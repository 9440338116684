<template>
  <div>
    <Navbar></Navbar>
    <NewsModal></NewsModal>
    <modal ref="popup" :title="callback.title">
      <template #headerIcon>
        <img
          v-if="!callback.error"
          class="modal__icon borderless"
          :src="require('./resources/images/icons/party.svg')"
        />
        <img v-else class="modal__icon borderless" :src="require('./resources/images/icons/warning.svg')" />
      </template>
      <template #body>
        <p class="modal__description">{{ callback.description }}</p>
        <div class="option" @click="$router.push({ name: 'ServerSelection' })">
          <img class="icon borderless" :src="require('./resources/images/icons/servers-min.png')" />
          <h1>{{ $t("Callback.selectAnotherServer") }}</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M 7 10 L 12 15 L 17 10"
            ></path>
          </svg>
        </div>
        <div class="option" @click="createRequest()">
          <img class="icon borderless" :src="require('./resources/images/icons/redo-min.png')" />
          <h1>{{ $t("Poll.callback.createAPollAgain") }}</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M 7 10 L 12 15 L 17 10"
            ></path>
          </svg>
        </div>
        <p class="separator">{{ $t("Callback.needHelp") }}</p>
        <div class="option" @click="$open('/join-support')">
          <img class="icon borderless" :src="require('./resources/images/support-icon-animated.gif')" />
          <h1>{{ $t("Callback.joinSupportServer.title") }}</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M 7 10 L 12 15 L 17 10"
            ></path>
          </svg>
        </div>
      </template>
    </modal>
    <PollResponseOptionsModal
      @close="handlePollResponseOptionsModalCloseEvent"
      ref="responseOptionsPopup"
      :title="$t('Poll.optionsModal.title')"
      :canClose="canCloseResponseOptionsModal"
    >
      <template #body>
        <img
          style="max-height: 125px; padding: 25px"
          v-if="settings.voteOptions.length === 0"
          :src="require('./resources/images/icons/open-box.svg')"
        />
        <p v-if="settings.voteOptions.length === 0" style="text-align: center">
          {{ $t("Poll.optionsModal.empty") }}
        </p>
        <div v-for="option in settings.voteOptions" :key="option.id">
          <div class="option noHover">
            <emoji-picker
              :pickerId="option.id"
              :emojisToHide="selectedEmojis"
              @open="isEmojiPickerOpened = true"
              @hide="isEmojiPickerOpened = false"
              @emojiSelect="selectEmoji"
            >
              <div
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                @click.stop="(e) => clickEvent(e, isEmojiPickerOpened)"
              >
                <!-- TODO double click to select emoji (VueClickHelper) -->
                <!-- @mouseover="
                    () => {
                      if (!isEmojiHovered && option.emoji.isDefault)
                        option.emoji.name = randomEmoji();
                      isEmojiHovered = true;
                    }
                  "
                  @mouseleave="isEmojiHovered = false" -->
                <div
                  :class="['borderless', 'emojiPickerIcon', { noGray: !option.emoji.isDefault }]"
                  v-html="$twemoji.parse(option.emoji.name)"
                />
              </div>
            </emoji-picker>
            <input
              class="optionInput"
              v-model="option.name"
              type="text"
              :id="'response-option-' + option.emoji.name"
              :placeholder="$t('Poll.optionsModal.optionName')"
              autocomplete="off"
              maxlength="325"
            />
            <font-awesome-icon
              @click="() => removeOption(option.id)"
              class="arrow"
              style="transform: none"
              :icon="['fas', 'minus']"
            />
          </div>
        </div>
        <div class="toggleButtonWrapper multipleAnswersPossible">
          <toggle-button
            :color="{ checked: '#3BA55D', unchecked: '#72767D' }"
            :width="45"
            :height="25"
            :value="settings.areSeveralAnswersPossible"
            :sync="true"
            @change="({ value }) => (settings.areSeveralAnswersPossible = value)"
          />
          <p>{{ $t("Poll.areSeveralAnswersPossible") }}</p>
        </div>
        <p
          style="text-align: center"
          ref="hasAtLeastOneOptionWithMissingContent"
          v-if="hasAtLeastOneOptionWithMissingContent && settings.voteOptions.length !== 0"
        >
          {{ $t("Poll.optionsModal.errors.hasAtLeastOneOptionWithMissingContent") }}
        </p>
        <p
          style="text-align: center"
          ref="doesNotHaveEnoughResponseOptions"
          v-if="doesNotHaveEnoughResponseOptions && settings.voteOptions.length !== 0"
        >
          {{ $t("Poll.optionsModal.errors.doesNotHaveEnoughResponseOptions") }}
        </p>
        <p
          style="text-align: center"
          ref="hasReachedTheMaximumNumberOfOptions"
          v-if="hasReachedTheMaximumNumberOfOptions && settings.voteOptions.length !== 0"
        >
          {{ $t("Poll.optionsModal.errors.hasReachedTheMaximumNumberOfOptions") }}
        </p>
      </template>
      <template #footer>
        <button class="modalButton" @click="addOption">
          {{ $t("Poll.optionsModal.addResponseOption") }}
        </button>
      </template>
    </PollResponseOptionsModal>
    <div
      class="container"
      :style="`background-image: url(${require('./resources/images/background.jpg')})`"
    >
      <div :class="['card', { shake: isMissingInfos }]">
        <div class="content">
          <img
            :src="require('./resources/images/poll/illustration-min.jpg')"
            alt="Page Illustration"
            class="illustration"
          />
          <h1>{{ $t("Poll.title") }}</h1>
          <div class="vue-select infosMargin">
            <v-select
              :placeholder="$t('Poll.whoWillBeAbleToAnswer')"
              autocomplete
              multiple
              :options="infos.roles"
              @input="setSelectedRoles"
              label="name"
              :selectable="() => roles.length < 8"
              :value="roles"
            >
              <!-- Custom style in the dropdown -->
              <template #option="{ name, color, users }">
                <span
                  :style="`border-radius: 3px; padding: 2px 3px; background: #${getHexaColor(
                    color,
                    '7289da'
                  )}1A; color: #${getHexaColor(color)}`"
                >
                  {{ truncateStr(name, 30) }}
                </span>
                <div class="dropdown-option-right" :style="`min-width: 50px;`">
                  <img alt="people" :src="require('./resources/images/attendance/users-min.png')" />
                  <span class="count">{{ users }}</span>
                </div>
              </template>
              <!-- Custom style when selected -->
              <template #selected-option="{ name, color }">
                <span :style="`color: #${getHexaColor(color)}`">
                  {{ truncateStr(name, 30) }}
                </span>
              </template>
            </v-select>
          </div>
          <div class="vue-select">
            <v-select
              :placeholder="$t('Poll.whereWillThePollBeSent')"
              autocomplete
              :options="infos.channels"
              @input="setSelectedChannel"
              label="name"
              :value="channel"
            >
              <!-- Custom style in the dropdown -->
              <template #option="{ category, name }">
                <span>{{ truncateStr(`${category ? "(" + category + ") " : ""}${name}`) }}</span>
              </template>
              <!-- Custom style when selected -->
              <template #selected-option="{ category, name }">
                <span>{{ truncateStr(`${category ? "(" + category + ") " : ""}${name}`) }}</span>
              </template>
            </v-select>
          </div>
          <p class="infos">{{ $t("Poll.infos") }}</p>
          <div class="vue-select">
            <div class="vs__dropdown-toggle">
              <input
                :placeholder="$t('Poll.subject')"
                class="vs__search"
                v-model="settings.subject"
                maxlength="256"
              />
            </div>
          </div>
          <div class="option" @click="$refs.responseOptionsPopup.openModal()">
            <img
              class="icon borderless"
              :src="require('./resources/images/poll/answers-min.png')"
            />
            <h1>
              {{ $t("Poll.optionsModal.title") }}
              <p>
                {{
                  $tc("Poll.optionsCount", settings.voteOptions.length + 1, {
                    total: settings.voteOptions.length,
                  })
                }}
              </p>
            </h1>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="arrow"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M 7 10 L 12 15 L 17 10"
              ></path>
            </svg>
          </div>
          <div class="row">
            <div class="largeColumn pollOptionsMargin">
              <div class="toggleButtonWrapper">
                <toggle-button
                  :color="{ checked: '#3BA55D', unchecked: '#72767D' }"
                  :width="45"
                  :height="25"
                  :value="settings.isAnonymous"
                  :sync="true"
                  @change="({ value }) => (settings.isAnonymous = value)"
                />
                <p>{{ $t("Poll.anonymous") }}</p>
              </div>
              <div class="toggleButtonWrapper">
                <toggle-button
                  :color="{ checked: '#3BA55D', unchecked: '#72767D' }"
                  :width="45"
                  :height="25"
                  :value="settings.isResultPublic"
                  :sync="true"
                  @change="({ value }) => (settings.isResultPublic = value)"
                />
                <p>{{ $t("Poll.publicResult") }}</p>
              </div>
            </div>
            <div class="column" style="margin-top: 10px">
              <div class="durationWrapper">
                <p v-if="!date">{{ $t("Poll.duration") }}</p>
                <p v-else>{{ $t("Poll.deadline") }}</p>
                <div class="durationField">
                  <div v-if="date" style="display: flex">
                    <var
                      @click="toggleDurationMode"
                      class="buttonIconWithHover noMargin"
                      v-html="$twemoji.parse('🕒')"
                    />
                    <date-pick
                      v-model="date"
                      :inputAttributes="{ readonly: true }"
                      :isDateDisabled="isDateOldOrTooFar"
                      :pickTime="true"
                      :use12HourClock="$t('Global.use12HourClock') == 'true'"
                      :displayFormat="$t('Poll.datePicker.displayFormat')"
                      :format="'YYYY-MM-DD HH:mm'"
                      :nextMonthCaption="$t('Poll.datePicker.nextMonthCaption')"
                      :prevMonthCaption="$t('Poll.datePicker.prevMonthCaption')"
                      :setTimeCaption="$t('Poll.datePicker.setTimeCaption')"
                      :weekdays="$t('Poll.datePicker.weekdays')"
                      :months="$t('Poll.datePicker.months')"
                    >
                    </date-pick>
                  </div>
                  <div v-if="!date" style="display: flex">
                    <var
                      @click="toggleCalendarMode"
                      class="buttonIconWithHover noMargin"
                      style="display: flex"
                      v-html="$twemoji.parse('📅')"
                    />
                    <VueTimepicker
                      v-if="!date"
                      :hour-range="[[0, 168]]"
                      :minute-range="[[1, 59]]"
                      v-model="duration"
                      format="HH:mm:ss"
                      input-width="5em"
                      manual-input
                      hide-clear-button
                      close-on-complete
                    >
                    </VueTimepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <div v-if="!isLoading">
              <button @click="clearUserInputs()" class="white">
                <font-awesome-icon class="icon" :icon="['fas', 'redo']" />
              </button>
              <button @click="deletePollRequest()" class="black">
                <font-awesome-icon class="icon" :icon="['fas', 'times']" />
              </button>
              <button @click="createOrEditPoll()" class="blue">
                <font-awesome-icon class="icon" :icon="['fas', 'check']" />
              </button>
            </div>
            <div v-else class="circleLoader"></div>
          </div>
        </div>
      </div>
    </div>
    <CookiesBanner/>
  </div>
</template>

<script>
import Vue from "vue";
import Navbar from "./components/Navbar.vue";
import Modal from "./components/Modal.vue";
import NewsModal from "./components/NewsModal.vue";
import PollResponseOptionsModal from "./components/InteractiveModal.vue";
import VueTimepicker from "vue2-timepicker/dist/VueTimepicker.umd.min.js";
import EmojiPicker from "./components/emoji-picker/EmojiPicker.vue";
import DatePick from "vue-date-pick";
import moment from "moment";
import CookiesBanner from "./components/CookiesBanner.vue";

import utils from "./mixins/utils";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faRedo, faTimes, faCheck, faMinus } from "@fortawesome/free-solid-svg-icons";

library.add(faRedo, faTimes, faCheck, faMinus);

export default {
  name: "Poll",
  mixins: [utils],
  components: {
    Navbar,
    NewsModal,
    Modal,
    VueTimepicker,
    PollResponseOptionsModal,
    EmojiPicker,
    DatePick,
    CookiesBanner,
  },
  metaInfo() {
    const title = this.$i18n.t("Poll.title");
    return {
      title: (chunk) => chunk.slice(0, -2) + title,
      meta: [
        { name: "description", content: this.$t("Home.sections.features.poll.description") },
        { property: "og:title", content: this.$t("Poll.title") },
        { property: "og:description", content: this.$t("Home.sections.features.poll.description") },
      ],
    };
  },
  data() {
    return {
      infos: {},
      channel: undefined, // text channel
      roles: [],
      settings: {
        subject: "",
        isAnonymous: true,
        isResultPublic: false,
        areSeveralAnswersPossible: false,
        deadline: undefined,
        voteOptions: [],
        timezone: undefined,
      },
      callback: {},
      isLoading: false,
      isMissingInfos: false,
      doesNotHaveEnoughResponseOptions: false,
      hasReachedTheMaximumNumberOfOptions: false,
      hasAtLeastOneOptionWithMissingContent: false,
      isEmojiPickerOpened: false,
      selectedEmojis: [],
      date: undefined,
      duration: "00:01:00",
    };
  },
  computed: {
    deadline() {
      if (this.date) return moment(this.date).toISOString();
      else if (this.duration) return this.durationToDate(this.duration).toISOString();
      else return this.getDefaultDeadline().toISOString();
    },
    canCloseResponseOptionsModal() {
      return (
        (!this.settings.voteOptions.some(
          (option) => option.name === "" || !/\S/.test(option.name)
        ) && this.settings.voteOptions.length >= 2) ||
        this.settings.voteOptions.length === 0
      );
    },
  },
  methods: {
    toggleCalendarMode: function () {
      this.date = moment().add(1, "day").format("YYYY-MM-DD HH:mm");
      this.duration = undefined;
    },
    toggleDurationMode: function () {
      this.date = undefined;
      this.duration = "00:01:00";
    },
    clearUserInputs: function () {
      this.channel = undefined;
      this.roles = [];
      this.settings.subject = "";
      this.settings.isAnonymous = true;
      this.settings.isResultPublic = false;
      this.settings.areSeveralAnswersPossible = false;
      this.settings.deadline = undefined;
      this.settings.voteOptions = [];
      this.doesNotHaveEnoughResponseOptions = false;
      this.hasReachedTheMaximumNumberOfOptions = false;
      this.hasAtLeastOneOptionWithMissingContent = false;
      this.isEmojiPickerOpened = false;
      this.searchContent = "";
      this.selectedEmojis = [];
      this.date = undefined;
      this.duration = "00:01:00";
    },
    durationToDate: function (duration) {
      const durationArray = duration.split(":");
      return moment().add({
        hours: durationArray[0],
        minutes: durationArray[1],
        seconds: durationArray[2],
      });
    },
    getDefaultDeadline: () => moment().add(1, "min"),
    // https://dbrekalo.github.io/vue-date-pick/examples.html#custom-date-parser
    isDateOldOrTooFar: (date) =>
      date <= moment().toDate() || date >= moment().add(2, "month").toDate(),
    handlePollResponseOptionsModalCloseEvent: function () {
      if (!this.canCloseResponseOptionsModal) {
        if (this.settings.voteOptions.length < 2) {
          this.doesNotHaveEnoughResponseOptions = true;
          return setTimeout(() => {
            this.doesNotHaveEnoughResponseOptions = false;
          }, 5000);
        } else {
          this.hasAtLeastOneOptionWithMissingContent = true;
          const self = this;
          Vue.nextTick(function () {
            self.$refs.hasAtLeastOneOptionWithMissingContent.scrollIntoView({ behavior: "smooth" });
            setTimeout(() => {
              self.hasAtLeastOneOptionWithMissingContent = false;
            }, 5000);
          });
        }
      }
    },
    selectEmoji: function ({ emoji, pickerId }) {
      const option = this.settings.voteOptions[pickerId];
      option.emoji.name = emoji;
      option.emoji.isDefault = false;
      this.selectedEmojis[option.id] = emoji;
    },
    addOption: function () {
      const id = this.settings.voteOptions[this.settings.voteOptions.length - 1 || 0]?.id + 1 || 0;
      if (id >= 10) {
        this.hasReachedTheMaximumNumberOfOptions = true;
        const self = this;
        Vue.nextTick(function () {
          self.$refs.hasReachedTheMaximumNumberOfOptions.scrollIntoView({ behavior: "smooth" });
          setTimeout(() => {
            self.hasReachedTheMaximumNumberOfOptions = false;
          }, 5000);
        });
        return;
      }
      this.settings.voteOptions.push({
        id,
        emoji: {
          isDefault: true,
          name: this.randomEmoji(),
        },
        name: "",
      });
    },
    removeOption: function (id) {
      this.settings.voteOptions = this.settings.voteOptions.filter((option) => option.id !== id);
      delete this.selectedEmojis[id];
    },
    setSelectedChannel: function (channel) {
      this.channel = channel;
    },
    setSelectedRoles: function (roles) {
      this.roles = roles;
    },
    /**
     * Takes Attendance
     */
    createOrEditPoll: async function () {
      const self = this;
      if (
        !self.channel ||
        self.roles.length === 0 ||
        self.settings.subject === "" ||
        self.settings.voteOptions.length < 2
      ) {
        self.isMissingInfos = true;
        setTimeout(() => {
          self.isMissingInfos = false;
        }, 1000);
      } else {
        this.isLoading = true;

        // Deadline security
        if (moment.duration(moment(this.settings.deadline).diff(moment())).asMinutes() < 1) {
          if (this.duration) this.settings.deadline = this.durationToDate(this.duration);
          else this.settings.deadline = this.getDefaultDeadline();
        }

        // If no emoji has been selected, replace it with a number
        // const defaultEmojis = ["1️⃣","2️⃣","3️⃣","4️⃣","5️⃣", "6️⃣", "7️⃣", "8️⃣", "9️⃣", "🔟"];
        // this.settings.voteOptions.forEach((vote, i) => {
        //   if(vote.emoji.isDefault) {
        //     vote.emoji.name = defaultEmojis[i]
        //     vote.emoji.isDefault = false;
        //   }
        //   return vote;
        // });

        // Post request content
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            channel: this.channel.id,
            roles: [...this.roles.map((r) => r.id)],
            settings: { ...this.settings, language: this.$i18n.locale || "en" },
          }),
        };

        const res = await fetch("/api/poll/edit", options);
        const data = await res.json();

        // Handle server error
        if (res.status === 403) return this.$router.push({ name: "ServerSelection" });

        this.callback = {
          title: this.$t(
            "Poll.callback." + (data.error ? `errors.${data.error}.title` : "pollCreated.title")
          ),
          description: this.$t(
            "Poll.callback." +
              (data.error ? `errors.${data.error}.description` : "pollCreated.description"),
            { channel: this.channel.name }
          ),
          ...data,
        };
        this.isLoading = false;
        this.$refs.popup.openModal();
      }
    },
    /**
     * Delete a poll request
     */
    deletePollRequest: async function () {
      const options = {
        method: "DELETE",
      };
      await fetch("/api/poll/delete", options);
      this.$router.push({ name: "ServerSelection" });
    },
    createRequest: async function () {
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      const res = await fetch(
        `/api/poll/create/${this.infos.request.guildId}/${this.infos.request.channelId ?? ""}`,
        options
      );
      // Handle server error
      if (res.status === 500) this.$router.push({ name: "ServerSelection" });
      else this.$router.go();
    },
  },
  updated() {
    this.settings.deadline = this.deadline;
  },
  /**
   * Loads the attendance infos from the api
   */
  async beforeCreate() {
    const res = await fetch("/api/poll");
    const data = await res.json();
    if (data.status === 401) this.$redirect("/login?redirectTo=/poll");
    else if (data.status === 403 || data.roles === null || data.channels === null)
      this.$router.push({ name: "ServerSelection" });
    else {
      this.infos = data;
      this.settings.timezone = data.timezone ?? this.guessTimezone();
      if (this.infos.request.channelId)
        this.channel = this.infos.channels.find((c) => c.id === this.infos.request.channelId);
    }
  },
};
</script>

<style lang="scss">
@import "./resources/styles/components/vue-select/vue-select.scss";
@import "./resources/styles/components/vue-timepicker.scss";
@import "./resources/styles/components/vue-datepicker.scss";
</style>

<style scoped lang="scss">
@import "./resources/styles/app.scss";

.vue-select {
  margin-top: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-option-right {
  float: right;
  mix-blend-mode: exclusion;
  text-align: right;
  background-color: #2326298a;
  padding: 1px 6px;
  border-radius: 4px;

  .count {
    color: #888;
  }

  img {
    max-height: 18px;
    text-align: left;
    float: left;
    margin-right: 2px;
  }
}
</style>
