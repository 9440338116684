<template>
  <div
    class="container"
    :style="`background-image: url(${require('./resources/images/home/background-min.jpg')})`"
  >
    <HomeNavbar :heroContainer="contactHeroContainerRef" />
    <div ref="contactHeroContainer" class="hero-container hero-container-small">
      <section class="hero">
        <div class="hero-center">
          <i18n
            tag="h1"
            path="ContactUs.sections.hero.title"
            for="ContactUs.sections.hero.titleColoredPart"
            class="section-heading"
          >
            <span class="title-colored">{{ $t("ContactUs.sections.hero.titleColoredPart") }}</span>
          </i18n>
          <p class="section-text">
            {{ $t("ContactUs.sections.hero.description") }}
          </p>
        </div>
      </section>
    </div>
    <div class="sections-container">
      <section
        ref="contact-section"
        class="section-container section-container-noBackground block-section"
      >
        <div class="section-content">
          <div class="block-section-content-container">
            <div class="inline-content">
              <div class="form-input-container">
                <div
                  v-if="invalidDiscordTag"
                  class="invalid-input-description invalid-input-description-blank"
                />
                <input
                  v-model="form.name"
                  type="text"
                  name="name"
                  class="form-input"
                  :placeholder="$t('ContactUs.form.name')"
                  autocomplete
                />
              </div>
              <div class="form-input-container">
                <p v-if="invalidDiscordTag" class="invalid-input-description">
                  {{ $t("ContactUs.form.invalidDiscordTag") }}
                </p>
                <input
                  @keyup="checkDiscordTagValidity()"
                  v-model="form.discordTag"
                  type="text"
                  name="discordTag"
                  :class="['form-input', { 'invalid-input': invalidDiscordTag }]"
                  :placeholder="$t('ContactUs.form.discordTag')"
                  autocomplete
                />
              </div>
            </div>
            <p v-if="invalidEmail" class="invalid-input-description">
              {{ $t("ContactUs.form.invalidEmail") }}
            </p>
            <input
              @keyup="checkEmailValidity()"
              v-model="form.email"
              name="email"
              type="email"
              :class="['form-input', 'form-input-email', { 'invalid-input': invalidEmail }]"
              :placeholder="$t('ContactUs.form.email')"
              autocomplete
              required
            />
            <p v-if="missingSubject" class="invalid-input-description">
              {{ $t("ContactUs.form.requiredField") }}
            </p>
            <input
              v-model="form.subject"
              name="subject"
              :class="['form-input', 'form-input-subject', { 'invalid-input': missingSubject }]"
              :placeholder="$t('ContactUs.form.subject')"
            />
            <p v-if="missingMessage" class="invalid-input-description">
              {{ $t("ContactUs.form.requiredField") }}
            </p>
            <textarea
              v-model="form.message"
              name="message"
              :class="['form-input', 'form-input-message', { 'invalid-input': missingMessage }]"
              :placeholder="$t('ContactUs.form.message')"
            />
            <button
              @click="validateFormContent()"
              :class="[
                'form-button',
                { 'form-button-desactivated': invalidDiscordTag || invalidEmail },
                { 'form-button-success': formSuccess },
                { 'form-button-error': formError },
              ]"
            >
              <span v-if="formSuccess">{{ $t("ContactUs.form.messageSent") }}</span>
              <span v-if="formError">{{ $t("ContactUs.form.messageNotSent") }}</span>
              <span v-if="!formSuccess && !formError">{{ $t("ContactUs.form.sendMessage") }}</span>
              <font-awesome-icon
                v-if="formSuccess"
                class="buttonIcon"
                :icon="['fas', 'paper-plane']"
              />
              <font-awesome-icon v-if="formError" class="buttonIcon" :icon="['fas', 'times']" />
              <font-awesome-icon
                v-if="!formSuccess && !formError"
                class="buttonIcon"
                :icon="['fas', 'envelope-open-text']"
              />
            </button>
          </div>
        </div>
      </section>
      <HomeFooter />
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelopeOpenText, faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons";

import HomeNavbar from "./components/HomeNavbar.vue";
import HomeFooter from "./components/HomeFooter.vue";

library.add(faEnvelopeOpenText, faPaperPlane, faTimes);

export default {
  name: "ContactUs",
  components: {
    HomeNavbar,
    HomeFooter,
  },
  metaInfo() {
    const title = this.$t("ContactUs.title");
    return {
      title: (chunk) => chunk.slice(0, -2) + title,
      meta: [
        { name: "description", content: this.$t("ContactUs.sections.hero.description") },
        { property: "og:title", content: this.$t("ContactUs.title") },
        { property: "og:description", content: this.$t("ContactUs.sections.hero.description") },
      ],
    };
  },
  data() {
    return {
      form: {
        name: "",
        discordTag: "",
        email: "",
        subject: "",
        message: "",
      },
      invalidDiscordTag: false,
      invalidEmail: false,
      missingSubject: false,
      missingMessage: false,
      formSuccess: false,
      formError: false,
      contactHeroContainerRef: undefined,
    };
  },
  methods: {
    checkDiscordTagValidity: function () {
      // Check discord tag validity
      const discordTag = /.+#\d{4}/;
      this.invalidDiscordTag =
        !this.form.discordTag.match(discordTag) && this.form.discordTag !== "";
    },
    checkEmailValidity: function () {
      // Check email validity
      const email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      this.invalidEmail = !this.form.email.match(email) && this.form.message.match(/^\s*$/);
    },
    validateFormContent: async function () {
      let missingContent = false;
      if (this.formSuccess || this.formError) return;
      // 2nd check
      this.checkDiscordTagValidity();
      this.checkEmailValidity();
      // 3rd check
      if (this.invalidDiscordTag || this.invalidEmail) {
        missingContent = true;
      }
      // Check if there's a mail
      if (this.form.email.match(/^\s*$/)) {
        this.invalidEmail = true;
        missingContent = true;
      }
      // Check if there's a subject
      if (this.form.subject.match(/^\s*$/)) {
        this.missingSubject = true;
        missingContent = true;
      }
      // Check if there's a message
      if (this.form.message.match(/^\s*$/)) {
        this.missingMessage = true;
        missingContent = true;
      }

      // Send form if there's all the required data
      if (!missingContent) {
        const destination = process.env.NODE_ENV.includes("DEVELOPMENT")
          ? "https://formspree.io/f/xeqrkybr"
          : "https://formspree.io/f/xwkrejdo";
        // Send form
        const res = await fetch(destination, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(this.form),
        });
        const data = await res.json();
        // Check if the form has been sent
        if (data?.ok) this.formSuccess = true;
        else this.formError = true;
      }
    },
  },
  beforeUpdate() {
    // Check if there's a subject
    if (!this.form.subject.match(/^\s*$/)) this.missingSubject = false;
    // Check if there's a message
    if (!this.form.message.match(/^\s*$/)) this.missingMessage = false;
  },
  mounted() {
    // Send the heroContainer ref to the navbar (refs are accessible only after being mounted)
    this.contactHeroContainerRef = this.$refs.contactHeroContainer;
  },
};
</script>

<style lang="scss" scoped>
@import "./resources/styles/global/variables.scss";
@import "./resources/styles/home.scss";

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  margin-top: 72px;
  letter-spacing: -0.02em;
  padding: 20px 125px;
  border-radius: 100px;
  background-color: $primary;
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  cursor: pointer;
  transition: 0.4s;

  @media screen and (max-width: 767px) {
    padding: 20px 35px;
  }

  .buttonIcon {
    width: 0.8em;
    margin-left: 12px;
  }

  &:hover {
    transform: scale(0.98, 0.98);
  }

  &-success {
    background-color: $success;
    cursor: not-allowed;

    &:hover {
      transform: none;
    }
  }

  &-error {
    background-color: $error;
    cursor: not-allowed;

    &:hover {
      transform: none;
    }
  }

  &-desactivated {
    cursor: not-allowed;

    .buttonIcon {
      cursor: inherit;
    }

    &:hover {
      transform: none;
    }
  }
}

.form-input {
  display: flex;
  flex-grow: 1;
  outline: 0;
  border-radius: 12px;
  padding: 24px;
  font-size: 26px;
  margin: 0 12px 22px 12px;
  max-height: 18px;
  line-height: 30px;
  background-color: #fcfdfd;

  &-email {
    max-width: 65%;

    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  &-subject {
    max-width: 85%;

    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  &-message {
    resize: none;
    margin-bottom: 0;
    min-height: 45vh;

    @media screen and (min-width: 1921px) {
      min-height: 30vh;
    }
  }

  @media screen and (max-width: 767px) {
    max-width: calc(100% - 2 * 24px - 2 * 10px);
    width: 100%;
    margin: 0 10px 11px 10px;
  }
}

.invalid-input {
  box-shadow: inset 0px 0px 0px 1.5px $error;
}

.invalid-input-description {
  margin: -14px 0 3px 12px;
  align-self: flex-start;
  color: $error;
  text-align: left;
  height: 18px;

  &-blank {
    content: "⠀";
  }

  @media screen and (max-width: 767px) {
    margin-top: 2px;
  }
}

.inline-content {
  display: flex;
  flex-direction: row;
  max-width: 100%;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .form-input-container {
    .form-input {
      @media screen and (max-width: 1070px) and (min-width: 767px) {
        max-width: 275px;
      }
    }

    &:last-child {
      margin-right: 35px;

      @media screen and (max-width: 767px) {
        margin-right: 0;
      }
    }
  }
}

textarea {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(59, 59, 59, 0.3);
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
</style>
