import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import Home from "../views/Home.vue";
import ContactUs from "../views/ContactUs.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermsOfUse from "../views/TermsOfUse.vue";
import Attendance from "../views/Attendance.vue";
import Poll from "../views/Poll.vue";
import Servers from "../views/Servers.vue";
import Error404 from "../views/Error404.vue";

Vue.use(VueRouter);

export default function (i18n) {
	const routes = [
		{
			path: "/",
			name: "Home",
			component: Home,
		},
		{
			path: "/contact-us",
			name: "ContactUs",
			component: ContactUs,
		},
		{
			path: "/privacy-policy",
			name: "PrivacyPolicy",
			component: PrivacyPolicy,
		},
		{
			path: "/terms-of-use",
			name: "TermsOfUse",
			component: TermsOfUse,
		},
		{
			path: "/attendance",
			name: "TakeAttendance",
			component: Attendance,
		},
		{
			path: "/poll",
			name: "CreatePoll",
			component: Poll,
		},
		{
			path: "/servers",
			name: "ServerSelection",
			component: Servers,
		},
		{
			path: "/error-404",
			name: "Error404",
			component: Error404,
		},
		{
			path: "/tfjm-2023/:catchAll(.*)",
		},
	];

	// Dynamic generation of language routes
	for (const lang of i18n.availableLocales) {
		routes.push({
			path: "/" + i18n.t("Global.url", lang),
			name: "Home-" + lang.toUpperCase(),
			component: Home,
			props: { language: lang },
		});
	}

	// Only redirects the user if the server is not in DEVELOPMENT mode
	// if (!process.env.NODE_ENV.includes("DEVELOPMENT")) {
	//   routes.push({
	//     path: '/:pathMatch(.*)*',
	//     redirect: { name: 'Error404' }
	//   });
	// }

	const router = new VueRouter({
		mode: "history",
		routes,
		scrollBehavior: (to, from, savedPosition) =>
			to.name.includes("Home-") ? savedPosition : { x: 0, y: 0 },
	});

	// If the route named 'xxxx' is not defined, redirect to 404 (handle 404 error)
	if (!process.env.NODE_ENV.includes("DEVELOPMENT")) {
		router.beforeEach((to, from, next) =>
			to.matched.length > 0 ? next() : next({ name: "Error404" })
		);
	}

	return router;
}
