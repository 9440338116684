var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-container"},[_c('div',{class:['header-background', { withShadow: !_vm.isUserOnHero }]}),_c('collapse-transition',{attrs:{"dimension":"height"}},[(_vm.mobileMenuOpened)?_c('div',{staticClass:"header-mobile-menu"},[_c('div',{staticClass:"header-menu-blur"}),_c('transition',{attrs:{"name":"fade-menu"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobileMenuOpened),expression:"mobileMenuOpened"}]},[_c('nav',{staticClass:"header-nav"},[_c('button',{class:[
                'nav-link',
                { 'nav-link--selected': _vm.$router.currentRoute.name.includes('Home') } ],on:{"click":function($event){_vm.$router.currentRoute.name !== 'Home' ? _vm.$router.push({ name: 'Home' }) : ''}}},[_vm._v(" "+_vm._s(_vm.$t("Home.navbar.overview"))+" ")]),_c('button',{class:[
                'nav-link',
                { 'nav-link--selected': _vm.$router.currentRoute.name === 'ContactUs' } ],on:{"click":function($event){_vm.$router.currentRoute.name !== 'ContactUs'
                  ? _vm.$router.push({ name: 'ContactUs' })
                  : ''}}},[_vm._v(" "+_vm._s(_vm.$t("Home.navbar.contact-us"))+" ")]),_c('a',{staticClass:"nav-link",attrs:{"rel":"noreferrer nofollow","target":"_blank","href":"https://utip.io/mw3y"}},[_vm._v(" "+_vm._s(_vm.$t("Home.navbar.donate"))+" ")]),_c('button',{staticClass:"nav-link",on:{"click":function($event){return _vm.$open('/join-support')}}},[_vm._v(" "+_vm._s(_vm.$t("Home.navbar.support"))+" ")])]),_c('div',{staticClass:"header-mobile-menu-button-container"},[_c('button',{staticClass:"large-button menu-button",on:{"click":function($event){return _vm.$open('/add-bot')}}},[_vm._v(" "+_vm._s(_vm.$t("Home.addToDiscord"))+" ")]),_c('button',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeLanguageDropdown),expression:"closeLanguageDropdown"}],ref:"mobileLanguageButton",class:[
                'large-button menu-button',
                { 'languageSelector-clicked': _vm.languageDropdownOpened } ],on:{"click":function($event){_vm.languageDropdownOpened = !_vm.languageDropdownOpened}}},[_c('span',{staticClass:"buttonIcon",staticStyle:{"display":"flex"},domProps:{"innerHTML":_vm._s(_vm.$twemoji.parse('🌎'))}}),_c('span',[_vm._v(_vm._s(_vm.$t("Navbar.languages")))]),(_vm.languageDropdownOpened)?_c('LanguageDropdown',{attrs:{"parent":_vm.$refs.mobileLanguageButton},on:{"click-outside":function($event){_vm.languageDropdownOpened = false},"languageChange":function (lang) { return _vm.$emit('languageChange', lang); }}}):_vm._e()],1)])])])],1):_vm._e()]),_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-left"},[_c('button',{staticClass:"header-logo",on:{"click":function($event){_vm.$router.currentRoute.name !== 'Home' ? _vm.$router.push({ name: 'Home' }) : ''}}},[_c('img',{attrs:{"src":require('../resources/images/full-logo-dark-min.png'),"alt":"suivix-logo"}})]),(_vm.statistics.guilds > 0)?_c('div',{staticClass:"header-info"},[_vm._v(" "+_vm._s(_vm.$tc("Home.navbar.servers-info", _vm.statistics.guilds))+" ")]):_vm._e(),(_vm.statistics.users > 0)?_c('div',{staticClass:"header-info"},[_vm._v(" "+_vm._s(_vm.$tc("Home.navbar.users-info", _vm.statistics.users))+" ")]):_vm._e()]),_c('nav',{staticClass:"header-nav"},[_c('button',{class:[
          'nav-link',
          { 'nav-link--selected': _vm.$router.currentRoute.name.includes('Home') } ],on:{"click":function($event){_vm.$router.currentRoute.name !== 'Home' ? _vm.$router.push({ name: 'Home' }) : ''}}},[_vm._v(" "+_vm._s(_vm.$t("Home.navbar.overview"))+" ")]),_c('button',{class:['nav-link', { 'nav-link--selected': _vm.$router.currentRoute.name === 'ContactUs' }],on:{"click":function($event){_vm.$router.currentRoute.name !== 'ContactUs' ? _vm.$router.push({ name: 'ContactUs' }) : ''}}},[_vm._v(" "+_vm._s(_vm.$t("Home.navbar.contact-us"))+" ")]),_c('a',{staticClass:"nav-link",attrs:{"rel":"noreferrer nofollow","target":"_blank","href":"https://utip.io/mw3y"}},[_vm._v(" "+_vm._s(_vm.$t("Home.navbar.donate"))+" ")]),_c('button',{staticClass:"nav-link",on:{"click":function($event){return _vm.$open('/join-support')}}},[_vm._v(" "+_vm._s(_vm.$t("Home.navbar.support"))+" ")])]),_c('div',{staticClass:"header-right"},[(!_vm.mobileMenuOpened)?_c('button',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeLanguageDropdown),expression:"closeLanguageDropdown"}],ref:"languageButton",class:[
          'header-button header-button-languageSelector',
          { 'languageSelector-clicked': _vm.languageDropdownOpened } ],on:{"click":function($event){_vm.languageDropdownOpened = !_vm.languageDropdownOpened}}},[_c('span',{staticClass:"buttonIcon",domProps:{"innerHTML":_vm._s(_vm.$twemoji.parse('🌎'))}}),_c('span',[_vm._v(_vm._s(_vm.$t("Navbar.languages")))]),(_vm.languageDropdownOpened)?_c('LanguageDropdown',{attrs:{"parent":_vm.$refs.languageButton},on:{"click-outside":function($event){_vm.languageDropdownOpened = false},"languageChange":function (lang) { return _vm.$emit('languageChange', lang); }}}):_vm._e()],1):_vm._e(),_c('button',{class:[
          'header-button header-button-addToDiscord',
          { 'header-button-colored': !_vm.isUserOnHero } ],on:{"click":function($event){return _vm.$open('/add-bot')}}},[_vm._v(" "+_vm._s(_vm.$t("Home.addToDiscord"))+" ")]),_c('button',{staticClass:"header-button header-menu-button",on:{"click":function($event){_vm.mobileMenuOpened = !_vm.mobileMenuOpened}}},[(!_vm.mobileMenuOpened)?_c('font-awesome-icon',{staticClass:"buttonIcon menuIcon",attrs:{"icon":['fas', 'bars']}}):_c('font-awesome-icon',{staticClass:"buttonIcon menuIcon",attrs:{"icon":['fas', 'times']}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }