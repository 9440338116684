<template>
  <div class="interactiveModal" v-if="show">
    <div class="interactiveModal__backdrop" />
    <!-- @click="closeModal()" -->
    <div :class="['interactiveModal__wrapper', { shake: isShaking }]">
      <div class="interactiveModal__dialog">
        <div class="interactiveModal__header">
          <div class="interactiveModal__leftButtonContainer">
            <font-awesome-icon
              @click="closeModal()"
              class="leftButton"
              :icon="['fas', 'times']"
              size="lg"
            />
          </div>
          <h1>{{ title }}</h1>
          <div class="blank__flex"></div>
        </div>

        <div class="interactiveModal__body">
          <slot name="body" />
        </div>
        <div class="interactiveModal__footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

library.add(faTimes);

export default {
  name: "InteractiveModal",
  props: {
    title: String,
    canClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      isShaking: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
      if (this.canClose) this.show = false;
      else {
        this.isShaking = true;
        setTimeout(() => {
          this.isShaking = false;
        }, 1000);
      }
    },
    openModal() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../resources/styles/global/variables.scss";

.blank__flex {
  flex: 1;
}

.interactiveModal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: auto;
  }

  &__backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__dialog {
    overflow-y: auto;
    background-color: $background;
    position: relative;
    width: 450px;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    z-index: 2;

    @media screen and (max-width: 1366px) and (min-width: 1023px) {
      zoom: 0.88;
    }

    @media screen and (max-width: 767px) {
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      width: 100%;
      margin: 0;
      border-radius: 8px 8px 0 0;
    }
  }

  &__close {
    cursor: pointer;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    color: $secondary;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  &__header {
    box-shadow: rgba(255, 255, 255, 0.05) 0 1px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;

    h1 {
      color: $title;
      font-size: 18px;
      font-weight: 600;
      line-height: 58px;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
      flex: 2.5;

      @media screen and (max-width: 380px) {
        font-size: 16px;
      }
    }
  }

  &__leftButtonContainer {
    z-index: 2;
    flex: 1;

    .leftButton {
      border-radius: 100px;
      color: $subtitle;
      padding: 13px 20px;

      &:hover {
        color: $title;
      }
    }
  }

  &__body {
    padding: 10px 20px 10px;
    overflow: overlay;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: justify;
    max-height: 55vh;

    @supports not (overflow: overlay) {
      // Firefox does not support overflow: overlay
      overflow: auto;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(116, 120, 125, 0.7);
      border: 5px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 0 20px 20px 20px;
  }
}
</style>
