var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("emoji-invoker",null,{"events":{
      click: function (e, isEmojiPickerOpened) {
        if (!isEmojiPickerOpened) { _vm.toggle(e); }
      },
    }}),(_vm.display.visible)?_c('div',{staticClass:"emoji-picker-container"},[_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hide),expression:"hide"}]},[_c('div',{staticClass:"emoji-picker emoji-picker-mobile",style:({ top: _vm.display.y + 'px', left: _vm.display.x + 'px' })},[_c('div',{staticClass:"emoji-picker__search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchContent),expression:"searchContent"}],attrs:{"placeholder":_vm.$t('Poll.optionsModal.emojiPicker.searchPlaceholder'),"type":"text","autocomplete":"off"},domProps:{"value":(_vm.searchContent)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchContent=$event.target.value}}})]),_c('div',{staticClass:"emojis-container"},_vm._l((_vm.emojis),function(emojiGroup,category){return _c('div',{key:category},[(Object.keys(emojiGroup).length > 0)?_c('h5',[_vm._v(_vm._s(category))]):_vm._e(),_c('div',{staticClass:"emojis"},_vm._l((emojiGroup),function(emoji,emojiName){return _c('div',{key:emojiName,attrs:{"title":emojiName}},[(!_vm.emojisToHide.includes(emoji))?_c('div',{staticClass:"emoji-wrapper",domProps:{"innerHTML":_vm._s(_vm.$twemoji.parse(emoji))},on:{"click":function () {
                      _vm.selectEmoji(emoji);
                      _vm.hide();
                    }}}):_vm._e()])}),0)])}),0)])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }