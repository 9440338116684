<template>
  <div class="container">
    <HomeNavbar />
    <div class="content">
      <div ref="heroContainer" class="hero-container">
        <div class="error-container">
          <img :src="require('./resources/images/404.svg')" class="error-logo" />
          <p class="error-text">{{ $t("404.error") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeNavbar from "./components/HomeNavbar.vue";

export default {
  name: "Error404",
  components: {
    HomeNavbar,
  },
  metaInfo() {
    const title = this.$t("404.title");
    return {
      title: (chunk) => chunk.slice(0, -2) + title,
      meta: [
        { name: "description", content: this.$t("404.error") },
        { property: "og:title", content: this.$t("404.title") },
        { property: "og:description", content: this.$t("404.error") },
      ],
    };
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "./resources/styles/home.scss";

.error-container {
  margin-top: -110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;

  .error-logo {
    max-height: 65vh;
  }

  .error-text {
    text-align: center;
    font-size: 32px;
    max-width: 75%;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      max-width: 95%;
    }
  }
}
</style>
