/* eslint max-len: ["error", { "code": 80000 }]*/

// https://raw.githubusercontent.com/muan/unicode-emoji-json/main/data-by-group.json

export default {
	"Frequently Used": [
		{
			"emoji": "✅",
			"skin_tone_support": false,
			"name": "check mark button",
			"slug": "check_mark_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "❌",
			"skin_tone_support": false,
			"name": "cross mark",
			"slug": "cross_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👍",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "thumbs up",
			"slug": "thumbs_up",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👎",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "thumbs down",
			"slug": "thumbs_down",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "0️⃣",
			"skin_tone_support": false,
			"name": "keycap 0",
			"slug": "keycap_0",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "1️⃣",
			"skin_tone_support": false,
			"name": "keycap 1",
			"slug": "keycap_1",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "2️⃣",
			"skin_tone_support": false,
			"name": "keycap 2",
			"slug": "keycap_2",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "3️⃣",
			"skin_tone_support": false,
			"name": "keycap 3",
			"slug": "keycap_3",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "4️⃣",
			"skin_tone_support": false,
			"name": "keycap 4",
			"slug": "keycap_4",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "5️⃣",
			"skin_tone_support": false,
			"name": "keycap 5",
			"slug": "keycap_5",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "6️⃣",
			"skin_tone_support": false,
			"name": "keycap 6",
			"slug": "keycap_6",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "7️⃣",
			"skin_tone_support": false,
			"name": "keycap 7",
			"slug": "keycap_7",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "8️⃣",
			"skin_tone_support": false,
			"name": "keycap 8",
			"slug": "keycap_8",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "9️⃣",
			"skin_tone_support": false,
			"name": "keycap 9",
			"slug": "keycap_9",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔟",
			"skin_tone_support": false,
			"name": "keycap 10",
			"slug": "keycap_10",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		}
	],
	"Smileys & Emotion": [
		{
			"emoji": "😀",
			"skin_tone_support": false,
			"name": "grinning face",
			"slug": "grinning_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😃",
			"skin_tone_support": false,
			"name": "grinning face with big eyes",
			"slug": "grinning_face_with_big_eyes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😄",
			"skin_tone_support": false,
			"name": "grinning face with smiling eyes",
			"slug": "grinning_face_with_smiling_eyes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😁",
			"skin_tone_support": false,
			"name": "beaming face with smiling eyes",
			"slug": "beaming_face_with_smiling_eyes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😆",
			"skin_tone_support": false,
			"name": "grinning squinting face",
			"slug": "grinning_squinting_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😅",
			"skin_tone_support": false,
			"name": "grinning face with sweat",
			"slug": "grinning_face_with_sweat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤣",
			"skin_tone_support": false,
			"name": "rolling on the floor laughing",
			"slug": "rolling_on_the_floor_laughing",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "😂",
			"skin_tone_support": false,
			"name": "face with tears of joy",
			"slug": "face_with_tears_of_joy",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙂",
			"skin_tone_support": false,
			"name": "slightly smiling face",
			"slug": "slightly_smiling_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🙃",
			"skin_tone_support": false,
			"name": "upside-down face",
			"slug": "upside_down_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😉",
			"skin_tone_support": false,
			"name": "winking face",
			"slug": "winking_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😊",
			"skin_tone_support": false,
			"name": "smiling face with smiling eyes",
			"slug": "smiling_face_with_smiling_eyes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😇",
			"skin_tone_support": false,
			"name": "smiling face with halo",
			"slug": "smiling_face_with_halo",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🥰",
			"skin_tone_support": false,
			"name": "smiling face with hearts",
			"slug": "smiling_face_with_hearts",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "😍",
			"skin_tone_support": false,
			"name": "smiling face with heart-eyes",
			"slug": "smiling_face_with_heart_eyes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤩",
			"skin_tone_support": false,
			"name": "star-struck",
			"slug": "star_struck",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "😘",
			"skin_tone_support": false,
			"name": "face blowing a kiss",
			"slug": "face_blowing_a_kiss",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😗",
			"skin_tone_support": false,
			"name": "kissing face",
			"slug": "kissing_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "☺️",
			"skin_tone_support": false,
			"name": "smiling face",
			"slug": "smiling_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😚",
			"skin_tone_support": false,
			"name": "kissing face with closed eyes",
			"slug": "kissing_face_with_closed_eyes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😙",
			"skin_tone_support": false,
			"name": "kissing face with smiling eyes",
			"slug": "kissing_face_with_smiling_eyes",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🥲",
			"skin_tone_support": false,
			"name": "smiling face with tear",
			"slug": "smiling_face_with_tear",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "😋",
			"skin_tone_support": false,
			"name": "face savoring food",
			"slug": "face_savoring_food",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😛",
			"skin_tone_support": false,
			"name": "face with tongue",
			"slug": "face_with_tongue",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😜",
			"skin_tone_support": false,
			"name": "winking face with tongue",
			"slug": "winking_face_with_tongue",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤪",
			"skin_tone_support": false,
			"name": "zany face",
			"slug": "zany_face",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "😝",
			"skin_tone_support": false,
			"name": "squinting face with tongue",
			"slug": "squinting_face_with_tongue",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤑",
			"skin_tone_support": false,
			"name": "money-mouth face",
			"slug": "money_mouth_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🤗",
			"skin_tone_support": false,
			"name": "hugging face",
			"slug": "hugging_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🤭",
			"skin_tone_support": false,
			"name": "face with hand over mouth",
			"slug": "face_with_hand_over_mouth",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🤫",
			"skin_tone_support": false,
			"name": "shushing face",
			"slug": "shushing_face",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🤔",
			"skin_tone_support": false,
			"name": "thinking face",
			"slug": "thinking_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🤐",
			"skin_tone_support": false,
			"name": "zipper-mouth face",
			"slug": "zipper_mouth_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🤨",
			"skin_tone_support": false,
			"name": "face with raised eyebrow",
			"slug": "face_with_raised_eyebrow",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "😐",
			"skin_tone_support": false,
			"name": "neutral face",
			"slug": "neutral_face",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "😑",
			"skin_tone_support": false,
			"name": "expressionless face",
			"slug": "expressionless_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😶",
			"skin_tone_support": false,
			"name": "face without mouth",
			"slug": "face_without_mouth",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😏",
			"skin_tone_support": false,
			"name": "smirking face",
			"slug": "smirking_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😒",
			"skin_tone_support": false,
			"name": "unamused face",
			"slug": "unamused_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙄",
			"skin_tone_support": false,
			"name": "face with rolling eyes",
			"slug": "face_with_rolling_eyes",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😬",
			"skin_tone_support": false,
			"name": "grimacing face",
			"slug": "grimacing_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🤥",
			"skin_tone_support": false,
			"name": "lying face",
			"slug": "lying_face",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "😌",
			"skin_tone_support": false,
			"name": "relieved face",
			"slug": "relieved_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😔",
			"skin_tone_support": false,
			"name": "pensive face",
			"slug": "pensive_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😪",
			"skin_tone_support": false,
			"name": "sleepy face",
			"slug": "sleepy_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤤",
			"skin_tone_support": false,
			"name": "drooling face",
			"slug": "drooling_face",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "😴",
			"skin_tone_support": false,
			"name": "sleeping face",
			"slug": "sleeping_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😷",
			"skin_tone_support": false,
			"name": "face with medical mask",
			"slug": "face_with_medical_mask",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤒",
			"skin_tone_support": false,
			"name": "face with thermometer",
			"slug": "face_with_thermometer",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🤕",
			"skin_tone_support": false,
			"name": "face with head-bandage",
			"slug": "face_with_head_bandage",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🤢",
			"skin_tone_support": false,
			"name": "nauseated face",
			"slug": "nauseated_face",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤮",
			"skin_tone_support": false,
			"name": "face vomiting",
			"slug": "face_vomiting",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🤧",
			"skin_tone_support": false,
			"name": "sneezing face",
			"slug": "sneezing_face",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🥵",
			"skin_tone_support": false,
			"name": "hot face",
			"slug": "hot_face",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🥶",
			"skin_tone_support": false,
			"name": "cold face",
			"slug": "cold_face",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🥴",
			"skin_tone_support": false,
			"name": "woozy face",
			"slug": "woozy_face",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "😵",
			"skin_tone_support": false,
			"name": "knocked-out face",
			"slug": "knocked_out_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤯",
			"skin_tone_support": false,
			"name": "exploding head",
			"slug": "exploding_head",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🤠",
			"skin_tone_support": false,
			"name": "cowboy hat face",
			"slug": "cowboy_hat_face",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🥳",
			"skin_tone_support": false,
			"name": "partying face",
			"slug": "partying_face",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🥸",
			"skin_tone_support": false,
			"name": "disguised face",
			"slug": "disguised_face",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "😎",
			"skin_tone_support": false,
			"name": "smiling face with sunglasses",
			"slug": "smiling_face_with_sunglasses",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🤓",
			"skin_tone_support": false,
			"name": "nerd face",
			"slug": "nerd_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🧐",
			"skin_tone_support": false,
			"name": "face with monocle",
			"slug": "face_with_monocle",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "😕",
			"skin_tone_support": false,
			"name": "confused face",
			"slug": "confused_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😟",
			"skin_tone_support": false,
			"name": "worried face",
			"slug": "worried_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🙁",
			"skin_tone_support": false,
			"name": "slightly frowning face",
			"slug": "slightly_frowning_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "☹️",
			"skin_tone_support": false,
			"name": "frowning face",
			"slug": "frowning_face",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "😮",
			"skin_tone_support": false,
			"name": "face with open mouth",
			"slug": "face_with_open_mouth",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😯",
			"skin_tone_support": false,
			"name": "hushed face",
			"slug": "hushed_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😲",
			"skin_tone_support": false,
			"name": "astonished face",
			"slug": "astonished_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😳",
			"skin_tone_support": false,
			"name": "flushed face",
			"slug": "flushed_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥺",
			"skin_tone_support": false,
			"name": "pleading face",
			"slug": "pleading_face",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "😦",
			"skin_tone_support": false,
			"name": "frowning face with open mouth",
			"slug": "frowning_face_with_open_mouth",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😧",
			"skin_tone_support": false,
			"name": "anguished face",
			"slug": "anguished_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😨",
			"skin_tone_support": false,
			"name": "fearful face",
			"slug": "fearful_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😰",
			"skin_tone_support": false,
			"name": "anxious face with sweat",
			"slug": "anxious_face_with_sweat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😥",
			"skin_tone_support": false,
			"name": "sad but relieved face",
			"slug": "sad_but_relieved_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😢",
			"skin_tone_support": false,
			"name": "crying face",
			"slug": "crying_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😭",
			"skin_tone_support": false,
			"name": "loudly crying face",
			"slug": "loudly_crying_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😱",
			"skin_tone_support": false,
			"name": "face screaming in fear",
			"slug": "face_screaming_in_fear",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😖",
			"skin_tone_support": false,
			"name": "confounded face",
			"slug": "confounded_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😣",
			"skin_tone_support": false,
			"name": "persevering face",
			"slug": "persevering_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😞",
			"skin_tone_support": false,
			"name": "disappointed face",
			"slug": "disappointed_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😓",
			"skin_tone_support": false,
			"name": "downcast face with sweat",
			"slug": "downcast_face_with_sweat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😩",
			"skin_tone_support": false,
			"name": "weary face",
			"slug": "weary_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😫",
			"skin_tone_support": false,
			"name": "tired face",
			"slug": "tired_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥱",
			"skin_tone_support": false,
			"name": "yawning face",
			"slug": "yawning_face",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "😤",
			"skin_tone_support": false,
			"name": "face with steam from nose",
			"slug": "face_with_steam_from_nose",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😡",
			"skin_tone_support": false,
			"name": "pouting face",
			"slug": "pouting_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😠",
			"skin_tone_support": false,
			"name": "angry face",
			"slug": "angry_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤬",
			"skin_tone_support": false,
			"name": "face with symbols on mouth",
			"slug": "face_with_symbols_on_mouth",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "😈",
			"skin_tone_support": false,
			"name": "smiling face with horns",
			"slug": "smiling_face_with_horns",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "👿",
			"skin_tone_support": false,
			"name": "angry face with horns",
			"slug": "angry_face_with_horns",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💀",
			"skin_tone_support": false,
			"name": "skull",
			"slug": "skull",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "☠️",
			"skin_tone_support": false,
			"name": "skull and crossbones",
			"slug": "skull_and_crossbones",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "💩",
			"skin_tone_support": false,
			"name": "pile of poo",
			"slug": "pile_of_poo",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤡",
			"skin_tone_support": false,
			"name": "clown face",
			"slug": "clown_face",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "👹",
			"skin_tone_support": false,
			"name": "ogre",
			"slug": "ogre",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👺",
			"skin_tone_support": false,
			"name": "goblin",
			"slug": "goblin",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👻",
			"skin_tone_support": false,
			"name": "ghost",
			"slug": "ghost",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👽",
			"skin_tone_support": false,
			"name": "alien",
			"slug": "alien",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👾",
			"skin_tone_support": false,
			"name": "alien monster",
			"slug": "alien_monster",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤖",
			"skin_tone_support": false,
			"name": "robot",
			"slug": "robot",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "😺",
			"skin_tone_support": false,
			"name": "grinning cat",
			"slug": "grinning_cat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😸",
			"skin_tone_support": false,
			"name": "grinning cat with smiling eyes",
			"slug": "grinning_cat_with_smiling_eyes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😹",
			"skin_tone_support": false,
			"name": "cat with tears of joy",
			"slug": "cat_with_tears_of_joy",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😻",
			"skin_tone_support": false,
			"name": "smiling cat with heart-eyes",
			"slug": "smiling_cat_with_heart_eyes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😼",
			"skin_tone_support": false,
			"name": "cat with wry smile",
			"slug": "cat_with_wry_smile",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😽",
			"skin_tone_support": false,
			"name": "kissing cat",
			"slug": "kissing_cat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙀",
			"skin_tone_support": false,
			"name": "weary cat",
			"slug": "weary_cat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😿",
			"skin_tone_support": false,
			"name": "crying cat",
			"slug": "crying_cat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "😾",
			"skin_tone_support": false,
			"name": "pouting cat",
			"slug": "pouting_cat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙈",
			"skin_tone_support": false,
			"name": "see-no-evil monkey",
			"slug": "see_no_evil_monkey",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙉",
			"skin_tone_support": false,
			"name": "hear-no-evil monkey",
			"slug": "hear_no_evil_monkey",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙊",
			"skin_tone_support": false,
			"name": "speak-no-evil monkey",
			"slug": "speak_no_evil_monkey",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💋",
			"skin_tone_support": false,
			"name": "kiss mark",
			"slug": "kiss_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💌",
			"skin_tone_support": false,
			"name": "love letter",
			"slug": "love_letter",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💘",
			"skin_tone_support": false,
			"name": "heart with arrow",
			"slug": "heart_with_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💝",
			"skin_tone_support": false,
			"name": "heart with ribbon",
			"slug": "heart_with_ribbon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💖",
			"skin_tone_support": false,
			"name": "sparkling heart",
			"slug": "sparkling_heart",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💗",
			"skin_tone_support": false,
			"name": "growing heart",
			"slug": "growing_heart",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💓",
			"skin_tone_support": false,
			"name": "beating heart",
			"slug": "beating_heart",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💞",
			"skin_tone_support": false,
			"name": "revolving hearts",
			"slug": "revolving_hearts",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💕",
			"skin_tone_support": false,
			"name": "two hearts",
			"slug": "two_hearts",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💟",
			"skin_tone_support": false,
			"name": "heart decoration",
			"slug": "heart_decoration",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "❣️",
			"skin_tone_support": false,
			"name": "heart exclamation",
			"slug": "heart_exclamation",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "💔",
			"skin_tone_support": false,
			"name": "broken heart",
			"slug": "broken_heart",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "❤️",
			"skin_tone_support": false,
			"name": "red heart",
			"slug": "red_heart",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧡",
			"skin_tone_support": false,
			"name": "orange heart",
			"slug": "orange_heart",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "💛",
			"skin_tone_support": false,
			"name": "yellow heart",
			"slug": "yellow_heart",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💚",
			"skin_tone_support": false,
			"name": "green heart",
			"slug": "green_heart",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💙",
			"skin_tone_support": false,
			"name": "blue heart",
			"slug": "blue_heart",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💜",
			"skin_tone_support": false,
			"name": "purple heart",
			"slug": "purple_heart",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤎",
			"skin_tone_support": false,
			"name": "brown heart",
			"slug": "brown_heart",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🖤",
			"skin_tone_support": false,
			"name": "black heart",
			"slug": "black_heart",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤍",
			"skin_tone_support": false,
			"name": "white heart",
			"slug": "white_heart",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "💯",
			"skin_tone_support": false,
			"name": "hundred points",
			"slug": "hundred_points",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💢",
			"skin_tone_support": false,
			"name": "anger symbol",
			"slug": "anger_symbol",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💥",
			"skin_tone_support": false,
			"name": "collision",
			"slug": "collision",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💫",
			"skin_tone_support": false,
			"name": "dizzy",
			"slug": "dizzy",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💦",
			"skin_tone_support": false,
			"name": "sweat droplets",
			"slug": "sweat_droplets",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💨",
			"skin_tone_support": false,
			"name": "dashing away",
			"slug": "dashing_away",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕳️",
			"skin_tone_support": false,
			"name": "hole",
			"slug": "hole",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "💣",
			"skin_tone_support": false,
			"name": "bomb",
			"slug": "bomb",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💬",
			"skin_tone_support": false,
			"name": "speech balloon",
			"slug": "speech_balloon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👁️‍🗨️",
			"skin_tone_support": false,
			"name": "eye in speech bubble",
			"slug": "eye_in_speech_bubble",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🗨️",
			"skin_tone_support": false,
			"name": "left speech bubble",
			"slug": "left_speech_bubble",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🗯️",
			"skin_tone_support": false,
			"name": "right anger bubble",
			"slug": "right_anger_bubble",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "💭",
			"skin_tone_support": false,
			"name": "thought balloon",
			"slug": "thought_balloon",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "💤",
			"skin_tone_support": false,
			"name": "zzz",
			"slug": "zzz",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		}
	],
	"People & Body": [
		{
			"emoji": "👋",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "waving hand",
			"slug": "waving_hand",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤚",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "raised back of hand",
			"slug": "raised_back_of_hand",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🖐️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "hand with fingers splayed",
			"slug": "hand_with_fingers_splayed",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "✋",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "raised hand",
			"slug": "raised_hand",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🖖",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "vulcan salute",
			"slug": "vulcan_salute",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "👌",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "OK hand",
			"slug": "ok_hand",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤌",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "13.0",
			"name": "pinched fingers",
			"slug": "pinched_fingers",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🤏",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "pinching hand",
			"slug": "pinching_hand",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "✌️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "victory hand",
			"slug": "victory_hand",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤞",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "crossed fingers",
			"slug": "crossed_fingers",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤟",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "love-you gesture",
			"slug": "love_you_gesture",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🤘",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "sign of the horns",
			"slug": "sign_of_the_horns",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🤙",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "call me hand",
			"slug": "call_me_hand",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "👈",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "backhand index pointing left",
			"slug": "backhand_index_pointing_left",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👉",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "backhand index pointing right",
			"slug": "backhand_index_pointing_right",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👆",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "backhand index pointing up",
			"slug": "backhand_index_pointing_up",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🖕",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "middle finger",
			"slug": "middle_finger",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "👇",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "backhand index pointing down",
			"slug": "backhand_index_pointing_down",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "☝️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "index pointing up",
			"slug": "index_pointing_up",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👍",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "thumbs up",
			"slug": "thumbs_up",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👎",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "thumbs down",
			"slug": "thumbs_down",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "✊",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "raised fist",
			"slug": "raised_fist",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👊",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "oncoming fist",
			"slug": "oncoming_fist",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤛",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "left-facing fist",
			"slug": "left_facing_fist",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤜",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "right-facing fist",
			"slug": "right_facing_fist",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "👏",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "clapping hands",
			"slug": "clapping_hands",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙌",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "raising hands",
			"slug": "raising_hands",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👐",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "open hands",
			"slug": "open_hands",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤲",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "palms up together",
			"slug": "palms_up_together",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🤝",
			"skin_tone_support": false,
			"name": "handshake",
			"slug": "handshake",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🙏",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "folded hands",
			"slug": "folded_hands",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "✍️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "writing hand",
			"slug": "writing_hand",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "💅",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "nail polish",
			"slug": "nail_polish",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤳",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "selfie",
			"slug": "selfie",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "💪",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "flexed biceps",
			"slug": "flexed_biceps",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦾",
			"skin_tone_support": false,
			"name": "mechanical arm",
			"slug": "mechanical_arm",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🦿",
			"skin_tone_support": false,
			"name": "mechanical leg",
			"slug": "mechanical_leg",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🦵",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "leg",
			"slug": "leg",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦶",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "foot",
			"slug": "foot",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "👂",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "ear",
			"slug": "ear",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦻",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "ear with hearing aid",
			"slug": "ear_with_hearing_aid",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "👃",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "nose",
			"slug": "nose",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧠",
			"skin_tone_support": false,
			"name": "brain",
			"slug": "brain",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🫀",
			"skin_tone_support": false,
			"name": "anatomical heart",
			"slug": "anatomical_heart",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🫁",
			"skin_tone_support": false,
			"name": "lungs",
			"slug": "lungs",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🦷",
			"skin_tone_support": false,
			"name": "tooth",
			"slug": "tooth",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦴",
			"skin_tone_support": false,
			"name": "bone",
			"slug": "bone",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "👀",
			"skin_tone_support": false,
			"name": "eyes",
			"slug": "eyes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👁️",
			"skin_tone_support": false,
			"name": "eye",
			"slug": "eye",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "👅",
			"skin_tone_support": false,
			"name": "tongue",
			"slug": "tongue",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👄",
			"skin_tone_support": false,
			"name": "mouth",
			"slug": "mouth",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👶",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "baby",
			"slug": "baby",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧒",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "child",
			"slug": "child",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "👦",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "boy",
			"slug": "boy",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👧",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "girl",
			"slug": "girl",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧑",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "person",
			"slug": "person",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "👱",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person blond hair",
			"slug": "person_blond_hair",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👨",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "man",
			"slug": "man",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧔",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "person beard",
			"slug": "person_beard",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "👨‍🦰",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "man red hair",
			"slug": "man_red_hair",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "👨‍🦱",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "man curly hair",
			"slug": "man_curly_hair",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "👨‍🦳",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "man white hair",
			"slug": "man_white_hair",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "👨‍🦲",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "man bald",
			"slug": "man_bald",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "👩",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "woman",
			"slug": "woman",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👩‍🦰",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "woman red hair",
			"slug": "woman_red_hair",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧑‍🦰",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "person red hair",
			"slug": "person_red_hair",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👩‍🦱",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "woman curly hair",
			"slug": "woman_curly_hair",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧑‍🦱",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "person curly hair",
			"slug": "person_curly_hair",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👩‍🦳",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "woman white hair",
			"slug": "woman_white_hair",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧑‍🦳",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "person white hair",
			"slug": "person_white_hair",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👩‍🦲",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "woman bald",
			"slug": "woman_bald",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧑‍🦲",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "person bald",
			"slug": "person_bald",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👱‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman blond hair",
			"slug": "woman_blond_hair",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👱‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man blond hair",
			"slug": "man_blond_hair",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧓",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "older person",
			"slug": "older_person",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "👴",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "old man",
			"slug": "old_man",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👵",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "old woman",
			"slug": "old_woman",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙍",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person frowning",
			"slug": "person_frowning",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙍‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man frowning",
			"slug": "man_frowning",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🙍‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman frowning",
			"slug": "woman_frowning",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🙎",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person pouting",
			"slug": "person_pouting",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙎‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man pouting",
			"slug": "man_pouting",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🙎‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman pouting",
			"slug": "woman_pouting",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🙅",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person gesturing NO",
			"slug": "person_gesturing_no",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙅‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man gesturing NO",
			"slug": "man_gesturing_no",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🙅‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman gesturing NO",
			"slug": "woman_gesturing_no",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🙆",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person gesturing OK",
			"slug": "person_gesturing_ok",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙆‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man gesturing OK",
			"slug": "man_gesturing_ok",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🙆‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman gesturing OK",
			"slug": "woman_gesturing_ok",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "💁",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person tipping hand",
			"slug": "person_tipping_hand",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💁‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man tipping hand",
			"slug": "man_tipping_hand",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "💁‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman tipping hand",
			"slug": "woman_tipping_hand",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🙋",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person raising hand",
			"slug": "person_raising_hand",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙋‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man raising hand",
			"slug": "man_raising_hand",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🙋‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman raising hand",
			"slug": "woman_raising_hand",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧏",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "deaf person",
			"slug": "deaf_person",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧏‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "deaf man",
			"slug": "deaf_man",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧏‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "deaf woman",
			"slug": "deaf_woman",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🙇",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person bowing",
			"slug": "person_bowing",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🙇‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man bowing",
			"slug": "man_bowing",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🙇‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman bowing",
			"slug": "woman_bowing",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤦",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "person facepalming",
			"slug": "person_facepalming",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤦‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man facepalming",
			"slug": "man_facepalming",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤦‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman facepalming",
			"slug": "woman_facepalming",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤷",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "person shrugging",
			"slug": "person_shrugging",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤷‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man shrugging",
			"slug": "man_shrugging",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤷‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman shrugging",
			"slug": "woman_shrugging",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍⚕️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "health worker",
			"slug": "health_worker",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍⚕️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man health worker",
			"slug": "man_health_worker",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍⚕️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman health worker",
			"slug": "woman_health_worker",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍🎓",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "student",
			"slug": "student",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🎓",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man student",
			"slug": "man_student",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍🎓",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman student",
			"slug": "woman_student",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍🏫",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "teacher",
			"slug": "teacher",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🏫",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man teacher",
			"slug": "man_teacher",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍🏫",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman teacher",
			"slug": "woman_teacher",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍⚖️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "judge",
			"slug": "judge",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍⚖️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man judge",
			"slug": "man_judge",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍⚖️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman judge",
			"slug": "woman_judge",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍🌾",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "farmer",
			"slug": "farmer",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🌾",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man farmer",
			"slug": "man_farmer",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍🌾",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman farmer",
			"slug": "woman_farmer",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍🍳",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "cook",
			"slug": "cook",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🍳",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man cook",
			"slug": "man_cook",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍🍳",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman cook",
			"slug": "woman_cook",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍🔧",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "mechanic",
			"slug": "mechanic",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🔧",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man mechanic",
			"slug": "man_mechanic",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍🔧",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman mechanic",
			"slug": "woman_mechanic",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍🏭",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "factory worker",
			"slug": "factory_worker",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🏭",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man factory worker",
			"slug": "man_factory_worker",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍🏭",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman factory worker",
			"slug": "woman_factory_worker",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍💼",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "office worker",
			"slug": "office_worker",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍💼",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man office worker",
			"slug": "man_office_worker",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍💼",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman office worker",
			"slug": "woman_office_worker",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍🔬",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "scientist",
			"slug": "scientist",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🔬",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man scientist",
			"slug": "man_scientist",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍🔬",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman scientist",
			"slug": "woman_scientist",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍💻",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "technologist",
			"slug": "technologist",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍💻",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man technologist",
			"slug": "man_technologist",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍💻",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman technologist",
			"slug": "woman_technologist",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍🎤",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "singer",
			"slug": "singer",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🎤",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man singer",
			"slug": "man_singer",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍🎤",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman singer",
			"slug": "woman_singer",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍🎨",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "artist",
			"slug": "artist",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🎨",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man artist",
			"slug": "man_artist",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍🎨",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman artist",
			"slug": "woman_artist",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍✈️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "pilot",
			"slug": "pilot",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍✈️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man pilot",
			"slug": "man_pilot",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍✈️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman pilot",
			"slug": "woman_pilot",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍🚀",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "astronaut",
			"slug": "astronaut",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🚀",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man astronaut",
			"slug": "man_astronaut",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍🚀",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman astronaut",
			"slug": "woman_astronaut",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧑‍🚒",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "firefighter",
			"slug": "firefighter",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🚒",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man firefighter",
			"slug": "man_firefighter",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍🚒",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman firefighter",
			"slug": "woman_firefighter",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👮",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "police officer",
			"slug": "police_officer",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👮‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man police officer",
			"slug": "man_police_officer",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👮‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman police officer",
			"slug": "woman_police_officer",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🕵️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "2.0",
			"name": "detective",
			"slug": "detective",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕵️‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man detective",
			"slug": "man_detective",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🕵️‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman detective",
			"slug": "woman_detective",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "💂",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "guard",
			"slug": "guard",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💂‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man guard",
			"slug": "man_guard",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "💂‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman guard",
			"slug": "woman_guard",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🥷",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "13.0",
			"name": "ninja",
			"slug": "ninja",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "👷",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "construction worker",
			"slug": "construction_worker",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👷‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man construction worker",
			"slug": "man_construction_worker",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👷‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman construction worker",
			"slug": "woman_construction_worker",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤴",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "prince",
			"slug": "prince",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "👸",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "princess",
			"slug": "princess",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👳",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person wearing turban",
			"slug": "person_wearing_turban",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👳‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man wearing turban",
			"slug": "man_wearing_turban",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👳‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman wearing turban",
			"slug": "woman_wearing_turban",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👲",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person with skullcap",
			"slug": "person_with_skullcap",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧕",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "woman with headscarf",
			"slug": "woman_with_headscarf",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🤵",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "person in tuxedo",
			"slug": "person_in_tuxedo",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤵‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "13.0",
			"name": "man in tuxedo",
			"slug": "man_in_tuxedo",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🤵‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "13.0",
			"name": "woman in tuxedo",
			"slug": "woman_in_tuxedo",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "👰",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person with veil",
			"slug": "person_with_veil",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👰‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "13.0",
			"name": "man with veil",
			"slug": "man_with_veil",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "👰‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "13.0",
			"name": "woman with veil",
			"slug": "woman_with_veil",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🤰",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "pregnant woman",
			"slug": "pregnant_woman",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤱",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "breast-feeding",
			"slug": "breast_feeding",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "👩‍🍼",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "13.0",
			"name": "woman feeding baby",
			"slug": "woman_feeding_baby",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "👨‍🍼",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "13.0",
			"name": "man feeding baby",
			"slug": "man_feeding_baby",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🧑‍🍼",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "13.0",
			"name": "person feeding baby",
			"slug": "person_feeding_baby",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "👼",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "baby angel",
			"slug": "baby_angel",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎅",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "Santa Claus",
			"slug": "santa_claus",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤶",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "Mrs. Claus",
			"slug": "mrs_claus",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🧑‍🎄",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "13.0",
			"name": "mx claus",
			"slug": "mx_claus",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🦸",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "superhero",
			"slug": "superhero",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦸‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "man superhero",
			"slug": "man_superhero",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦸‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "woman superhero",
			"slug": "woman_superhero",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦹",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "supervillain",
			"slug": "supervillain",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦹‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "man supervillain",
			"slug": "man_supervillain",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦹‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "11.0",
			"name": "woman supervillain",
			"slug": "woman_supervillain",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧙",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "mage",
			"slug": "mage",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧙‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "man mage",
			"slug": "man_mage",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧙‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "woman mage",
			"slug": "woman_mage",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧚",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "fairy",
			"slug": "fairy",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧚‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "man fairy",
			"slug": "man_fairy",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧚‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "woman fairy",
			"slug": "woman_fairy",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧛",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "vampire",
			"slug": "vampire",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧛‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "man vampire",
			"slug": "man_vampire",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧛‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "woman vampire",
			"slug": "woman_vampire",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧜",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "merperson",
			"slug": "merperson",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧜‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "merman",
			"slug": "merman",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧜‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "mermaid",
			"slug": "mermaid",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧝",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "elf",
			"slug": "elf",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧝‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "man elf",
			"slug": "man_elf",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧝‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "woman elf",
			"slug": "woman_elf",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧞",
			"skin_tone_support": false,
			"name": "genie",
			"slug": "genie",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧞‍♂️",
			"skin_tone_support": false,
			"name": "man genie",
			"slug": "man_genie",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧞‍♀️",
			"skin_tone_support": false,
			"name": "woman genie",
			"slug": "woman_genie",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧟",
			"skin_tone_support": false,
			"name": "zombie",
			"slug": "zombie",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧟‍♂️",
			"skin_tone_support": false,
			"name": "man zombie",
			"slug": "man_zombie",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧟‍♀️",
			"skin_tone_support": false,
			"name": "woman zombie",
			"slug": "woman_zombie",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "💆",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person getting massage",
			"slug": "person_getting_massage",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💆‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man getting massage",
			"slug": "man_getting_massage",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "💆‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman getting massage",
			"slug": "woman_getting_massage",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "💇",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person getting haircut",
			"slug": "person_getting_haircut",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💇‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man getting haircut",
			"slug": "man_getting_haircut",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "💇‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman getting haircut",
			"slug": "woman_getting_haircut",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🚶",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person walking",
			"slug": "person_walking",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚶‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man walking",
			"slug": "man_walking",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🚶‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman walking",
			"slug": "woman_walking",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧍",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "person standing",
			"slug": "person_standing",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧍‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "man standing",
			"slug": "man_standing",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧍‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "woman standing",
			"slug": "woman_standing",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧎",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "person kneeling",
			"slug": "person_kneeling",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧎‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "man kneeling",
			"slug": "man_kneeling",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧎‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "woman kneeling",
			"slug": "woman_kneeling",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧑‍🦯",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "person with white cane",
			"slug": "person_with_white_cane",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🦯",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "man with white cane",
			"slug": "man_with_white_cane",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "👩‍🦯",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "woman with white cane",
			"slug": "woman_with_white_cane",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧑‍🦼",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "person in motorized wheelchair",
			"slug": "person_in_motorized_wheelchair",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🦼",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "man in motorized wheelchair",
			"slug": "man_in_motorized_wheelchair",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "👩‍🦼",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "woman in motorized wheelchair",
			"slug": "woman_in_motorized_wheelchair",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧑‍🦽",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.1",
			"name": "person in manual wheelchair",
			"slug": "person_in_manual_wheelchair",
			"unicode_version": "12.1",
			"emoji_version": "12.1"
		},
		{
			"emoji": "👨‍🦽",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "man in manual wheelchair",
			"slug": "man_in_manual_wheelchair",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "👩‍🦽",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "woman in manual wheelchair",
			"slug": "woman_in_manual_wheelchair",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🏃",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person running",
			"slug": "person_running",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏃‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man running",
			"slug": "man_running",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🏃‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman running",
			"slug": "woman_running",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "💃",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "woman dancing",
			"slug": "woman_dancing",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕺",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "man dancing",
			"slug": "man_dancing",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🕴️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "person in suit levitating",
			"slug": "person_in_suit_levitating",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "👯",
			"skin_tone_support": false,
			"name": "people with bunny ears",
			"slug": "people_with_bunny_ears",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👯‍♂️",
			"skin_tone_support": false,
			"name": "men with bunny ears",
			"slug": "men_with_bunny_ears",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👯‍♀️",
			"skin_tone_support": false,
			"name": "women with bunny ears",
			"slug": "women_with_bunny_ears",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧖",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "person in steamy room",
			"slug": "person_in_steamy_room",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧖‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "man in steamy room",
			"slug": "man_in_steamy_room",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧖‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "woman in steamy room",
			"slug": "woman_in_steamy_room",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧗",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "person climbing",
			"slug": "person_climbing",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧗‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "man climbing",
			"slug": "man_climbing",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧗‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "woman climbing",
			"slug": "woman_climbing",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🤺",
			"skin_tone_support": false,
			"name": "person fencing",
			"slug": "person_fencing",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🏇",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "horse racing",
			"slug": "horse_racing",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "⛷️",
			"skin_tone_support": false,
			"name": "skier",
			"slug": "skier",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏂",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "snowboarder",
			"slug": "snowboarder",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏌️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "person golfing",
			"slug": "person_golfing",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏌️‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man golfing",
			"slug": "man_golfing",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🏌️‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman golfing",
			"slug": "woman_golfing",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🏄",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person surfing",
			"slug": "person_surfing",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏄‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man surfing",
			"slug": "man_surfing",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🏄‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman surfing",
			"slug": "woman_surfing",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🚣",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person rowing boat",
			"slug": "person_rowing_boat",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚣‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man rowing boat",
			"slug": "man_rowing_boat",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🚣‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman rowing boat",
			"slug": "woman_rowing_boat",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🏊",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person swimming",
			"slug": "person_swimming",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏊‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man swimming",
			"slug": "man_swimming",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🏊‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman swimming",
			"slug": "woman_swimming",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "⛹️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "2.0",
			"name": "person bouncing ball",
			"slug": "person_bouncing_ball",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⛹️‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man bouncing ball",
			"slug": "man_bouncing_ball",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "⛹️‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman bouncing ball",
			"slug": "woman_bouncing_ball",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🏋️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "2.0",
			"name": "person lifting weights",
			"slug": "person_lifting_weights",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏋️‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man lifting weights",
			"slug": "man_lifting_weights",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🏋️‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman lifting weights",
			"slug": "woman_lifting_weights",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🚴",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person biking",
			"slug": "person_biking",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚴‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man biking",
			"slug": "man_biking",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🚴‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman biking",
			"slug": "woman_biking",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🚵",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person mountain biking",
			"slug": "person_mountain_biking",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚵‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man mountain biking",
			"slug": "man_mountain_biking",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🚵‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman mountain biking",
			"slug": "woman_mountain_biking",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤸",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "person cartwheeling",
			"slug": "person_cartwheeling",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤸‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man cartwheeling",
			"slug": "man_cartwheeling",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤸‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman cartwheeling",
			"slug": "woman_cartwheeling",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤼",
			"skin_tone_support": false,
			"name": "people wrestling",
			"slug": "people_wrestling",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤼‍♂️",
			"skin_tone_support": false,
			"name": "men wrestling",
			"slug": "men_wrestling",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤼‍♀️",
			"skin_tone_support": false,
			"name": "women wrestling",
			"slug": "women_wrestling",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤽",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "person playing water polo",
			"slug": "person_playing_water_polo",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤽‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man playing water polo",
			"slug": "man_playing_water_polo",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤽‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman playing water polo",
			"slug": "woman_playing_water_polo",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤾",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "person playing handball",
			"slug": "person_playing_handball",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤾‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man playing handball",
			"slug": "man_playing_handball",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤾‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman playing handball",
			"slug": "woman_playing_handball",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤹",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "3.0",
			"name": "person juggling",
			"slug": "person_juggling",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🤹‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "man juggling",
			"slug": "man_juggling",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🤹‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "woman juggling",
			"slug": "woman_juggling",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🧘",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "person in lotus position",
			"slug": "person_in_lotus_position",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧘‍♂️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "man in lotus position",
			"slug": "man_in_lotus_position",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧘‍♀️",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "5.0",
			"name": "woman in lotus position",
			"slug": "woman_in_lotus_position",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🛀",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "1.0",
			"name": "person taking bath",
			"slug": "person_taking_bath",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛌",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "4.0",
			"name": "person in bed",
			"slug": "person_in_bed",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🧑‍🤝‍🧑",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "people holding hands",
			"slug": "people_holding_hands",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "👭",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "women holding hands",
			"slug": "women_holding_hands",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "👫",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "woman and man holding hands",
			"slug": "woman_and_man_holding_hands",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👬",
			"skin_tone_support": true,
			"skin_tone_support_unicode_version": "12.0",
			"name": "men holding hands",
			"slug": "men_holding_hands",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "💏",
			"skin_tone_support": false,
			"name": "kiss",
			"slug": "kiss",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👩‍❤️‍💋‍👨",
			"skin_tone_support": false,
			"name": "kiss woman, man",
			"slug": "kiss_woman_man",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍❤️‍💋‍👨",
			"skin_tone_support": false,
			"name": "kiss man, man",
			"slug": "kiss_man_man",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👩‍❤️‍💋‍👩",
			"skin_tone_support": false,
			"name": "kiss woman, woman",
			"slug": "kiss_woman_woman",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "💑",
			"skin_tone_support": false,
			"name": "couple with heart",
			"slug": "couple_with_heart",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👩‍❤️‍👨",
			"skin_tone_support": false,
			"name": "couple with heart woman, man",
			"slug": "couple_with_heart_woman_man",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍❤️‍👨",
			"skin_tone_support": false,
			"name": "couple with heart man, man",
			"slug": "couple_with_heart_man_man",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👩‍❤️‍👩",
			"skin_tone_support": false,
			"name": "couple with heart woman, woman",
			"slug": "couple_with_heart_woman_woman",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👪",
			"skin_tone_support": false,
			"name": "family",
			"slug": "family",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👨‍👩‍👦",
			"skin_tone_support": false,
			"name": "family man, woman, boy",
			"slug": "family_man_woman_boy",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍👩‍👧",
			"skin_tone_support": false,
			"name": "family man, woman, girl",
			"slug": "family_man_woman_girl",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍👩‍👧‍👦",
			"skin_tone_support": false,
			"name": "family man, woman, girl, boy",
			"slug": "family_man_woman_girl_boy",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍👩‍👦‍👦",
			"skin_tone_support": false,
			"name": "family man, woman, boy, boy",
			"slug": "family_man_woman_boy_boy",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍👩‍👧‍👧",
			"skin_tone_support": false,
			"name": "family man, woman, girl, girl",
			"slug": "family_man_woman_girl_girl",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍👨‍👦",
			"skin_tone_support": false,
			"name": "family man, man, boy",
			"slug": "family_man_man_boy",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍👨‍👧",
			"skin_tone_support": false,
			"name": "family man, man, girl",
			"slug": "family_man_man_girl",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍👨‍👧‍👦",
			"skin_tone_support": false,
			"name": "family man, man, girl, boy",
			"slug": "family_man_man_girl_boy",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍👨‍👦‍👦",
			"skin_tone_support": false,
			"name": "family man, man, boy, boy",
			"slug": "family_man_man_boy_boy",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍👨‍👧‍👧",
			"skin_tone_support": false,
			"name": "family man, man, girl, girl",
			"slug": "family_man_man_girl_girl",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👩‍👩‍👦",
			"skin_tone_support": false,
			"name": "family woman, woman, boy",
			"slug": "family_woman_woman_boy",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👩‍👩‍👧",
			"skin_tone_support": false,
			"name": "family woman, woman, girl",
			"slug": "family_woman_woman_girl",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👩‍👩‍👧‍👦",
			"skin_tone_support": false,
			"name": "family woman, woman, girl, boy",
			"slug": "family_woman_woman_girl_boy",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👩‍👩‍👦‍👦",
			"skin_tone_support": false,
			"name": "family woman, woman, boy, boy",
			"slug": "family_woman_woman_boy_boy",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👩‍👩‍👧‍👧",
			"skin_tone_support": false,
			"name": "family woman, woman, girl, girl",
			"slug": "family_woman_woman_girl_girl",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "👨‍👦",
			"skin_tone_support": false,
			"name": "family man, boy",
			"slug": "family_man_boy",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👨‍👦‍👦",
			"skin_tone_support": false,
			"name": "family man, boy, boy",
			"slug": "family_man_boy_boy",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👨‍👧",
			"skin_tone_support": false,
			"name": "family man, girl",
			"slug": "family_man_girl",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👨‍👧‍👦",
			"skin_tone_support": false,
			"name": "family man, girl, boy",
			"slug": "family_man_girl_boy",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👨‍👧‍👧",
			"skin_tone_support": false,
			"name": "family man, girl, girl",
			"slug": "family_man_girl_girl",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍👦",
			"skin_tone_support": false,
			"name": "family woman, boy",
			"slug": "family_woman_boy",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍👦‍👦",
			"skin_tone_support": false,
			"name": "family woman, boy, boy",
			"slug": "family_woman_boy_boy",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍👧",
			"skin_tone_support": false,
			"name": "family woman, girl",
			"slug": "family_woman_girl",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍👧‍👦",
			"skin_tone_support": false,
			"name": "family woman, girl, boy",
			"slug": "family_woman_girl_boy",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "👩‍👧‍👧",
			"skin_tone_support": false,
			"name": "family woman, girl, girl",
			"slug": "family_woman_girl_girl",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🗣️",
			"skin_tone_support": false,
			"name": "speaking head",
			"slug": "speaking_head",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "👤",
			"skin_tone_support": false,
			"name": "bust in silhouette",
			"slug": "bust_in_silhouette",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👥",
			"skin_tone_support": false,
			"name": "busts in silhouette",
			"slug": "busts_in_silhouette",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🫂",
			"skin_tone_support": false,
			"name": "people hugging",
			"slug": "people_hugging",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "👣",
			"skin_tone_support": false,
			"name": "footprints",
			"slug": "footprints",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		}
	],
	"Animals & Nature": [
		{
			"emoji": "🐵",
			"skin_tone_support": false,
			"name": "monkey face",
			"slug": "monkey_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐒",
			"skin_tone_support": false,
			"name": "monkey",
			"slug": "monkey",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦍",
			"skin_tone_support": false,
			"name": "gorilla",
			"slug": "gorilla",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🦧",
			"skin_tone_support": false,
			"name": "orangutan",
			"slug": "orangutan",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🐶",
			"skin_tone_support": false,
			"name": "dog face",
			"slug": "dog_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐕",
			"skin_tone_support": false,
			"name": "dog",
			"slug": "dog",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🦮",
			"skin_tone_support": false,
			"name": "guide dog",
			"slug": "guide_dog",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🐕‍🦺",
			"skin_tone_support": false,
			"name": "service dog",
			"slug": "service_dog",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🐩",
			"skin_tone_support": false,
			"name": "poodle",
			"slug": "poodle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐺",
			"skin_tone_support": false,
			"name": "wolf",
			"slug": "wolf",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦊",
			"skin_tone_support": false,
			"name": "fox",
			"slug": "fox",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🦝",
			"skin_tone_support": false,
			"name": "raccoon",
			"slug": "raccoon",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🐱",
			"skin_tone_support": false,
			"name": "cat face",
			"slug": "cat_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐈",
			"skin_tone_support": false,
			"name": "cat",
			"slug": "cat",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🐈‍⬛",
			"skin_tone_support": false,
			"name": "black cat",
			"slug": "black_cat",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🦁",
			"skin_tone_support": false,
			"name": "lion",
			"slug": "lion",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐯",
			"skin_tone_support": false,
			"name": "tiger face",
			"slug": "tiger_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐅",
			"skin_tone_support": false,
			"name": "tiger",
			"slug": "tiger",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐆",
			"skin_tone_support": false,
			"name": "leopard",
			"slug": "leopard",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐴",
			"skin_tone_support": false,
			"name": "horse face",
			"slug": "horse_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐎",
			"skin_tone_support": false,
			"name": "horse",
			"slug": "horse",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦄",
			"skin_tone_support": false,
			"name": "unicorn",
			"slug": "unicorn",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🦓",
			"skin_tone_support": false,
			"name": "zebra",
			"slug": "zebra",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🦌",
			"skin_tone_support": false,
			"name": "deer",
			"slug": "deer",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🦬",
			"skin_tone_support": false,
			"name": "bison",
			"slug": "bison",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🐮",
			"skin_tone_support": false,
			"name": "cow face",
			"slug": "cow_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐂",
			"skin_tone_support": false,
			"name": "ox",
			"slug": "ox",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐃",
			"skin_tone_support": false,
			"name": "water buffalo",
			"slug": "water_buffalo",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐄",
			"skin_tone_support": false,
			"name": "cow",
			"slug": "cow",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐷",
			"skin_tone_support": false,
			"name": "pig face",
			"slug": "pig_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐖",
			"skin_tone_support": false,
			"name": "pig",
			"slug": "pig",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐗",
			"skin_tone_support": false,
			"name": "boar",
			"slug": "boar",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐽",
			"skin_tone_support": false,
			"name": "pig nose",
			"slug": "pig_nose",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐏",
			"skin_tone_support": false,
			"name": "ram",
			"slug": "ram",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐑",
			"skin_tone_support": false,
			"name": "ewe",
			"slug": "ewe",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐐",
			"skin_tone_support": false,
			"name": "goat",
			"slug": "goat",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐪",
			"skin_tone_support": false,
			"name": "camel",
			"slug": "camel",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐫",
			"skin_tone_support": false,
			"name": "two-hump camel",
			"slug": "two_hump_camel",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦙",
			"skin_tone_support": false,
			"name": "llama",
			"slug": "llama",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦒",
			"skin_tone_support": false,
			"name": "giraffe",
			"slug": "giraffe",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🐘",
			"skin_tone_support": false,
			"name": "elephant",
			"slug": "elephant",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦣",
			"skin_tone_support": false,
			"name": "mammoth",
			"slug": "mammoth",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🦏",
			"skin_tone_support": false,
			"name": "rhinoceros",
			"slug": "rhinoceros",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🦛",
			"skin_tone_support": false,
			"name": "hippopotamus",
			"slug": "hippopotamus",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🐭",
			"skin_tone_support": false,
			"name": "mouse face",
			"slug": "mouse_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐁",
			"skin_tone_support": false,
			"name": "mouse",
			"slug": "mouse",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐀",
			"skin_tone_support": false,
			"name": "rat",
			"slug": "rat",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐹",
			"skin_tone_support": false,
			"name": "hamster",
			"slug": "hamster",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐰",
			"skin_tone_support": false,
			"name": "rabbit face",
			"slug": "rabbit_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐇",
			"skin_tone_support": false,
			"name": "rabbit",
			"slug": "rabbit",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐿️",
			"skin_tone_support": false,
			"name": "chipmunk",
			"slug": "chipmunk",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🦫",
			"skin_tone_support": false,
			"name": "beaver",
			"slug": "beaver",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🦔",
			"skin_tone_support": false,
			"name": "hedgehog",
			"slug": "hedgehog",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🦇",
			"skin_tone_support": false,
			"name": "bat",
			"slug": "bat",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🐻",
			"skin_tone_support": false,
			"name": "bear",
			"slug": "bear",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐻‍❄️",
			"skin_tone_support": false,
			"name": "polar bear",
			"slug": "polar_bear",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🐨",
			"skin_tone_support": false,
			"name": "koala",
			"slug": "koala",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐼",
			"skin_tone_support": false,
			"name": "panda",
			"slug": "panda",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦥",
			"skin_tone_support": false,
			"name": "sloth",
			"slug": "sloth",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🦦",
			"skin_tone_support": false,
			"name": "otter",
			"slug": "otter",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🦨",
			"skin_tone_support": false,
			"name": "skunk",
			"slug": "skunk",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🦘",
			"skin_tone_support": false,
			"name": "kangaroo",
			"slug": "kangaroo",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦡",
			"skin_tone_support": false,
			"name": "badger",
			"slug": "badger",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🐾",
			"skin_tone_support": false,
			"name": "paw prints",
			"slug": "paw_prints",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦃",
			"skin_tone_support": false,
			"name": "turkey",
			"slug": "turkey",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐔",
			"skin_tone_support": false,
			"name": "chicken",
			"slug": "chicken",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐓",
			"skin_tone_support": false,
			"name": "rooster",
			"slug": "rooster",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐣",
			"skin_tone_support": false,
			"name": "hatching chick",
			"slug": "hatching_chick",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐤",
			"skin_tone_support": false,
			"name": "baby chick",
			"slug": "baby_chick",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐥",
			"skin_tone_support": false,
			"name": "front-facing baby chick",
			"slug": "front_facing_baby_chick",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐦",
			"skin_tone_support": false,
			"name": "bird",
			"slug": "bird",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐧",
			"skin_tone_support": false,
			"name": "penguin",
			"slug": "penguin",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕊️",
			"skin_tone_support": false,
			"name": "dove",
			"slug": "dove",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🦅",
			"skin_tone_support": false,
			"name": "eagle",
			"slug": "eagle",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🦆",
			"skin_tone_support": false,
			"name": "duck",
			"slug": "duck",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🦢",
			"skin_tone_support": false,
			"name": "swan",
			"slug": "swan",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦉",
			"skin_tone_support": false,
			"name": "owl",
			"slug": "owl",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🦤",
			"skin_tone_support": false,
			"name": "dodo",
			"slug": "dodo",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🪶",
			"skin_tone_support": false,
			"name": "feather",
			"slug": "feather",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🦩",
			"skin_tone_support": false,
			"name": "flamingo",
			"slug": "flamingo",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🦚",
			"skin_tone_support": false,
			"name": "peacock",
			"slug": "peacock",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦜",
			"skin_tone_support": false,
			"name": "parrot",
			"slug": "parrot",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🐸",
			"skin_tone_support": false,
			"name": "frog",
			"slug": "frog",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐊",
			"skin_tone_support": false,
			"name": "crocodile",
			"slug": "crocodile",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐢",
			"skin_tone_support": false,
			"name": "turtle",
			"slug": "turtle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦎",
			"skin_tone_support": false,
			"name": "lizard",
			"slug": "lizard",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🐍",
			"skin_tone_support": false,
			"name": "snake",
			"slug": "snake",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐲",
			"skin_tone_support": false,
			"name": "dragon face",
			"slug": "dragon_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐉",
			"skin_tone_support": false,
			"name": "dragon",
			"slug": "dragon",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🦕",
			"skin_tone_support": false,
			"name": "sauropod",
			"slug": "sauropod",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🦖",
			"skin_tone_support": false,
			"name": "T-Rex",
			"slug": "t_rex",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🐳",
			"skin_tone_support": false,
			"name": "spouting whale",
			"slug": "spouting_whale",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐋",
			"skin_tone_support": false,
			"name": "whale",
			"slug": "whale",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🐬",
			"skin_tone_support": false,
			"name": "dolphin",
			"slug": "dolphin",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦭",
			"skin_tone_support": false,
			"name": "seal",
			"slug": "seal",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🐟",
			"skin_tone_support": false,
			"name": "fish",
			"slug": "fish",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐠",
			"skin_tone_support": false,
			"name": "tropical fish",
			"slug": "tropical_fish",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐡",
			"skin_tone_support": false,
			"name": "blowfish",
			"slug": "blowfish",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦈",
			"skin_tone_support": false,
			"name": "shark",
			"slug": "shark",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🐙",
			"skin_tone_support": false,
			"name": "octopus",
			"slug": "octopus",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐚",
			"skin_tone_support": false,
			"name": "spiral shell",
			"slug": "spiral_shell",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐌",
			"skin_tone_support": false,
			"name": "snail",
			"slug": "snail",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦋",
			"skin_tone_support": false,
			"name": "butterfly",
			"slug": "butterfly",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🐛",
			"skin_tone_support": false,
			"name": "bug",
			"slug": "bug",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐜",
			"skin_tone_support": false,
			"name": "ant",
			"slug": "ant",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🐝",
			"skin_tone_support": false,
			"name": "honeybee",
			"slug": "honeybee",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪲",
			"skin_tone_support": false,
			"name": "beetle",
			"slug": "beetle",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🐞",
			"skin_tone_support": false,
			"name": "lady beetle",
			"slug": "lady_beetle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🦗",
			"skin_tone_support": false,
			"name": "cricket",
			"slug": "cricket",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🪳",
			"skin_tone_support": false,
			"name": "cockroach",
			"slug": "cockroach",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🕷️",
			"skin_tone_support": false,
			"name": "spider",
			"slug": "spider",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕸️",
			"skin_tone_support": false,
			"name": "spider web",
			"slug": "spider_web",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🦂",
			"skin_tone_support": false,
			"name": "scorpion",
			"slug": "scorpion",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🦟",
			"skin_tone_support": false,
			"name": "mosquito",
			"slug": "mosquito",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🪰",
			"skin_tone_support": false,
			"name": "fly",
			"slug": "fly",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🪱",
			"skin_tone_support": false,
			"name": "worm",
			"slug": "worm",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🦠",
			"skin_tone_support": false,
			"name": "microbe",
			"slug": "microbe",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "💐",
			"skin_tone_support": false,
			"name": "bouquet",
			"slug": "bouquet",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌸",
			"skin_tone_support": false,
			"name": "cherry blossom",
			"slug": "cherry_blossom",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💮",
			"skin_tone_support": false,
			"name": "white flower",
			"slug": "white_flower",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏵️",
			"skin_tone_support": false,
			"name": "rosette",
			"slug": "rosette",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌹",
			"skin_tone_support": false,
			"name": "rose",
			"slug": "rose",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥀",
			"skin_tone_support": false,
			"name": "wilted flower",
			"slug": "wilted_flower",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🌺",
			"skin_tone_support": false,
			"name": "hibiscus",
			"slug": "hibiscus",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌻",
			"skin_tone_support": false,
			"name": "sunflower",
			"slug": "sunflower",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌼",
			"skin_tone_support": false,
			"name": "blossom",
			"slug": "blossom",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌷",
			"skin_tone_support": false,
			"name": "tulip",
			"slug": "tulip",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌱",
			"skin_tone_support": false,
			"name": "seedling",
			"slug": "seedling",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪴",
			"skin_tone_support": false,
			"name": "potted plant",
			"slug": "potted_plant",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🌲",
			"skin_tone_support": false,
			"name": "evergreen tree",
			"slug": "evergreen_tree",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🌳",
			"skin_tone_support": false,
			"name": "deciduous tree",
			"slug": "deciduous_tree",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🌴",
			"skin_tone_support": false,
			"name": "palm tree",
			"slug": "palm_tree",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌵",
			"skin_tone_support": false,
			"name": "cactus",
			"slug": "cactus",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌾",
			"skin_tone_support": false,
			"name": "sheaf of rice",
			"slug": "sheaf_of_rice",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌿",
			"skin_tone_support": false,
			"name": "herb",
			"slug": "herb",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "☘️",
			"skin_tone_support": false,
			"name": "shamrock",
			"slug": "shamrock",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🍀",
			"skin_tone_support": false,
			"name": "four leaf clover",
			"slug": "four_leaf_clover",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍁",
			"skin_tone_support": false,
			"name": "maple leaf",
			"slug": "maple_leaf",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍂",
			"skin_tone_support": false,
			"name": "fallen leaf",
			"slug": "fallen_leaf",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍃",
			"skin_tone_support": false,
			"name": "leaf fluttering in wind",
			"slug": "leaf_fluttering_in_wind",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		}
	],
	"Food & Drink": [
		{
			"emoji": "🍇",
			"skin_tone_support": false,
			"name": "grapes",
			"slug": "grapes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍈",
			"skin_tone_support": false,
			"name": "melon",
			"slug": "melon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍉",
			"skin_tone_support": false,
			"name": "watermelon",
			"slug": "watermelon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍊",
			"skin_tone_support": false,
			"name": "tangerine",
			"slug": "tangerine",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍋",
			"skin_tone_support": false,
			"name": "lemon",
			"slug": "lemon",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🍌",
			"skin_tone_support": false,
			"name": "banana",
			"slug": "banana",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍍",
			"skin_tone_support": false,
			"name": "pineapple",
			"slug": "pineapple",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥭",
			"skin_tone_support": false,
			"name": "mango",
			"slug": "mango",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🍎",
			"skin_tone_support": false,
			"name": "red apple",
			"slug": "red_apple",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍏",
			"skin_tone_support": false,
			"name": "green apple",
			"slug": "green_apple",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍐",
			"skin_tone_support": false,
			"name": "pear",
			"slug": "pear",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🍑",
			"skin_tone_support": false,
			"name": "peach",
			"slug": "peach",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍒",
			"skin_tone_support": false,
			"name": "cherries",
			"slug": "cherries",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍓",
			"skin_tone_support": false,
			"name": "strawberry",
			"slug": "strawberry",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🫐",
			"skin_tone_support": false,
			"name": "blueberries",
			"slug": "blueberries",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🥝",
			"skin_tone_support": false,
			"name": "kiwi fruit",
			"slug": "kiwi_fruit",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🍅",
			"skin_tone_support": false,
			"name": "tomato",
			"slug": "tomato",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🫒",
			"skin_tone_support": false,
			"name": "olive",
			"slug": "olive",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🥥",
			"skin_tone_support": false,
			"name": "coconut",
			"slug": "coconut",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🥑",
			"skin_tone_support": false,
			"name": "avocado",
			"slug": "avocado",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🍆",
			"skin_tone_support": false,
			"name": "eggplant",
			"slug": "eggplant",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥔",
			"skin_tone_support": false,
			"name": "potato",
			"slug": "potato",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🥕",
			"skin_tone_support": false,
			"name": "carrot",
			"slug": "carrot",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🌽",
			"skin_tone_support": false,
			"name": "ear of corn",
			"slug": "ear_of_corn",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌶️",
			"skin_tone_support": false,
			"name": "hot pepper",
			"slug": "hot_pepper",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🫑",
			"skin_tone_support": false,
			"name": "bell pepper",
			"slug": "bell_pepper",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🥒",
			"skin_tone_support": false,
			"name": "cucumber",
			"slug": "cucumber",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🥬",
			"skin_tone_support": false,
			"name": "leafy green",
			"slug": "leafy_green",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🥦",
			"skin_tone_support": false,
			"name": "broccoli",
			"slug": "broccoli",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧄",
			"skin_tone_support": false,
			"name": "garlic",
			"slug": "garlic",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧅",
			"skin_tone_support": false,
			"name": "onion",
			"slug": "onion",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🍄",
			"skin_tone_support": false,
			"name": "mushroom",
			"slug": "mushroom",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥜",
			"skin_tone_support": false,
			"name": "peanuts",
			"slug": "peanuts",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🌰",
			"skin_tone_support": false,
			"name": "chestnut",
			"slug": "chestnut",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍞",
			"skin_tone_support": false,
			"name": "bread",
			"slug": "bread",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥐",
			"skin_tone_support": false,
			"name": "croissant",
			"slug": "croissant",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🥖",
			"skin_tone_support": false,
			"name": "baguette bread",
			"slug": "baguette_bread",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🫓",
			"skin_tone_support": false,
			"name": "flatbread",
			"slug": "flatbread",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🥨",
			"skin_tone_support": false,
			"name": "pretzel",
			"slug": "pretzel",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🥯",
			"skin_tone_support": false,
			"name": "bagel",
			"slug": "bagel",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🥞",
			"skin_tone_support": false,
			"name": "pancakes",
			"slug": "pancakes",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🧇",
			"skin_tone_support": false,
			"name": "waffle",
			"slug": "waffle",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧀",
			"skin_tone_support": false,
			"name": "cheese wedge",
			"slug": "cheese_wedge",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🍖",
			"skin_tone_support": false,
			"name": "meat on bone",
			"slug": "meat_on_bone",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍗",
			"skin_tone_support": false,
			"name": "poultry leg",
			"slug": "poultry_leg",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥩",
			"skin_tone_support": false,
			"name": "cut of meat",
			"slug": "cut_of_meat",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🥓",
			"skin_tone_support": false,
			"name": "bacon",
			"slug": "bacon",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🍔",
			"skin_tone_support": false,
			"name": "hamburger",
			"slug": "hamburger",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍟",
			"skin_tone_support": false,
			"name": "french fries",
			"slug": "french_fries",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍕",
			"skin_tone_support": false,
			"name": "pizza",
			"slug": "pizza",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌭",
			"skin_tone_support": false,
			"name": "hot dog",
			"slug": "hot_dog",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🥪",
			"skin_tone_support": false,
			"name": "sandwich",
			"slug": "sandwich",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🌮",
			"skin_tone_support": false,
			"name": "taco",
			"slug": "taco",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🌯",
			"skin_tone_support": false,
			"name": "burrito",
			"slug": "burrito",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🫔",
			"skin_tone_support": false,
			"name": "tamale",
			"slug": "tamale",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🥙",
			"skin_tone_support": false,
			"name": "stuffed flatbread",
			"slug": "stuffed_flatbread",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🧆",
			"skin_tone_support": false,
			"name": "falafel",
			"slug": "falafel",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🥚",
			"skin_tone_support": false,
			"name": "egg",
			"slug": "egg",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🍳",
			"skin_tone_support": false,
			"name": "cooking",
			"slug": "cooking",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥘",
			"skin_tone_support": false,
			"name": "shallow pan of food",
			"slug": "shallow_pan_of_food",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🍲",
			"skin_tone_support": false,
			"name": "pot of food",
			"slug": "pot_of_food",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🫕",
			"skin_tone_support": false,
			"name": "fondue",
			"slug": "fondue",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🥣",
			"skin_tone_support": false,
			"name": "bowl with spoon",
			"slug": "bowl_with_spoon",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🥗",
			"skin_tone_support": false,
			"name": "green salad",
			"slug": "green_salad",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🍿",
			"skin_tone_support": false,
			"name": "popcorn",
			"slug": "popcorn",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🧈",
			"skin_tone_support": false,
			"name": "butter",
			"slug": "butter",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧂",
			"skin_tone_support": false,
			"name": "salt",
			"slug": "salt",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🥫",
			"skin_tone_support": false,
			"name": "canned food",
			"slug": "canned_food",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🍱",
			"skin_tone_support": false,
			"name": "bento box",
			"slug": "bento_box",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍘",
			"skin_tone_support": false,
			"name": "rice cracker",
			"slug": "rice_cracker",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍙",
			"skin_tone_support": false,
			"name": "rice ball",
			"slug": "rice_ball",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍚",
			"skin_tone_support": false,
			"name": "cooked rice",
			"slug": "cooked_rice",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍛",
			"skin_tone_support": false,
			"name": "curry rice",
			"slug": "curry_rice",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍜",
			"skin_tone_support": false,
			"name": "steaming bowl",
			"slug": "steaming_bowl",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍝",
			"skin_tone_support": false,
			"name": "spaghetti",
			"slug": "spaghetti",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍠",
			"skin_tone_support": false,
			"name": "roasted sweet potato",
			"slug": "roasted_sweet_potato",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍢",
			"skin_tone_support": false,
			"name": "oden",
			"slug": "oden",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍣",
			"skin_tone_support": false,
			"name": "sushi",
			"slug": "sushi",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍤",
			"skin_tone_support": false,
			"name": "fried shrimp",
			"slug": "fried_shrimp",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍥",
			"skin_tone_support": false,
			"name": "fish cake with swirl",
			"slug": "fish_cake_with_swirl",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥮",
			"skin_tone_support": false,
			"name": "moon cake",
			"slug": "moon_cake",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🍡",
			"skin_tone_support": false,
			"name": "dango",
			"slug": "dango",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥟",
			"skin_tone_support": false,
			"name": "dumpling",
			"slug": "dumpling",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🥠",
			"skin_tone_support": false,
			"name": "fortune cookie",
			"slug": "fortune_cookie",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🥡",
			"skin_tone_support": false,
			"name": "takeout box",
			"slug": "takeout_box",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🦀",
			"skin_tone_support": false,
			"name": "crab",
			"slug": "crab",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🦞",
			"skin_tone_support": false,
			"name": "lobster",
			"slug": "lobster",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦐",
			"skin_tone_support": false,
			"name": "shrimp",
			"slug": "shrimp",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🦑",
			"skin_tone_support": false,
			"name": "squid",
			"slug": "squid",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🦪",
			"skin_tone_support": false,
			"name": "oyster",
			"slug": "oyster",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🍦",
			"skin_tone_support": false,
			"name": "soft ice cream",
			"slug": "soft_ice_cream",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍧",
			"skin_tone_support": false,
			"name": "shaved ice",
			"slug": "shaved_ice",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍨",
			"skin_tone_support": false,
			"name": "ice cream",
			"slug": "ice_cream",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍩",
			"skin_tone_support": false,
			"name": "doughnut",
			"slug": "doughnut",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍪",
			"skin_tone_support": false,
			"name": "cookie",
			"slug": "cookie",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎂",
			"skin_tone_support": false,
			"name": "birthday cake",
			"slug": "birthday_cake",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍰",
			"skin_tone_support": false,
			"name": "shortcake",
			"slug": "shortcake",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧁",
			"skin_tone_support": false,
			"name": "cupcake",
			"slug": "cupcake",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🥧",
			"skin_tone_support": false,
			"name": "pie",
			"slug": "pie",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🍫",
			"skin_tone_support": false,
			"name": "chocolate bar",
			"slug": "chocolate_bar",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍬",
			"skin_tone_support": false,
			"name": "candy",
			"slug": "candy",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍭",
			"skin_tone_support": false,
			"name": "lollipop",
			"slug": "lollipop",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍮",
			"skin_tone_support": false,
			"name": "custard",
			"slug": "custard",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍯",
			"skin_tone_support": false,
			"name": "honey pot",
			"slug": "honey_pot",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍼",
			"skin_tone_support": false,
			"name": "baby bottle",
			"slug": "baby_bottle",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🥛",
			"skin_tone_support": false,
			"name": "glass of milk",
			"slug": "glass_of_milk",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "☕",
			"skin_tone_support": false,
			"name": "hot beverage",
			"slug": "hot_beverage",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🫖",
			"skin_tone_support": false,
			"name": "teapot",
			"slug": "teapot",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🍵",
			"skin_tone_support": false,
			"name": "teacup without handle",
			"slug": "teacup_without_handle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍶",
			"skin_tone_support": false,
			"name": "sake",
			"slug": "sake",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍾",
			"skin_tone_support": false,
			"name": "bottle with popping cork",
			"slug": "bottle_with_popping_cork",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🍷",
			"skin_tone_support": false,
			"name": "wine glass",
			"slug": "wine_glass",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍸",
			"skin_tone_support": false,
			"name": "cocktail glass",
			"slug": "cocktail_glass",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍹",
			"skin_tone_support": false,
			"name": "tropical drink",
			"slug": "tropical_drink",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍺",
			"skin_tone_support": false,
			"name": "beer mug",
			"slug": "beer_mug",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🍻",
			"skin_tone_support": false,
			"name": "clinking beer mugs",
			"slug": "clinking_beer_mugs",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥂",
			"skin_tone_support": false,
			"name": "clinking glasses",
			"slug": "clinking_glasses",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🥃",
			"skin_tone_support": false,
			"name": "tumbler glass",
			"slug": "tumbler_glass",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🥤",
			"skin_tone_support": false,
			"name": "cup with straw",
			"slug": "cup_with_straw",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧋",
			"skin_tone_support": false,
			"name": "bubble tea",
			"slug": "bubble_tea",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🧃",
			"skin_tone_support": false,
			"name": "beverage box",
			"slug": "beverage_box",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧉",
			"skin_tone_support": false,
			"name": "mate",
			"slug": "mate",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧊",
			"skin_tone_support": false,
			"name": "ice",
			"slug": "ice",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🥢",
			"skin_tone_support": false,
			"name": "chopsticks",
			"slug": "chopsticks",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🍽️",
			"skin_tone_support": false,
			"name": "fork and knife with plate",
			"slug": "fork_and_knife_with_plate",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🍴",
			"skin_tone_support": false,
			"name": "fork and knife",
			"slug": "fork_and_knife",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥄",
			"skin_tone_support": false,
			"name": "spoon",
			"slug": "spoon",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🔪",
			"skin_tone_support": false,
			"name": "kitchen knife",
			"slug": "kitchen_knife",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏺",
			"skin_tone_support": false,
			"name": "amphora",
			"slug": "amphora",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		}
	],
	"Travel & Places": [
		{
			"emoji": "🌍",
			"skin_tone_support": false,
			"name": "globe showing Europe-Africa",
			"slug": "globe_showing_europe_africa",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌎",
			"skin_tone_support": false,
			"name": "globe showing Americas",
			"slug": "globe_showing_americas",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌏",
			"skin_tone_support": false,
			"name": "globe showing Asia-Australia",
			"slug": "globe_showing_asia_australia",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌐",
			"skin_tone_support": false,
			"name": "globe with meridians",
			"slug": "globe_with_meridians",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🗺️",
			"skin_tone_support": false,
			"name": "world map",
			"slug": "world_map",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🗾",
			"skin_tone_support": false,
			"name": "map of Japan",
			"slug": "map_of_japan",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧭",
			"skin_tone_support": false,
			"name": "compass",
			"slug": "compass",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🏔️",
			"skin_tone_support": false,
			"name": "snow-capped mountain",
			"slug": "snow_capped_mountain",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⛰️",
			"skin_tone_support": false,
			"name": "mountain",
			"slug": "mountain",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌋",
			"skin_tone_support": false,
			"name": "volcano",
			"slug": "volcano",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🗻",
			"skin_tone_support": false,
			"name": "mount fuji",
			"slug": "mount_fuji",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏕️",
			"skin_tone_support": false,
			"name": "camping",
			"slug": "camping",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏖️",
			"skin_tone_support": false,
			"name": "beach with umbrella",
			"slug": "beach_with_umbrella",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏜️",
			"skin_tone_support": false,
			"name": "desert",
			"slug": "desert",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏝️",
			"skin_tone_support": false,
			"name": "desert island",
			"slug": "desert_island",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏞️",
			"skin_tone_support": false,
			"name": "national park",
			"slug": "national_park",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏟️",
			"skin_tone_support": false,
			"name": "stadium",
			"slug": "stadium",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏛️",
			"skin_tone_support": false,
			"name": "classical building",
			"slug": "classical_building",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏗️",
			"skin_tone_support": false,
			"name": "building construction",
			"slug": "building_construction",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🧱",
			"skin_tone_support": false,
			"name": "brick",
			"slug": "brick",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🪨",
			"skin_tone_support": false,
			"name": "rock",
			"slug": "rock",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🪵",
			"skin_tone_support": false,
			"name": "wood",
			"slug": "wood",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🛖",
			"skin_tone_support": false,
			"name": "hut",
			"slug": "hut",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🏘️",
			"skin_tone_support": false,
			"name": "houses",
			"slug": "houses",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏚️",
			"skin_tone_support": false,
			"name": "derelict house",
			"slug": "derelict_house",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏠",
			"skin_tone_support": false,
			"name": "house",
			"slug": "house",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏡",
			"skin_tone_support": false,
			"name": "house with garden",
			"slug": "house_with_garden",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏢",
			"skin_tone_support": false,
			"name": "office building",
			"slug": "office_building",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏣",
			"skin_tone_support": false,
			"name": "Japanese post office",
			"slug": "japanese_post_office",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏤",
			"skin_tone_support": false,
			"name": "post office",
			"slug": "post_office",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🏥",
			"skin_tone_support": false,
			"name": "hospital",
			"slug": "hospital",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏦",
			"skin_tone_support": false,
			"name": "bank",
			"slug": "bank",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏨",
			"skin_tone_support": false,
			"name": "hotel",
			"slug": "hotel",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏩",
			"skin_tone_support": false,
			"name": "love hotel",
			"slug": "love_hotel",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏪",
			"skin_tone_support": false,
			"name": "convenience store",
			"slug": "convenience_store",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏫",
			"skin_tone_support": false,
			"name": "school",
			"slug": "school",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏬",
			"skin_tone_support": false,
			"name": "department store",
			"slug": "department_store",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏭",
			"skin_tone_support": false,
			"name": "factory",
			"slug": "factory",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏯",
			"skin_tone_support": false,
			"name": "Japanese castle",
			"slug": "japanese_castle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏰",
			"skin_tone_support": false,
			"name": "castle",
			"slug": "castle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💒",
			"skin_tone_support": false,
			"name": "wedding",
			"slug": "wedding",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🗼",
			"skin_tone_support": false,
			"name": "Tokyo tower",
			"slug": "tokyo_tower",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🗽",
			"skin_tone_support": false,
			"name": "Statue of Liberty",
			"slug": "statue_of_liberty",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⛪",
			"skin_tone_support": false,
			"name": "church",
			"slug": "church",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕌",
			"skin_tone_support": false,
			"name": "mosque",
			"slug": "mosque",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🛕",
			"skin_tone_support": false,
			"name": "hindu temple",
			"slug": "hindu_temple",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🕍",
			"skin_tone_support": false,
			"name": "synagogue",
			"slug": "synagogue",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "⛩️",
			"skin_tone_support": false,
			"name": "shinto shrine",
			"slug": "shinto_shrine",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕋",
			"skin_tone_support": false,
			"name": "kaaba",
			"slug": "kaaba",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "⛲",
			"skin_tone_support": false,
			"name": "fountain",
			"slug": "fountain",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⛺",
			"skin_tone_support": false,
			"name": "tent",
			"slug": "tent",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌁",
			"skin_tone_support": false,
			"name": "foggy",
			"slug": "foggy",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌃",
			"skin_tone_support": false,
			"name": "night with stars",
			"slug": "night_with_stars",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏙️",
			"skin_tone_support": false,
			"name": "cityscape",
			"slug": "cityscape",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌄",
			"skin_tone_support": false,
			"name": "sunrise over mountains",
			"slug": "sunrise_over_mountains",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌅",
			"skin_tone_support": false,
			"name": "sunrise",
			"slug": "sunrise",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌆",
			"skin_tone_support": false,
			"name": "cityscape at dusk",
			"slug": "cityscape_at_dusk",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌇",
			"skin_tone_support": false,
			"name": "sunset",
			"slug": "sunset",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌉",
			"skin_tone_support": false,
			"name": "bridge at night",
			"slug": "bridge_at_night",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♨️",
			"skin_tone_support": false,
			"name": "hot springs",
			"slug": "hot_springs",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎠",
			"skin_tone_support": false,
			"name": "carousel horse",
			"slug": "carousel_horse",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎡",
			"skin_tone_support": false,
			"name": "ferris wheel",
			"slug": "ferris_wheel",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎢",
			"skin_tone_support": false,
			"name": "roller coaster",
			"slug": "roller_coaster",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💈",
			"skin_tone_support": false,
			"name": "barber pole",
			"slug": "barber_pole",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎪",
			"skin_tone_support": false,
			"name": "circus tent",
			"slug": "circus_tent",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚂",
			"skin_tone_support": false,
			"name": "locomotive",
			"slug": "locomotive",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚃",
			"skin_tone_support": false,
			"name": "railway car",
			"slug": "railway_car",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚄",
			"skin_tone_support": false,
			"name": "high-speed train",
			"slug": "high_speed_train",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚅",
			"skin_tone_support": false,
			"name": "bullet train",
			"slug": "bullet_train",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚆",
			"skin_tone_support": false,
			"name": "train",
			"slug": "train",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚇",
			"skin_tone_support": false,
			"name": "metro",
			"slug": "metro",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚈",
			"skin_tone_support": false,
			"name": "light rail",
			"slug": "light_rail",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚉",
			"skin_tone_support": false,
			"name": "station",
			"slug": "station",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚊",
			"skin_tone_support": false,
			"name": "tram",
			"slug": "tram",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚝",
			"skin_tone_support": false,
			"name": "monorail",
			"slug": "monorail",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚞",
			"skin_tone_support": false,
			"name": "mountain railway",
			"slug": "mountain_railway",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚋",
			"skin_tone_support": false,
			"name": "tram car",
			"slug": "tram_car",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚌",
			"skin_tone_support": false,
			"name": "bus",
			"slug": "bus",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚍",
			"skin_tone_support": false,
			"name": "oncoming bus",
			"slug": "oncoming_bus",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🚎",
			"skin_tone_support": false,
			"name": "trolleybus",
			"slug": "trolleybus",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚐",
			"skin_tone_support": false,
			"name": "minibus",
			"slug": "minibus",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚑",
			"skin_tone_support": false,
			"name": "ambulance",
			"slug": "ambulance",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚒",
			"skin_tone_support": false,
			"name": "fire engine",
			"slug": "fire_engine",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚓",
			"skin_tone_support": false,
			"name": "police car",
			"slug": "police_car",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚔",
			"skin_tone_support": false,
			"name": "oncoming police car",
			"slug": "oncoming_police_car",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🚕",
			"skin_tone_support": false,
			"name": "taxi",
			"slug": "taxi",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚖",
			"skin_tone_support": false,
			"name": "oncoming taxi",
			"slug": "oncoming_taxi",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚗",
			"skin_tone_support": false,
			"name": "automobile",
			"slug": "automobile",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚘",
			"skin_tone_support": false,
			"name": "oncoming automobile",
			"slug": "oncoming_automobile",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🚙",
			"skin_tone_support": false,
			"name": "sport utility vehicle",
			"slug": "sport_utility_vehicle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛻",
			"skin_tone_support": false,
			"name": "pickup truck",
			"slug": "pickup_truck",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🚚",
			"skin_tone_support": false,
			"name": "delivery truck",
			"slug": "delivery_truck",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚛",
			"skin_tone_support": false,
			"name": "articulated lorry",
			"slug": "articulated_lorry",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚜",
			"skin_tone_support": false,
			"name": "tractor",
			"slug": "tractor",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🏎️",
			"skin_tone_support": false,
			"name": "racing car",
			"slug": "racing_car",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏍️",
			"skin_tone_support": false,
			"name": "motorcycle",
			"slug": "motorcycle",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🛵",
			"skin_tone_support": false,
			"name": "motor scooter",
			"slug": "motor_scooter",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🦽",
			"skin_tone_support": false,
			"name": "manual wheelchair",
			"slug": "manual_wheelchair",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🦼",
			"skin_tone_support": false,
			"name": "motorized wheelchair",
			"slug": "motorized_wheelchair",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🛺",
			"skin_tone_support": false,
			"name": "auto rickshaw",
			"slug": "auto_rickshaw",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🚲",
			"skin_tone_support": false,
			"name": "bicycle",
			"slug": "bicycle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛴",
			"skin_tone_support": false,
			"name": "kick scooter",
			"slug": "kick_scooter",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🛹",
			"skin_tone_support": false,
			"name": "skateboard",
			"slug": "skateboard",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🛼",
			"skin_tone_support": false,
			"name": "roller skate",
			"slug": "roller_skate",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🚏",
			"skin_tone_support": false,
			"name": "bus stop",
			"slug": "bus_stop",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛣️",
			"skin_tone_support": false,
			"name": "motorway",
			"slug": "motorway",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🛤️",
			"skin_tone_support": false,
			"name": "railway track",
			"slug": "railway_track",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🛢️",
			"skin_tone_support": false,
			"name": "oil drum",
			"slug": "oil_drum",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⛽",
			"skin_tone_support": false,
			"name": "fuel pump",
			"slug": "fuel_pump",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚨",
			"skin_tone_support": false,
			"name": "police car light",
			"slug": "police_car_light",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚥",
			"skin_tone_support": false,
			"name": "horizontal traffic light",
			"slug": "horizontal_traffic_light",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚦",
			"skin_tone_support": false,
			"name": "vertical traffic light",
			"slug": "vertical_traffic_light",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🛑",
			"skin_tone_support": false,
			"name": "stop sign",
			"slug": "stop_sign",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🚧",
			"skin_tone_support": false,
			"name": "construction",
			"slug": "construction",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⚓",
			"skin_tone_support": false,
			"name": "anchor",
			"slug": "anchor",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⛵",
			"skin_tone_support": false,
			"name": "sailboat",
			"slug": "sailboat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛶",
			"skin_tone_support": false,
			"name": "canoe",
			"slug": "canoe",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🚤",
			"skin_tone_support": false,
			"name": "speedboat",
			"slug": "speedboat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛳️",
			"skin_tone_support": false,
			"name": "passenger ship",
			"slug": "passenger_ship",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⛴️",
			"skin_tone_support": false,
			"name": "ferry",
			"slug": "ferry",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🛥️",
			"skin_tone_support": false,
			"name": "motor boat",
			"slug": "motor_boat",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🚢",
			"skin_tone_support": false,
			"name": "ship",
			"slug": "ship",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "✈️",
			"skin_tone_support": false,
			"name": "airplane",
			"slug": "airplane",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛩️",
			"skin_tone_support": false,
			"name": "small airplane",
			"slug": "small_airplane",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🛫",
			"skin_tone_support": false,
			"name": "airplane departure",
			"slug": "airplane_departure",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🛬",
			"skin_tone_support": false,
			"name": "airplane arrival",
			"slug": "airplane_arrival",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🪂",
			"skin_tone_support": false,
			"name": "parachute",
			"slug": "parachute",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "💺",
			"skin_tone_support": false,
			"name": "seat",
			"slug": "seat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚁",
			"skin_tone_support": false,
			"name": "helicopter",
			"slug": "helicopter",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚟",
			"skin_tone_support": false,
			"name": "suspension railway",
			"slug": "suspension_railway",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚠",
			"skin_tone_support": false,
			"name": "mountain cableway",
			"slug": "mountain_cableway",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚡",
			"skin_tone_support": false,
			"name": "aerial tramway",
			"slug": "aerial_tramway",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🛰️",
			"skin_tone_support": false,
			"name": "satellite",
			"slug": "satellite",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🚀",
			"skin_tone_support": false,
			"name": "rocket",
			"slug": "rocket",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛸",
			"skin_tone_support": false,
			"name": "flying saucer",
			"slug": "flying_saucer",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🛎️",
			"skin_tone_support": false,
			"name": "bellhop bell",
			"slug": "bellhop_bell",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🧳",
			"skin_tone_support": false,
			"name": "luggage",
			"slug": "luggage",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "⌛",
			"skin_tone_support": false,
			"name": "hourglass done",
			"slug": "hourglass_done",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⏳",
			"skin_tone_support": false,
			"name": "hourglass not done",
			"slug": "hourglass_not_done",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⌚",
			"skin_tone_support": false,
			"name": "watch",
			"slug": "watch",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⏰",
			"skin_tone_support": false,
			"name": "alarm clock",
			"slug": "alarm_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⏱️",
			"skin_tone_support": false,
			"name": "stopwatch",
			"slug": "stopwatch",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "⏲️",
			"skin_tone_support": false,
			"name": "timer clock",
			"slug": "timer_clock",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🕰️",
			"skin_tone_support": false,
			"name": "mantelpiece clock",
			"slug": "mantelpiece_clock",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕛",
			"skin_tone_support": false,
			"name": "twelve o’clock",
			"slug": "twelve_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕧",
			"skin_tone_support": false,
			"name": "twelve-thirty",
			"slug": "twelve_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕐",
			"skin_tone_support": false,
			"name": "one o’clock",
			"slug": "one_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕜",
			"skin_tone_support": false,
			"name": "one-thirty",
			"slug": "one_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕑",
			"skin_tone_support": false,
			"name": "two o’clock",
			"slug": "two_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕝",
			"skin_tone_support": false,
			"name": "two-thirty",
			"slug": "two_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕒",
			"skin_tone_support": false,
			"name": "three o’clock",
			"slug": "three_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕞",
			"skin_tone_support": false,
			"name": "three-thirty",
			"slug": "three_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕓",
			"skin_tone_support": false,
			"name": "four o’clock",
			"slug": "four_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕟",
			"skin_tone_support": false,
			"name": "four-thirty",
			"slug": "four_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕔",
			"skin_tone_support": false,
			"name": "five o’clock",
			"slug": "five_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕠",
			"skin_tone_support": false,
			"name": "five-thirty",
			"slug": "five_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕕",
			"skin_tone_support": false,
			"name": "six o’clock",
			"slug": "six_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕡",
			"skin_tone_support": false,
			"name": "six-thirty",
			"slug": "six_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕖",
			"skin_tone_support": false,
			"name": "seven o’clock",
			"slug": "seven_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕢",
			"skin_tone_support": false,
			"name": "seven-thirty",
			"slug": "seven_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕗",
			"skin_tone_support": false,
			"name": "eight o’clock",
			"slug": "eight_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕣",
			"skin_tone_support": false,
			"name": "eight-thirty",
			"slug": "eight_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕘",
			"skin_tone_support": false,
			"name": "nine o’clock",
			"slug": "nine_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕤",
			"skin_tone_support": false,
			"name": "nine-thirty",
			"slug": "nine_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕙",
			"skin_tone_support": false,
			"name": "ten o’clock",
			"slug": "ten_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕥",
			"skin_tone_support": false,
			"name": "ten-thirty",
			"slug": "ten_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🕚",
			"skin_tone_support": false,
			"name": "eleven o’clock",
			"slug": "eleven_o_clock",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕦",
			"skin_tone_support": false,
			"name": "eleven-thirty",
			"slug": "eleven_thirty",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌑",
			"skin_tone_support": false,
			"name": "new moon",
			"slug": "new_moon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌒",
			"skin_tone_support": false,
			"name": "waxing crescent moon",
			"slug": "waxing_crescent_moon",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🌓",
			"skin_tone_support": false,
			"name": "first quarter moon",
			"slug": "first_quarter_moon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌔",
			"skin_tone_support": false,
			"name": "waxing gibbous moon",
			"slug": "waxing_gibbous_moon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌕",
			"skin_tone_support": false,
			"name": "full moon",
			"slug": "full_moon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌖",
			"skin_tone_support": false,
			"name": "waning gibbous moon",
			"slug": "waning_gibbous_moon",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🌗",
			"skin_tone_support": false,
			"name": "last quarter moon",
			"slug": "last_quarter_moon",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🌘",
			"skin_tone_support": false,
			"name": "waning crescent moon",
			"slug": "waning_crescent_moon",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🌙",
			"skin_tone_support": false,
			"name": "crescent moon",
			"slug": "crescent_moon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌚",
			"skin_tone_support": false,
			"name": "new moon face",
			"slug": "new_moon_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🌛",
			"skin_tone_support": false,
			"name": "first quarter moon face",
			"slug": "first_quarter_moon_face",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌜",
			"skin_tone_support": false,
			"name": "last quarter moon face",
			"slug": "last_quarter_moon_face",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌡️",
			"skin_tone_support": false,
			"name": "thermometer",
			"slug": "thermometer",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "☀️",
			"skin_tone_support": false,
			"name": "sun",
			"slug": "sun",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌝",
			"skin_tone_support": false,
			"name": "full moon face",
			"slug": "full_moon_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🌞",
			"skin_tone_support": false,
			"name": "sun with face",
			"slug": "sun_with_face",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🪐",
			"skin_tone_support": false,
			"name": "ringed planet",
			"slug": "ringed_planet",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "⭐",
			"skin_tone_support": false,
			"name": "star",
			"slug": "star",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌟",
			"skin_tone_support": false,
			"name": "glowing star",
			"slug": "glowing_star",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌠",
			"skin_tone_support": false,
			"name": "shooting star",
			"slug": "shooting_star",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌌",
			"skin_tone_support": false,
			"name": "milky way",
			"slug": "milky_way",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "☁️",
			"skin_tone_support": false,
			"name": "cloud",
			"slug": "cloud",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⛅",
			"skin_tone_support": false,
			"name": "sun behind cloud",
			"slug": "sun_behind_cloud",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⛈️",
			"skin_tone_support": false,
			"name": "cloud with lightning and rain",
			"slug": "cloud_with_lightning_and_rain",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌤️",
			"skin_tone_support": false,
			"name": "sun behind small cloud",
			"slug": "sun_behind_small_cloud",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌥️",
			"skin_tone_support": false,
			"name": "sun behind large cloud",
			"slug": "sun_behind_large_cloud",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌦️",
			"skin_tone_support": false,
			"name": "sun behind rain cloud",
			"slug": "sun_behind_rain_cloud",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌧️",
			"skin_tone_support": false,
			"name": "cloud with rain",
			"slug": "cloud_with_rain",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌨️",
			"skin_tone_support": false,
			"name": "cloud with snow",
			"slug": "cloud_with_snow",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌩️",
			"skin_tone_support": false,
			"name": "cloud with lightning",
			"slug": "cloud_with_lightning",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌪️",
			"skin_tone_support": false,
			"name": "tornado",
			"slug": "tornado",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌫️",
			"skin_tone_support": false,
			"name": "fog",
			"slug": "fog",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌬️",
			"skin_tone_support": false,
			"name": "wind face",
			"slug": "wind_face",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🌀",
			"skin_tone_support": false,
			"name": "cyclone",
			"slug": "cyclone",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌈",
			"skin_tone_support": false,
			"name": "rainbow",
			"slug": "rainbow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌂",
			"skin_tone_support": false,
			"name": "closed umbrella",
			"slug": "closed_umbrella",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "☂️",
			"skin_tone_support": false,
			"name": "umbrella",
			"slug": "umbrella",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "☔",
			"skin_tone_support": false,
			"name": "umbrella with rain drops",
			"slug": "umbrella_with_rain_drops",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⛱️",
			"skin_tone_support": false,
			"name": "umbrella on ground",
			"slug": "umbrella_on_ground",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⚡",
			"skin_tone_support": false,
			"name": "high voltage",
			"slug": "high_voltage",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "❄️",
			"skin_tone_support": false,
			"name": "snowflake",
			"slug": "snowflake",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "☃️",
			"skin_tone_support": false,
			"name": "snowman",
			"slug": "snowman",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⛄",
			"skin_tone_support": false,
			"name": "snowman without snow",
			"slug": "snowman_without_snow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "☄️",
			"skin_tone_support": false,
			"name": "comet",
			"slug": "comet",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔥",
			"skin_tone_support": false,
			"name": "fire",
			"slug": "fire",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💧",
			"skin_tone_support": false,
			"name": "droplet",
			"slug": "droplet",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🌊",
			"skin_tone_support": false,
			"name": "water wave",
			"slug": "water_wave",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		}
	],
	"Activities": [
		{
			"emoji": "🎃",
			"skin_tone_support": false,
			"name": "jack-o-lantern",
			"slug": "jack_o_lantern",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎄",
			"skin_tone_support": false,
			"name": "Christmas tree",
			"slug": "christmas_tree",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎆",
			"skin_tone_support": false,
			"name": "fireworks",
			"slug": "fireworks",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎇",
			"skin_tone_support": false,
			"name": "sparkler",
			"slug": "sparkler",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧨",
			"skin_tone_support": false,
			"name": "firecracker",
			"slug": "firecracker",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "✨",
			"skin_tone_support": false,
			"name": "sparkles",
			"slug": "sparkles",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎈",
			"skin_tone_support": false,
			"name": "balloon",
			"slug": "balloon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎉",
			"skin_tone_support": false,
			"name": "party popper",
			"slug": "party_popper",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎊",
			"skin_tone_support": false,
			"name": "confetti ball",
			"slug": "confetti_ball",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎋",
			"skin_tone_support": false,
			"name": "tanabata tree",
			"slug": "tanabata_tree",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎍",
			"skin_tone_support": false,
			"name": "pine decoration",
			"slug": "pine_decoration",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎎",
			"skin_tone_support": false,
			"name": "Japanese dolls",
			"slug": "japanese_dolls",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎏",
			"skin_tone_support": false,
			"name": "carp streamer",
			"slug": "carp_streamer",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎐",
			"skin_tone_support": false,
			"name": "wind chime",
			"slug": "wind_chime",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎑",
			"skin_tone_support": false,
			"name": "moon viewing ceremony",
			"slug": "moon_viewing_ceremony",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧧",
			"skin_tone_support": false,
			"name": "red envelope",
			"slug": "red_envelope",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🎀",
			"skin_tone_support": false,
			"name": "ribbon",
			"slug": "ribbon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎁",
			"skin_tone_support": false,
			"name": "wrapped gift",
			"slug": "wrapped_gift",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎗️",
			"skin_tone_support": false,
			"name": "reminder ribbon",
			"slug": "reminder_ribbon",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🎟️",
			"skin_tone_support": false,
			"name": "admission tickets",
			"slug": "admission_tickets",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🎫",
			"skin_tone_support": false,
			"name": "ticket",
			"slug": "ticket",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎖️",
			"skin_tone_support": false,
			"name": "military medal",
			"slug": "military_medal",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏆",
			"skin_tone_support": false,
			"name": "trophy",
			"slug": "trophy",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏅",
			"skin_tone_support": false,
			"name": "sports medal",
			"slug": "sports_medal",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🥇",
			"skin_tone_support": false,
			"name": "1st place medal",
			"slug": "1st_place_medal",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🥈",
			"skin_tone_support": false,
			"name": "2nd place medal",
			"slug": "2nd_place_medal",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🥉",
			"skin_tone_support": false,
			"name": "3rd place medal",
			"slug": "3rd_place_medal",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "⚽",
			"skin_tone_support": false,
			"name": "soccer ball",
			"slug": "soccer_ball",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⚾",
			"skin_tone_support": false,
			"name": "baseball",
			"slug": "baseball",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥎",
			"skin_tone_support": false,
			"name": "softball",
			"slug": "softball",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🏀",
			"skin_tone_support": false,
			"name": "basketball",
			"slug": "basketball",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏐",
			"skin_tone_support": false,
			"name": "volleyball",
			"slug": "volleyball",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🏈",
			"skin_tone_support": false,
			"name": "american football",
			"slug": "american_football",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏉",
			"skin_tone_support": false,
			"name": "rugby football",
			"slug": "rugby_football",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🎾",
			"skin_tone_support": false,
			"name": "tennis",
			"slug": "tennis",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥏",
			"skin_tone_support": false,
			"name": "flying disc",
			"slug": "flying_disc",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🎳",
			"skin_tone_support": false,
			"name": "bowling",
			"slug": "bowling",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏏",
			"skin_tone_support": false,
			"name": "cricket game",
			"slug": "cricket_game",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🏑",
			"skin_tone_support": false,
			"name": "field hockey",
			"slug": "field_hockey",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🏒",
			"skin_tone_support": false,
			"name": "ice hockey",
			"slug": "ice_hockey",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🥍",
			"skin_tone_support": false,
			"name": "lacrosse",
			"slug": "lacrosse",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🏓",
			"skin_tone_support": false,
			"name": "ping pong",
			"slug": "ping_pong",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🏸",
			"skin_tone_support": false,
			"name": "badminton",
			"slug": "badminton",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🥊",
			"skin_tone_support": false,
			"name": "boxing glove",
			"slug": "boxing_glove",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🥋",
			"skin_tone_support": false,
			"name": "martial arts uniform",
			"slug": "martial_arts_uniform",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🥅",
			"skin_tone_support": false,
			"name": "goal net",
			"slug": "goal_net",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "⛳",
			"skin_tone_support": false,
			"name": "flag in hole",
			"slug": "flag_in_hole",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⛸️",
			"skin_tone_support": false,
			"name": "ice skate",
			"slug": "ice_skate",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🎣",
			"skin_tone_support": false,
			"name": "fishing pole",
			"slug": "fishing_pole",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🤿",
			"skin_tone_support": false,
			"name": "diving mask",
			"slug": "diving_mask",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🎽",
			"skin_tone_support": false,
			"name": "running shirt",
			"slug": "running_shirt",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎿",
			"skin_tone_support": false,
			"name": "skis",
			"slug": "skis",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛷",
			"skin_tone_support": false,
			"name": "sled",
			"slug": "sled",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🥌",
			"skin_tone_support": false,
			"name": "curling stone",
			"slug": "curling_stone",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🎯",
			"skin_tone_support": false,
			"name": "direct hit",
			"slug": "direct_hit",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪀",
			"skin_tone_support": false,
			"name": "yo-yo",
			"slug": "yo_yo",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🪁",
			"skin_tone_support": false,
			"name": "kite",
			"slug": "kite",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🎱",
			"skin_tone_support": false,
			"name": "pool 8 ball",
			"slug": "pool_8_ball",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔮",
			"skin_tone_support": false,
			"name": "crystal ball",
			"slug": "crystal_ball",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪄",
			"skin_tone_support": false,
			"name": "magic wand",
			"slug": "magic_wand",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🧿",
			"skin_tone_support": false,
			"name": "nazar amulet",
			"slug": "nazar_amulet",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🎮",
			"skin_tone_support": false,
			"name": "video game",
			"slug": "video_game",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕹️",
			"skin_tone_support": false,
			"name": "joystick",
			"slug": "joystick",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🎰",
			"skin_tone_support": false,
			"name": "slot machine",
			"slug": "slot_machine",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎲",
			"skin_tone_support": false,
			"name": "game die",
			"slug": "game_die",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧩",
			"skin_tone_support": false,
			"name": "puzzle piece",
			"slug": "puzzle_piece",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧸",
			"skin_tone_support": false,
			"name": "teddy bear",
			"slug": "teddy_bear",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🪅",
			"skin_tone_support": false,
			"name": "piñata",
			"slug": "pinata",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🪆",
			"skin_tone_support": false,
			"name": "nesting dolls",
			"slug": "nesting_dolls",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "♠️",
			"skin_tone_support": false,
			"name": "spade suit",
			"slug": "spade_suit",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♥️",
			"skin_tone_support": false,
			"name": "heart suit",
			"slug": "heart_suit",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♦️",
			"skin_tone_support": false,
			"name": "diamond suit",
			"slug": "diamond_suit",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♣️",
			"skin_tone_support": false,
			"name": "club suit",
			"slug": "club_suit",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♟️",
			"skin_tone_support": false,
			"name": "chess pawn",
			"slug": "chess_pawn",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🃏",
			"skin_tone_support": false,
			"name": "joker",
			"slug": "joker",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🀄",
			"skin_tone_support": false,
			"name": "mahjong red dragon",
			"slug": "mahjong_red_dragon",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎴",
			"skin_tone_support": false,
			"name": "flower playing cards",
			"slug": "flower_playing_cards",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎭",
			"skin_tone_support": false,
			"name": "performing arts",
			"slug": "performing_arts",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🖼️",
			"skin_tone_support": false,
			"name": "framed picture",
			"slug": "framed_picture",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🎨",
			"skin_tone_support": false,
			"name": "artist palette",
			"slug": "artist_palette",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧵",
			"skin_tone_support": false,
			"name": "thread",
			"slug": "thread",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🪡",
			"skin_tone_support": false,
			"name": "sewing needle",
			"slug": "sewing_needle",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🧶",
			"skin_tone_support": false,
			"name": "yarn",
			"slug": "yarn",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🪢",
			"skin_tone_support": false,
			"name": "knot",
			"slug": "knot",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		}
	],
	"Objects": [
		{
			"emoji": "👓",
			"skin_tone_support": false,
			"name": "glasses",
			"slug": "glasses",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕶️",
			"skin_tone_support": false,
			"name": "sunglasses",
			"slug": "sunglasses",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🥽",
			"skin_tone_support": false,
			"name": "goggles",
			"slug": "goggles",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🥼",
			"skin_tone_support": false,
			"name": "lab coat",
			"slug": "lab_coat",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🦺",
			"skin_tone_support": false,
			"name": "safety vest",
			"slug": "safety_vest",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "👔",
			"skin_tone_support": false,
			"name": "necktie",
			"slug": "necktie",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👕",
			"skin_tone_support": false,
			"name": "t-shirt",
			"slug": "t_shirt",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👖",
			"skin_tone_support": false,
			"name": "jeans",
			"slug": "jeans",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧣",
			"skin_tone_support": false,
			"name": "scarf",
			"slug": "scarf",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧤",
			"skin_tone_support": false,
			"name": "gloves",
			"slug": "gloves",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧥",
			"skin_tone_support": false,
			"name": "coat",
			"slug": "coat",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🧦",
			"skin_tone_support": false,
			"name": "socks",
			"slug": "socks",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "👗",
			"skin_tone_support": false,
			"name": "dress",
			"slug": "dress",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👘",
			"skin_tone_support": false,
			"name": "kimono",
			"slug": "kimono",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥻",
			"skin_tone_support": false,
			"name": "sari",
			"slug": "sari",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🩱",
			"skin_tone_support": false,
			"name": "one-piece swimsuit",
			"slug": "one_piece_swimsuit",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🩲",
			"skin_tone_support": false,
			"name": "briefs",
			"slug": "briefs",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🩳",
			"skin_tone_support": false,
			"name": "shorts",
			"slug": "shorts",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "👙",
			"skin_tone_support": false,
			"name": "bikini",
			"slug": "bikini",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👚",
			"skin_tone_support": false,
			"name": "woman’s clothes",
			"slug": "woman_s_clothes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👛",
			"skin_tone_support": false,
			"name": "purse",
			"slug": "purse",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👜",
			"skin_tone_support": false,
			"name": "handbag",
			"slug": "handbag",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👝",
			"skin_tone_support": false,
			"name": "clutch bag",
			"slug": "clutch_bag",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛍️",
			"skin_tone_support": false,
			"name": "shopping bags",
			"slug": "shopping_bags",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🎒",
			"skin_tone_support": false,
			"name": "backpack",
			"slug": "backpack",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🩴",
			"skin_tone_support": false,
			"name": "thong sandal",
			"slug": "thong_sandal",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "👞",
			"skin_tone_support": false,
			"name": "man’s shoe",
			"slug": "man_s_shoe",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👟",
			"skin_tone_support": false,
			"name": "running shoe",
			"slug": "running_shoe",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🥾",
			"skin_tone_support": false,
			"name": "hiking boot",
			"slug": "hiking_boot",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🥿",
			"skin_tone_support": false,
			"name": "flat shoe",
			"slug": "flat_shoe",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "👠",
			"skin_tone_support": false,
			"name": "high-heeled shoe",
			"slug": "high_heeled_shoe",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👡",
			"skin_tone_support": false,
			"name": "woman’s sandal",
			"slug": "woman_s_sandal",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🩰",
			"skin_tone_support": false,
			"name": "ballet shoes",
			"slug": "ballet_shoes",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "👢",
			"skin_tone_support": false,
			"name": "woman’s boot",
			"slug": "woman_s_boot",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👑",
			"skin_tone_support": false,
			"name": "crown",
			"slug": "crown",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "👒",
			"skin_tone_support": false,
			"name": "woman’s hat",
			"slug": "woman_s_hat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎩",
			"skin_tone_support": false,
			"name": "top hat",
			"slug": "top_hat",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎓",
			"skin_tone_support": false,
			"name": "graduation cap",
			"slug": "graduation_cap",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧢",
			"skin_tone_support": false,
			"name": "billed cap",
			"slug": "billed_cap",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🪖",
			"skin_tone_support": false,
			"name": "military helmet",
			"slug": "military_helmet",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "⛑️",
			"skin_tone_support": false,
			"name": "rescue worker’s helmet",
			"slug": "rescue_worker_s_helmet",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "📿",
			"skin_tone_support": false,
			"name": "prayer beads",
			"slug": "prayer_beads",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "💄",
			"skin_tone_support": false,
			"name": "lipstick",
			"slug": "lipstick",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💍",
			"skin_tone_support": false,
			"name": "ring",
			"slug": "ring",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💎",
			"skin_tone_support": false,
			"name": "gem stone",
			"slug": "gem_stone",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔇",
			"skin_tone_support": false,
			"name": "muted speaker",
			"slug": "muted_speaker",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔈",
			"skin_tone_support": false,
			"name": "speaker low volume",
			"slug": "speaker_low_volume",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🔉",
			"skin_tone_support": false,
			"name": "speaker medium volume",
			"slug": "speaker_medium_volume",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔊",
			"skin_tone_support": false,
			"name": "speaker high volume",
			"slug": "speaker_high_volume",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📢",
			"skin_tone_support": false,
			"name": "loudspeaker",
			"slug": "loudspeaker",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📣",
			"skin_tone_support": false,
			"name": "megaphone",
			"slug": "megaphone",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📯",
			"skin_tone_support": false,
			"name": "postal horn",
			"slug": "postal_horn",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔔",
			"skin_tone_support": false,
			"name": "bell",
			"slug": "bell",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔕",
			"skin_tone_support": false,
			"name": "bell with slash",
			"slug": "bell_with_slash",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🎼",
			"skin_tone_support": false,
			"name": "musical score",
			"slug": "musical_score",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎵",
			"skin_tone_support": false,
			"name": "musical note",
			"slug": "musical_note",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎶",
			"skin_tone_support": false,
			"name": "musical notes",
			"slug": "musical_notes",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎙️",
			"skin_tone_support": false,
			"name": "studio microphone",
			"slug": "studio_microphone",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🎚️",
			"skin_tone_support": false,
			"name": "level slider",
			"slug": "level_slider",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🎛️",
			"skin_tone_support": false,
			"name": "control knobs",
			"slug": "control_knobs",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🎤",
			"skin_tone_support": false,
			"name": "microphone",
			"slug": "microphone",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎧",
			"skin_tone_support": false,
			"name": "headphone",
			"slug": "headphone",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📻",
			"skin_tone_support": false,
			"name": "radio",
			"slug": "radio",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎷",
			"skin_tone_support": false,
			"name": "saxophone",
			"slug": "saxophone",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪗",
			"skin_tone_support": false,
			"name": "accordion",
			"slug": "accordion",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🎸",
			"skin_tone_support": false,
			"name": "guitar",
			"slug": "guitar",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎹",
			"skin_tone_support": false,
			"name": "musical keyboard",
			"slug": "musical_keyboard",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎺",
			"skin_tone_support": false,
			"name": "trumpet",
			"slug": "trumpet",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎻",
			"skin_tone_support": false,
			"name": "violin",
			"slug": "violin",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪕",
			"skin_tone_support": false,
			"name": "banjo",
			"slug": "banjo",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🥁",
			"skin_tone_support": false,
			"name": "drum",
			"slug": "drum",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🪘",
			"skin_tone_support": false,
			"name": "long drum",
			"slug": "long_drum",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "📱",
			"skin_tone_support": false,
			"name": "mobile phone",
			"slug": "mobile_phone",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📲",
			"skin_tone_support": false,
			"name": "mobile phone with arrow",
			"slug": "mobile_phone_with_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "☎️",
			"skin_tone_support": false,
			"name": "telephone",
			"slug": "telephone",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📞",
			"skin_tone_support": false,
			"name": "telephone receiver",
			"slug": "telephone_receiver",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📟",
			"skin_tone_support": false,
			"name": "pager",
			"slug": "pager",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📠",
			"skin_tone_support": false,
			"name": "fax machine",
			"slug": "fax_machine",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔋",
			"skin_tone_support": false,
			"name": "battery",
			"slug": "battery",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔌",
			"skin_tone_support": false,
			"name": "electric plug",
			"slug": "electric_plug",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💻",
			"skin_tone_support": false,
			"name": "laptop",
			"slug": "laptop",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🖥️",
			"skin_tone_support": false,
			"name": "desktop computer",
			"slug": "desktop_computer",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🖨️",
			"skin_tone_support": false,
			"name": "printer",
			"slug": "printer",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⌨️",
			"skin_tone_support": false,
			"name": "keyboard",
			"slug": "keyboard",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🖱️",
			"skin_tone_support": false,
			"name": "computer mouse",
			"slug": "computer_mouse",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🖲️",
			"skin_tone_support": false,
			"name": "trackball",
			"slug": "trackball",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "💽",
			"skin_tone_support": false,
			"name": "computer disk",
			"slug": "computer_disk",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💾",
			"skin_tone_support": false,
			"name": "floppy disk",
			"slug": "floppy_disk",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💿",
			"skin_tone_support": false,
			"name": "optical disk",
			"slug": "optical_disk",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📀",
			"skin_tone_support": false,
			"name": "dvd",
			"slug": "dvd",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧮",
			"skin_tone_support": false,
			"name": "abacus",
			"slug": "abacus",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🎥",
			"skin_tone_support": false,
			"name": "movie camera",
			"slug": "movie_camera",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎞️",
			"skin_tone_support": false,
			"name": "film frames",
			"slug": "film_frames",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "📽️",
			"skin_tone_support": false,
			"name": "film projector",
			"slug": "film_projector",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🎬",
			"skin_tone_support": false,
			"name": "clapper board",
			"slug": "clapper_board",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📺",
			"skin_tone_support": false,
			"name": "television",
			"slug": "television",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📷",
			"skin_tone_support": false,
			"name": "camera",
			"slug": "camera",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📸",
			"skin_tone_support": false,
			"name": "camera with flash",
			"slug": "camera_with_flash",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "📹",
			"skin_tone_support": false,
			"name": "video camera",
			"slug": "video_camera",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📼",
			"skin_tone_support": false,
			"name": "videocassette",
			"slug": "videocassette",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔍",
			"skin_tone_support": false,
			"name": "magnifying glass tilted left",
			"slug": "magnifying_glass_tilted_left",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔎",
			"skin_tone_support": false,
			"name": "magnifying glass tilted right",
			"slug": "magnifying_glass_tilted_right",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🕯️",
			"skin_tone_support": false,
			"name": "candle",
			"slug": "candle",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "💡",
			"skin_tone_support": false,
			"name": "light bulb",
			"slug": "light_bulb",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔦",
			"skin_tone_support": false,
			"name": "flashlight",
			"slug": "flashlight",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏮",
			"skin_tone_support": false,
			"name": "red paper lantern",
			"slug": "red_paper_lantern",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪔",
			"skin_tone_support": false,
			"name": "diya lamp",
			"slug": "diya_lamp",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "📔",
			"skin_tone_support": false,
			"name": "notebook with decorative cover",
			"slug": "notebook_with_decorative_cover",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📕",
			"skin_tone_support": false,
			"name": "closed book",
			"slug": "closed_book",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📖",
			"skin_tone_support": false,
			"name": "open book",
			"slug": "open_book",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📗",
			"skin_tone_support": false,
			"name": "green book",
			"slug": "green_book",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📘",
			"skin_tone_support": false,
			"name": "blue book",
			"slug": "blue_book",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📙",
			"skin_tone_support": false,
			"name": "orange book",
			"slug": "orange_book",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📚",
			"skin_tone_support": false,
			"name": "books",
			"slug": "books",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📓",
			"skin_tone_support": false,
			"name": "notebook",
			"slug": "notebook",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📒",
			"skin_tone_support": false,
			"name": "ledger",
			"slug": "ledger",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📃",
			"skin_tone_support": false,
			"name": "page with curl",
			"slug": "page_with_curl",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📜",
			"skin_tone_support": false,
			"name": "scroll",
			"slug": "scroll",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📄",
			"skin_tone_support": false,
			"name": "page facing up",
			"slug": "page_facing_up",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📰",
			"skin_tone_support": false,
			"name": "newspaper",
			"slug": "newspaper",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🗞️",
			"skin_tone_support": false,
			"name": "rolled-up newspaper",
			"slug": "rolled_up_newspaper",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "📑",
			"skin_tone_support": false,
			"name": "bookmark tabs",
			"slug": "bookmark_tabs",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔖",
			"skin_tone_support": false,
			"name": "bookmark",
			"slug": "bookmark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏷️",
			"skin_tone_support": false,
			"name": "label",
			"slug": "label",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "💰",
			"skin_tone_support": false,
			"name": "money bag",
			"slug": "money_bag",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪙",
			"skin_tone_support": false,
			"name": "coin",
			"slug": "coin",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "💴",
			"skin_tone_support": false,
			"name": "yen banknote",
			"slug": "yen_banknote",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💵",
			"skin_tone_support": false,
			"name": "dollar banknote",
			"slug": "dollar_banknote",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💶",
			"skin_tone_support": false,
			"name": "euro banknote",
			"slug": "euro_banknote",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "💷",
			"skin_tone_support": false,
			"name": "pound banknote",
			"slug": "pound_banknote",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "💸",
			"skin_tone_support": false,
			"name": "money with wings",
			"slug": "money_with_wings",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💳",
			"skin_tone_support": false,
			"name": "credit card",
			"slug": "credit_card",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🧾",
			"skin_tone_support": false,
			"name": "receipt",
			"slug": "receipt",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "💹",
			"skin_tone_support": false,
			"name": "chart increasing with yen",
			"slug": "chart_increasing_with_yen",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "✉️",
			"skin_tone_support": false,
			"name": "envelope",
			"slug": "envelope",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📧",
			"skin_tone_support": false,
			"name": "e-mail",
			"slug": "e_mail",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📨",
			"skin_tone_support": false,
			"name": "incoming envelope",
			"slug": "incoming_envelope",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📩",
			"skin_tone_support": false,
			"name": "envelope with arrow",
			"slug": "envelope_with_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📤",
			"skin_tone_support": false,
			"name": "outbox tray",
			"slug": "outbox_tray",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📥",
			"skin_tone_support": false,
			"name": "inbox tray",
			"slug": "inbox_tray",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📦",
			"skin_tone_support": false,
			"name": "package",
			"slug": "package",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📫",
			"skin_tone_support": false,
			"name": "closed mailbox with raised flag",
			"slug": "closed_mailbox_with_raised_flag",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📪",
			"skin_tone_support": false,
			"name": "closed mailbox with lowered flag",
			"slug": "closed_mailbox_with_lowered_flag",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📬",
			"skin_tone_support": false,
			"name": "open mailbox with raised flag",
			"slug": "open_mailbox_with_raised_flag",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "📭",
			"skin_tone_support": false,
			"name": "open mailbox with lowered flag",
			"slug": "open_mailbox_with_lowered_flag",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "📮",
			"skin_tone_support": false,
			"name": "postbox",
			"slug": "postbox",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🗳️",
			"skin_tone_support": false,
			"name": "ballot box with ballot",
			"slug": "ballot_box_with_ballot",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "✏️",
			"skin_tone_support": false,
			"name": "pencil",
			"slug": "pencil",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "✒️",
			"skin_tone_support": false,
			"name": "black nib",
			"slug": "black_nib",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🖋️",
			"skin_tone_support": false,
			"name": "fountain pen",
			"slug": "fountain_pen",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🖊️",
			"skin_tone_support": false,
			"name": "pen",
			"slug": "pen",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🖌️",
			"skin_tone_support": false,
			"name": "paintbrush",
			"slug": "paintbrush",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🖍️",
			"skin_tone_support": false,
			"name": "crayon",
			"slug": "crayon",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "📝",
			"skin_tone_support": false,
			"name": "memo",
			"slug": "memo",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💼",
			"skin_tone_support": false,
			"name": "briefcase",
			"slug": "briefcase",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📁",
			"skin_tone_support": false,
			"name": "file folder",
			"slug": "file_folder",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📂",
			"skin_tone_support": false,
			"name": "open file folder",
			"slug": "open_file_folder",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🗂️",
			"skin_tone_support": false,
			"name": "card index dividers",
			"slug": "card_index_dividers",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "📅",
			"skin_tone_support": false,
			"name": "calendar",
			"slug": "calendar",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📆",
			"skin_tone_support": false,
			"name": "tear-off calendar",
			"slug": "tear_off_calendar",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🗒️",
			"skin_tone_support": false,
			"name": "spiral notepad",
			"slug": "spiral_notepad",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🗓️",
			"skin_tone_support": false,
			"name": "spiral calendar",
			"slug": "spiral_calendar",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "📇",
			"skin_tone_support": false,
			"name": "card index",
			"slug": "card_index",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📈",
			"skin_tone_support": false,
			"name": "chart increasing",
			"slug": "chart_increasing",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📉",
			"skin_tone_support": false,
			"name": "chart decreasing",
			"slug": "chart_decreasing",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📊",
			"skin_tone_support": false,
			"name": "bar chart",
			"slug": "bar_chart",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📋",
			"skin_tone_support": false,
			"name": "clipboard",
			"slug": "clipboard",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📌",
			"skin_tone_support": false,
			"name": "pushpin",
			"slug": "pushpin",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📍",
			"skin_tone_support": false,
			"name": "round pushpin",
			"slug": "round_pushpin",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📎",
			"skin_tone_support": false,
			"name": "paperclip",
			"slug": "paperclip",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🖇️",
			"skin_tone_support": false,
			"name": "linked paperclips",
			"slug": "linked_paperclips",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "📏",
			"skin_tone_support": false,
			"name": "straight ruler",
			"slug": "straight_ruler",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📐",
			"skin_tone_support": false,
			"name": "triangular ruler",
			"slug": "triangular_ruler",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "✂️",
			"skin_tone_support": false,
			"name": "scissors",
			"slug": "scissors",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🗃️",
			"skin_tone_support": false,
			"name": "card file box",
			"slug": "card_file_box",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🗄️",
			"skin_tone_support": false,
			"name": "file cabinet",
			"slug": "file_cabinet",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🗑️",
			"skin_tone_support": false,
			"name": "wastebasket",
			"slug": "wastebasket",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🔒",
			"skin_tone_support": false,
			"name": "locked",
			"slug": "locked",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔓",
			"skin_tone_support": false,
			"name": "unlocked",
			"slug": "unlocked",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔏",
			"skin_tone_support": false,
			"name": "locked with pen",
			"slug": "locked_with_pen",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔐",
			"skin_tone_support": false,
			"name": "locked with key",
			"slug": "locked_with_key",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔑",
			"skin_tone_support": false,
			"name": "key",
			"slug": "key",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🗝️",
			"skin_tone_support": false,
			"name": "old key",
			"slug": "old_key",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🔨",
			"skin_tone_support": false,
			"name": "hammer",
			"slug": "hammer",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪓",
			"skin_tone_support": false,
			"name": "axe",
			"slug": "axe",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "⛏️",
			"skin_tone_support": false,
			"name": "pick",
			"slug": "pick",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⚒️",
			"skin_tone_support": false,
			"name": "hammer and pick",
			"slug": "hammer_and_pick",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🛠️",
			"skin_tone_support": false,
			"name": "hammer and wrench",
			"slug": "hammer_and_wrench",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🗡️",
			"skin_tone_support": false,
			"name": "dagger",
			"slug": "dagger",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⚔️",
			"skin_tone_support": false,
			"name": "crossed swords",
			"slug": "crossed_swords",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔫",
			"skin_tone_support": false,
			"name": "water pistol",
			"slug": "water_pistol",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪃",
			"skin_tone_support": false,
			"name": "boomerang",
			"slug": "boomerang",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🏹",
			"skin_tone_support": false,
			"name": "bow and arrow",
			"slug": "bow_and_arrow",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🛡️",
			"skin_tone_support": false,
			"name": "shield",
			"slug": "shield",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🪚",
			"skin_tone_support": false,
			"name": "carpentry saw",
			"slug": "carpentry_saw",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🔧",
			"skin_tone_support": false,
			"name": "wrench",
			"slug": "wrench",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪛",
			"skin_tone_support": false,
			"name": "screwdriver",
			"slug": "screwdriver",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🔩",
			"skin_tone_support": false,
			"name": "nut and bolt",
			"slug": "nut_and_bolt",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⚙️",
			"skin_tone_support": false,
			"name": "gear",
			"slug": "gear",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🗜️",
			"skin_tone_support": false,
			"name": "clamp",
			"slug": "clamp",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⚖️",
			"skin_tone_support": false,
			"name": "balance scale",
			"slug": "balance_scale",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🦯",
			"skin_tone_support": false,
			"name": "white cane",
			"slug": "white_cane",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🔗",
			"skin_tone_support": false,
			"name": "link",
			"slug": "link",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⛓️",
			"skin_tone_support": false,
			"name": "chains",
			"slug": "chains",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🪝",
			"skin_tone_support": false,
			"name": "hook",
			"slug": "hook",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🧰",
			"skin_tone_support": false,
			"name": "toolbox",
			"slug": "toolbox",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧲",
			"skin_tone_support": false,
			"name": "magnet",
			"slug": "magnet",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🪜",
			"skin_tone_support": false,
			"name": "ladder",
			"slug": "ladder",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "⚗️",
			"skin_tone_support": false,
			"name": "alembic",
			"slug": "alembic",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🧪",
			"skin_tone_support": false,
			"name": "test tube",
			"slug": "test_tube",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧫",
			"skin_tone_support": false,
			"name": "petri dish",
			"slug": "petri_dish",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧬",
			"skin_tone_support": false,
			"name": "dna",
			"slug": "dna",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🔬",
			"skin_tone_support": false,
			"name": "microscope",
			"slug": "microscope",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔭",
			"skin_tone_support": false,
			"name": "telescope",
			"slug": "telescope",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "📡",
			"skin_tone_support": false,
			"name": "satellite antenna",
			"slug": "satellite_antenna",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💉",
			"skin_tone_support": false,
			"name": "syringe",
			"slug": "syringe",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🩸",
			"skin_tone_support": false,
			"name": "drop of blood",
			"slug": "drop_of_blood",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "💊",
			"skin_tone_support": false,
			"name": "pill",
			"slug": "pill",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🩹",
			"skin_tone_support": false,
			"name": "adhesive bandage",
			"slug": "adhesive_bandage",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🩺",
			"skin_tone_support": false,
			"name": "stethoscope",
			"slug": "stethoscope",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🚪",
			"skin_tone_support": false,
			"name": "door",
			"slug": "door",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛗",
			"skin_tone_support": false,
			"name": "elevator",
			"slug": "elevator",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🪞",
			"skin_tone_support": false,
			"name": "mirror",
			"slug": "mirror",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🪟",
			"skin_tone_support": false,
			"name": "window",
			"slug": "window",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🛏️",
			"skin_tone_support": false,
			"name": "bed",
			"slug": "bed",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🛋️",
			"skin_tone_support": false,
			"name": "couch and lamp",
			"slug": "couch_and_lamp",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🪑",
			"skin_tone_support": false,
			"name": "chair",
			"slug": "chair",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🚽",
			"skin_tone_support": false,
			"name": "toilet",
			"slug": "toilet",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪠",
			"skin_tone_support": false,
			"name": "plunger",
			"slug": "plunger",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🚿",
			"skin_tone_support": false,
			"name": "shower",
			"slug": "shower",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🛁",
			"skin_tone_support": false,
			"name": "bathtub",
			"slug": "bathtub",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🪤",
			"skin_tone_support": false,
			"name": "mouse trap",
			"slug": "mouse_trap",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🪒",
			"skin_tone_support": false,
			"name": "razor",
			"slug": "razor",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🧴",
			"skin_tone_support": false,
			"name": "lotion bottle",
			"slug": "lotion_bottle",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧷",
			"skin_tone_support": false,
			"name": "safety pin",
			"slug": "safety_pin",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧹",
			"skin_tone_support": false,
			"name": "broom",
			"slug": "broom",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧺",
			"skin_tone_support": false,
			"name": "basket",
			"slug": "basket",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧻",
			"skin_tone_support": false,
			"name": "roll of paper",
			"slug": "roll_of_paper",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🪣",
			"skin_tone_support": false,
			"name": "bucket",
			"slug": "bucket",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🧼",
			"skin_tone_support": false,
			"name": "soap",
			"slug": "soap",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🪥",
			"skin_tone_support": false,
			"name": "toothbrush",
			"slug": "toothbrush",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🧽",
			"skin_tone_support": false,
			"name": "sponge",
			"slug": "sponge",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🧯",
			"skin_tone_support": false,
			"name": "fire extinguisher",
			"slug": "fire_extinguisher",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🛒",
			"skin_tone_support": false,
			"name": "shopping cart",
			"slug": "shopping_cart",
			"unicode_version": "3.0",
			"emoji_version": "3.0"
		},
		{
			"emoji": "🚬",
			"skin_tone_support": false,
			"name": "cigarette",
			"slug": "cigarette",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⚰️",
			"skin_tone_support": false,
			"name": "coffin",
			"slug": "coffin",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🪦",
			"skin_tone_support": false,
			"name": "headstone",
			"slug": "headstone",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "⚱️",
			"skin_tone_support": false,
			"name": "funeral urn",
			"slug": "funeral_urn",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🗿",
			"skin_tone_support": false,
			"name": "moai",
			"slug": "moai",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🪧",
			"skin_tone_support": false,
			"name": "placard",
			"slug": "placard",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		}
	],
	"Symbols": [
		{
			"emoji": "🏧",
			"skin_tone_support": false,
			"name": "ATM sign",
			"slug": "atm_sign",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚮",
			"skin_tone_support": false,
			"name": "litter in bin sign",
			"slug": "litter_in_bin_sign",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚰",
			"skin_tone_support": false,
			"name": "potable water",
			"slug": "potable_water",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "♿",
			"skin_tone_support": false,
			"name": "wheelchair symbol",
			"slug": "wheelchair_symbol",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚹",
			"skin_tone_support": false,
			"name": "men’s room",
			"slug": "men_s_room",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚺",
			"skin_tone_support": false,
			"name": "women’s room",
			"slug": "women_s_room",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚻",
			"skin_tone_support": false,
			"name": "restroom",
			"slug": "restroom",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚼",
			"skin_tone_support": false,
			"name": "baby symbol",
			"slug": "baby_symbol",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚾",
			"skin_tone_support": false,
			"name": "water closet",
			"slug": "water_closet",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛂",
			"skin_tone_support": false,
			"name": "passport control",
			"slug": "passport_control",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🛃",
			"skin_tone_support": false,
			"name": "customs",
			"slug": "customs",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🛄",
			"skin_tone_support": false,
			"name": "baggage claim",
			"slug": "baggage_claim",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🛅",
			"skin_tone_support": false,
			"name": "left luggage",
			"slug": "left_luggage",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "⚠️",
			"skin_tone_support": false,
			"name": "warning",
			"slug": "warning",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚸",
			"skin_tone_support": false,
			"name": "children crossing",
			"slug": "children_crossing",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "⛔",
			"skin_tone_support": false,
			"name": "no entry",
			"slug": "no_entry",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚫",
			"skin_tone_support": false,
			"name": "prohibited",
			"slug": "prohibited",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚳",
			"skin_tone_support": false,
			"name": "no bicycles",
			"slug": "no_bicycles",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚭",
			"skin_tone_support": false,
			"name": "no smoking",
			"slug": "no_smoking",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚯",
			"skin_tone_support": false,
			"name": "no littering",
			"slug": "no_littering",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚱",
			"skin_tone_support": false,
			"name": "non-potable water",
			"slug": "non_potable_water",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🚷",
			"skin_tone_support": false,
			"name": "no pedestrians",
			"slug": "no_pedestrians",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "📵",
			"skin_tone_support": false,
			"name": "no mobile phones",
			"slug": "no_mobile_phones",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔞",
			"skin_tone_support": false,
			"name": "no one under eighteen",
			"slug": "no_one_under_eighteen",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "☢️",
			"skin_tone_support": false,
			"name": "radioactive",
			"slug": "radioactive",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "☣️",
			"skin_tone_support": false,
			"name": "biohazard",
			"slug": "biohazard",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "⬆️",
			"skin_tone_support": false,
			"name": "up arrow",
			"slug": "up_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "↗️",
			"skin_tone_support": false,
			"name": "up-right arrow",
			"slug": "up_right_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "➡️",
			"skin_tone_support": false,
			"name": "right arrow",
			"slug": "right_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "↘️",
			"skin_tone_support": false,
			"name": "down-right arrow",
			"slug": "down_right_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⬇️",
			"skin_tone_support": false,
			"name": "down arrow",
			"slug": "down_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "↙️",
			"skin_tone_support": false,
			"name": "down-left arrow",
			"slug": "down_left_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⬅️",
			"skin_tone_support": false,
			"name": "left arrow",
			"slug": "left_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "↖️",
			"skin_tone_support": false,
			"name": "up-left arrow",
			"slug": "up_left_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "↕️",
			"skin_tone_support": false,
			"name": "up-down arrow",
			"slug": "up_down_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "↔️",
			"skin_tone_support": false,
			"name": "left-right arrow",
			"slug": "left_right_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "↩️",
			"skin_tone_support": false,
			"name": "right arrow curving left",
			"slug": "right_arrow_curving_left",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "↪️",
			"skin_tone_support": false,
			"name": "left arrow curving right",
			"slug": "left_arrow_curving_right",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⤴️",
			"skin_tone_support": false,
			"name": "right arrow curving up",
			"slug": "right_arrow_curving_up",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⤵️",
			"skin_tone_support": false,
			"name": "right arrow curving down",
			"slug": "right_arrow_curving_down",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔃",
			"skin_tone_support": false,
			"name": "clockwise vertical arrows",
			"slug": "clockwise_vertical_arrows",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔄",
			"skin_tone_support": false,
			"name": "counterclockwise arrows button",
			"slug": "counterclockwise_arrows_button",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔙",
			"skin_tone_support": false,
			"name": "BACK arrow",
			"slug": "back_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔚",
			"skin_tone_support": false,
			"name": "END arrow",
			"slug": "end_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔛",
			"skin_tone_support": false,
			"name": "ON! arrow",
			"slug": "on_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔜",
			"skin_tone_support": false,
			"name": "SOON arrow",
			"slug": "soon_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔝",
			"skin_tone_support": false,
			"name": "TOP arrow",
			"slug": "top_arrow",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🛐",
			"skin_tone_support": false,
			"name": "place of worship",
			"slug": "place_of_worship",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "⚛️",
			"skin_tone_support": false,
			"name": "atom symbol",
			"slug": "atom_symbol",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🕉️",
			"skin_tone_support": false,
			"name": "om",
			"slug": "om",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "✡️",
			"skin_tone_support": false,
			"name": "star of David",
			"slug": "star_of_david",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "☸️",
			"skin_tone_support": false,
			"name": "wheel of dharma",
			"slug": "wheel_of_dharma",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "☯️",
			"skin_tone_support": false,
			"name": "yin yang",
			"slug": "yin_yang",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "✝️",
			"skin_tone_support": false,
			"name": "latin cross",
			"slug": "latin_cross",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "☦️",
			"skin_tone_support": false,
			"name": "orthodox cross",
			"slug": "orthodox_cross",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "☪️",
			"skin_tone_support": false,
			"name": "star and crescent",
			"slug": "star_and_crescent",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "☮️",
			"skin_tone_support": false,
			"name": "peace symbol",
			"slug": "peace_symbol",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🕎",
			"skin_tone_support": false,
			"name": "menorah",
			"slug": "menorah",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔯",
			"skin_tone_support": false,
			"name": "dotted six-pointed star",
			"slug": "dotted_six_pointed_star",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♈",
			"skin_tone_support": false,
			"name": "Aries",
			"slug": "aries",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♉",
			"skin_tone_support": false,
			"name": "Taurus",
			"slug": "taurus",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♊",
			"skin_tone_support": false,
			"name": "Gemini",
			"slug": "gemini",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♋",
			"skin_tone_support": false,
			"name": "Cancer",
			"slug": "cancer",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♌",
			"skin_tone_support": false,
			"name": "Leo",
			"slug": "leo",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♍",
			"skin_tone_support": false,
			"name": "Virgo",
			"slug": "virgo",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♎",
			"skin_tone_support": false,
			"name": "Libra",
			"slug": "libra",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♏",
			"skin_tone_support": false,
			"name": "Scorpio",
			"slug": "scorpio",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♐",
			"skin_tone_support": false,
			"name": "Sagittarius",
			"slug": "sagittarius",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♑",
			"skin_tone_support": false,
			"name": "Capricorn",
			"slug": "capricorn",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♒",
			"skin_tone_support": false,
			"name": "Aquarius",
			"slug": "aquarius",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♓",
			"skin_tone_support": false,
			"name": "Pisces",
			"slug": "pisces",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⛎",
			"skin_tone_support": false,
			"name": "Ophiuchus",
			"slug": "ophiuchus",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔀",
			"skin_tone_support": false,
			"name": "shuffle tracks button",
			"slug": "shuffle_tracks_button",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔁",
			"skin_tone_support": false,
			"name": "repeat button",
			"slug": "repeat_button",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔂",
			"skin_tone_support": false,
			"name": "repeat single button",
			"slug": "repeat_single_button",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "▶️",
			"skin_tone_support": false,
			"name": "play button",
			"slug": "play_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⏩",
			"skin_tone_support": false,
			"name": "fast-forward button",
			"slug": "fast_forward_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⏭️",
			"skin_tone_support": false,
			"name": "next track button",
			"slug": "next_track_button",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⏯️",
			"skin_tone_support": false,
			"name": "play or pause button",
			"slug": "play_or_pause_button",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "◀️",
			"skin_tone_support": false,
			"name": "reverse button",
			"slug": "reverse_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⏪",
			"skin_tone_support": false,
			"name": "fast reverse button",
			"slug": "fast_reverse_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⏮️",
			"skin_tone_support": false,
			"name": "last track button",
			"slug": "last_track_button",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🔼",
			"skin_tone_support": false,
			"name": "upwards button",
			"slug": "upwards_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⏫",
			"skin_tone_support": false,
			"name": "fast up button",
			"slug": "fast_up_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔽",
			"skin_tone_support": false,
			"name": "downwards button",
			"slug": "downwards_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⏬",
			"skin_tone_support": false,
			"name": "fast down button",
			"slug": "fast_down_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⏸️",
			"skin_tone_support": false,
			"name": "pause button",
			"slug": "pause_button",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⏹️",
			"skin_tone_support": false,
			"name": "stop button",
			"slug": "stop_button",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⏺️",
			"skin_tone_support": false,
			"name": "record button",
			"slug": "record_button",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "⏏️",
			"skin_tone_support": false,
			"name": "eject button",
			"slug": "eject_button",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🎦",
			"skin_tone_support": false,
			"name": "cinema",
			"slug": "cinema",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔅",
			"skin_tone_support": false,
			"name": "dim button",
			"slug": "dim_button",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔆",
			"skin_tone_support": false,
			"name": "bright button",
			"slug": "bright_button",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "📶",
			"skin_tone_support": false,
			"name": "antenna bars",
			"slug": "antenna_bars",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📳",
			"skin_tone_support": false,
			"name": "vibration mode",
			"slug": "vibration_mode",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📴",
			"skin_tone_support": false,
			"name": "mobile phone off",
			"slug": "mobile_phone_off",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♀️",
			"skin_tone_support": false,
			"name": "female sign",
			"slug": "female_sign",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "♂️",
			"skin_tone_support": false,
			"name": "male sign",
			"slug": "male_sign",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "⚧️",
			"skin_tone_support": false,
			"name": "transgender symbol",
			"slug": "transgender_symbol",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "✖️",
			"skin_tone_support": false,
			"name": "multiply",
			"slug": "multiply",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "➕",
			"skin_tone_support": false,
			"name": "plus",
			"slug": "plus",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "➖",
			"skin_tone_support": false,
			"name": "minus",
			"slug": "minus",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "➗",
			"skin_tone_support": false,
			"name": "divide",
			"slug": "divide",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "♾️",
			"skin_tone_support": false,
			"name": "infinity",
			"slug": "infinity",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "‼️",
			"skin_tone_support": false,
			"name": "double exclamation mark",
			"slug": "double_exclamation_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⁉️",
			"skin_tone_support": false,
			"name": "exclamation question mark",
			"slug": "exclamation_question_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "❓",
			"skin_tone_support": false,
			"name": "red question mark",
			"slug": "red_question_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "❔",
			"skin_tone_support": false,
			"name": "white question mark",
			"slug": "white_question_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "❕",
			"skin_tone_support": false,
			"name": "white exclamation mark",
			"slug": "white_exclamation_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "❗",
			"skin_tone_support": false,
			"name": "red exclamation mark",
			"slug": "red_exclamation_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "〰️",
			"skin_tone_support": false,
			"name": "wavy dash",
			"slug": "wavy_dash",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💱",
			"skin_tone_support": false,
			"name": "currency exchange",
			"slug": "currency_exchange",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💲",
			"skin_tone_support": false,
			"name": "heavy dollar sign",
			"slug": "heavy_dollar_sign",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⚕️",
			"skin_tone_support": false,
			"name": "medical symbol",
			"slug": "medical_symbol",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "♻️",
			"skin_tone_support": false,
			"name": "recycling symbol",
			"slug": "recycling_symbol",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⚜️",
			"skin_tone_support": false,
			"name": "fleur-de-lis",
			"slug": "fleur_de_lis",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🔱",
			"skin_tone_support": false,
			"name": "trident emblem",
			"slug": "trident_emblem",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "📛",
			"skin_tone_support": false,
			"name": "name badge",
			"slug": "name_badge",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔰",
			"skin_tone_support": false,
			"name": "Japanese symbol for beginner",
			"slug": "japanese_symbol_for_beginner",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⭕",
			"skin_tone_support": false,
			"name": "hollow red circle",
			"slug": "hollow_red_circle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "✅",
			"skin_tone_support": false,
			"name": "check mark button",
			"slug": "check_mark_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "☑️",
			"skin_tone_support": false,
			"name": "check box with check",
			"slug": "check_box_with_check",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "✔️",
			"skin_tone_support": false,
			"name": "check mark",
			"slug": "check_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "❌",
			"skin_tone_support": false,
			"name": "cross mark",
			"slug": "cross_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "❎",
			"skin_tone_support": false,
			"name": "cross mark button",
			"slug": "cross_mark_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "➰",
			"skin_tone_support": false,
			"name": "curly loop",
			"slug": "curly_loop",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "➿",
			"skin_tone_support": false,
			"name": "double curly loop",
			"slug": "double_curly_loop",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "〽️",
			"skin_tone_support": false,
			"name": "part alternation mark",
			"slug": "part_alternation_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "✳️",
			"skin_tone_support": false,
			"name": "eight-spoked asterisk",
			"slug": "eight_spoked_asterisk",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "✴️",
			"skin_tone_support": false,
			"name": "eight-pointed star",
			"slug": "eight_pointed_star",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "❇️",
			"skin_tone_support": false,
			"name": "sparkle",
			"slug": "sparkle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "©️",
			"skin_tone_support": false,
			"name": "copyright",
			"slug": "copyright",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "®️",
			"skin_tone_support": false,
			"name": "registered",
			"slug": "registered",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "™️",
			"skin_tone_support": false,
			"name": "trade mark",
			"slug": "trade_mark",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "#️⃣",
			"skin_tone_support": false,
			"name": "keycap #",
			"slug": "keycap_",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "*️⃣",
			"skin_tone_support": false,
			"name": "keycap *",
			"slug": "keycap_",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "0️⃣",
			"skin_tone_support": false,
			"name": "keycap 0",
			"slug": "keycap_0",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "1️⃣",
			"skin_tone_support": false,
			"name": "keycap 1",
			"slug": "keycap_1",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "2️⃣",
			"skin_tone_support": false,
			"name": "keycap 2",
			"slug": "keycap_2",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "3️⃣",
			"skin_tone_support": false,
			"name": "keycap 3",
			"slug": "keycap_3",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "4️⃣",
			"skin_tone_support": false,
			"name": "keycap 4",
			"slug": "keycap_4",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "5️⃣",
			"skin_tone_support": false,
			"name": "keycap 5",
			"slug": "keycap_5",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "6️⃣",
			"skin_tone_support": false,
			"name": "keycap 6",
			"slug": "keycap_6",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "7️⃣",
			"skin_tone_support": false,
			"name": "keycap 7",
			"slug": "keycap_7",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "8️⃣",
			"skin_tone_support": false,
			"name": "keycap 8",
			"slug": "keycap_8",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "9️⃣",
			"skin_tone_support": false,
			"name": "keycap 9",
			"slug": "keycap_9",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔟",
			"skin_tone_support": false,
			"name": "keycap 10",
			"slug": "keycap_10",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔠",
			"skin_tone_support": false,
			"name": "input latin uppercase",
			"slug": "input_latin_uppercase",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔡",
			"skin_tone_support": false,
			"name": "input latin lowercase",
			"slug": "input_latin_lowercase",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔢",
			"skin_tone_support": false,
			"name": "input numbers",
			"slug": "input_numbers",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔣",
			"skin_tone_support": false,
			"name": "input symbols",
			"slug": "input_symbols",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔤",
			"skin_tone_support": false,
			"name": "input latin letters",
			"slug": "input_latin_letters",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🅰️",
			"skin_tone_support": false,
			"name": "A button (blood type)",
			"slug": "a_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🆎",
			"skin_tone_support": false,
			"name": "AB button (blood type)",
			"slug": "ab_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🅱️",
			"skin_tone_support": false,
			"name": "B button (blood type)",
			"slug": "b_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🆑",
			"skin_tone_support": false,
			"name": "CL button",
			"slug": "cl_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🆒",
			"skin_tone_support": false,
			"name": "COOL button",
			"slug": "cool_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🆓",
			"skin_tone_support": false,
			"name": "FREE button",
			"slug": "free_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "ℹ️",
			"skin_tone_support": false,
			"name": "information",
			"slug": "information",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🆔",
			"skin_tone_support": false,
			"name": "ID button",
			"slug": "id_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "Ⓜ️",
			"skin_tone_support": false,
			"name": "circled M",
			"slug": "circled_m",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🆕",
			"skin_tone_support": false,
			"name": "NEW button",
			"slug": "new_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🆖",
			"skin_tone_support": false,
			"name": "NG button",
			"slug": "ng_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🅾️",
			"skin_tone_support": false,
			"name": "O button (blood type)",
			"slug": "o_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🆗",
			"skin_tone_support": false,
			"name": "OK button",
			"slug": "ok_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🅿️",
			"skin_tone_support": false,
			"name": "P button",
			"slug": "p_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🆘",
			"skin_tone_support": false,
			"name": "SOS button",
			"slug": "sos_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🆙",
			"skin_tone_support": false,
			"name": "UP! button",
			"slug": "up_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🆚",
			"skin_tone_support": false,
			"name": "VS button",
			"slug": "vs_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈁",
			"skin_tone_support": false,
			"name": "Japanese “here” button",
			"slug": "japanese_here_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈂️",
			"skin_tone_support": false,
			"name": "Japanese “service charge” button",
			"slug": "japanese_service_charge_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈷️",
			"skin_tone_support": false,
			"name": "Japanese “monthly amount” button",
			"slug": "japanese_monthly_amount_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈶",
			"skin_tone_support": false,
			"name": "Japanese “not free of charge” button",
			"slug": "japanese_not_free_of_charge_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈯",
			"skin_tone_support": false,
			"name": "Japanese “reserved” button",
			"slug": "japanese_reserved_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🉐",
			"skin_tone_support": false,
			"name": "Japanese “bargain” button",
			"slug": "japanese_bargain_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈹",
			"skin_tone_support": false,
			"name": "Japanese “discount” button",
			"slug": "japanese_discount_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈚",
			"skin_tone_support": false,
			"name": "Japanese “free of charge” button",
			"slug": "japanese_free_of_charge_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈲",
			"skin_tone_support": false,
			"name": "Japanese “prohibited” button",
			"slug": "japanese_prohibited_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🉑",
			"skin_tone_support": false,
			"name": "Japanese “acceptable” button",
			"slug": "japanese_acceptable_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈸",
			"skin_tone_support": false,
			"name": "Japanese “application” button",
			"slug": "japanese_application_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈴",
			"skin_tone_support": false,
			"name": "Japanese “passing grade” button",
			"slug": "japanese_passing_grade_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈳",
			"skin_tone_support": false,
			"name": "Japanese “vacancy” button",
			"slug": "japanese_vacancy_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "㊗️",
			"skin_tone_support": false,
			"name": "Japanese “congratulations” button",
			"slug": "japanese_congratulations_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "㊙️",
			"skin_tone_support": false,
			"name": "Japanese “secret” button",
			"slug": "japanese_secret_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈺",
			"skin_tone_support": false,
			"name": "Japanese “open for business” button",
			"slug": "japanese_open_for_business_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🈵",
			"skin_tone_support": false,
			"name": "Japanese “no vacancy” button",
			"slug": "japanese_no_vacancy_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔴",
			"skin_tone_support": false,
			"name": "red circle",
			"slug": "red_circle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🟠",
			"skin_tone_support": false,
			"name": "orange circle",
			"slug": "orange_circle",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🟡",
			"skin_tone_support": false,
			"name": "yellow circle",
			"slug": "yellow_circle",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🟢",
			"skin_tone_support": false,
			"name": "green circle",
			"slug": "green_circle",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🔵",
			"skin_tone_support": false,
			"name": "blue circle",
			"slug": "blue_circle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🟣",
			"skin_tone_support": false,
			"name": "purple circle",
			"slug": "purple_circle",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🟤",
			"skin_tone_support": false,
			"name": "brown circle",
			"slug": "brown_circle",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "⚫",
			"skin_tone_support": false,
			"name": "black circle",
			"slug": "black_circle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⚪",
			"skin_tone_support": false,
			"name": "white circle",
			"slug": "white_circle",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🟥",
			"skin_tone_support": false,
			"name": "red square",
			"slug": "red_square",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🟧",
			"skin_tone_support": false,
			"name": "orange square",
			"slug": "orange_square",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🟨",
			"skin_tone_support": false,
			"name": "yellow square",
			"slug": "yellow_square",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🟩",
			"skin_tone_support": false,
			"name": "green square",
			"slug": "green_square",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🟦",
			"skin_tone_support": false,
			"name": "blue square",
			"slug": "blue_square",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🟪",
			"skin_tone_support": false,
			"name": "purple square",
			"slug": "purple_square",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "🟫",
			"skin_tone_support": false,
			"name": "brown square",
			"slug": "brown_square",
			"unicode_version": "12.0",
			"emoji_version": "12.0"
		},
		{
			"emoji": "⬛",
			"skin_tone_support": false,
			"name": "black large square",
			"slug": "black_large_square",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "⬜",
			"skin_tone_support": false,
			"name": "white large square",
			"slug": "white_large_square",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "◼️",
			"skin_tone_support": false,
			"name": "black medium square",
			"slug": "black_medium_square",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "◻️",
			"skin_tone_support": false,
			"name": "white medium square",
			"slug": "white_medium_square",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "◾",
			"skin_tone_support": false,
			"name": "black medium-small square",
			"slug": "black_medium_small_square",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "◽",
			"skin_tone_support": false,
			"name": "white medium-small square",
			"slug": "white_medium_small_square",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "▪️",
			"skin_tone_support": false,
			"name": "black small square",
			"slug": "black_small_square",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "▫️",
			"skin_tone_support": false,
			"name": "white small square",
			"slug": "white_small_square",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔶",
			"skin_tone_support": false,
			"name": "large orange diamond",
			"slug": "large_orange_diamond",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔷",
			"skin_tone_support": false,
			"name": "large blue diamond",
			"slug": "large_blue_diamond",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔸",
			"skin_tone_support": false,
			"name": "small orange diamond",
			"slug": "small_orange_diamond",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔹",
			"skin_tone_support": false,
			"name": "small blue diamond",
			"slug": "small_blue_diamond",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔺",
			"skin_tone_support": false,
			"name": "red triangle pointed up",
			"slug": "red_triangle_pointed_up",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔻",
			"skin_tone_support": false,
			"name": "red triangle pointed down",
			"slug": "red_triangle_pointed_down",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "💠",
			"skin_tone_support": false,
			"name": "diamond with a dot",
			"slug": "diamond_with_a_dot",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔘",
			"skin_tone_support": false,
			"name": "radio button",
			"slug": "radio_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔳",
			"skin_tone_support": false,
			"name": "white square button",
			"slug": "white_square_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🔲",
			"skin_tone_support": false,
			"name": "black square button",
			"slug": "black_square_button",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		}
	],
	"Flags": [
		{
			"emoji": "🏁",
			"skin_tone_support": false,
			"name": "chequered flag",
			"slug": "chequered_flag",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🚩",
			"skin_tone_support": false,
			"name": "triangular flag",
			"slug": "triangular_flag",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🎌",
			"skin_tone_support": false,
			"name": "crossed flags",
			"slug": "crossed_flags",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🏴",
			"skin_tone_support": false,
			"name": "black flag",
			"slug": "black_flag",
			"unicode_version": "1.0",
			"emoji_version": "1.0"
		},
		{
			"emoji": "🏳️",
			"skin_tone_support": false,
			"name": "white flag",
			"slug": "white_flag",
			"unicode_version": "0.7",
			"emoji_version": "0.7"
		},
		{
			"emoji": "🏳️‍🌈",
			"skin_tone_support": false,
			"name": "rainbow flag",
			"slug": "rainbow_flag",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🏳️‍⚧️",
			"skin_tone_support": false,
			"name": "transgender flag",
			"slug": "transgender_flag",
			"unicode_version": "13.0",
			"emoji_version": "13.0"
		},
		{
			"emoji": "🏴‍☠️",
			"skin_tone_support": false,
			"name": "pirate flag",
			"slug": "pirate_flag",
			"unicode_version": "11.0",
			"emoji_version": "11.0"
		},
		{
			"emoji": "🇦🇨",
			"skin_tone_support": false,
			"name": "flag Ascension Island",
			"slug": "flag_ascension_island",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇩",
			"skin_tone_support": false,
			"name": "flag Andorra",
			"slug": "flag_andorra",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇪",
			"skin_tone_support": false,
			"name": "flag United Arab Emirates",
			"slug": "flag_united_arab_emirates",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇫",
			"skin_tone_support": false,
			"name": "flag Afghanistan",
			"slug": "flag_afghanistan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇬",
			"skin_tone_support": false,
			"name": "flag Antigua & Barbuda",
			"slug": "flag_antigua_barbuda",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇮",
			"skin_tone_support": false,
			"name": "flag Anguilla",
			"slug": "flag_anguilla",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇱",
			"skin_tone_support": false,
			"name": "flag Albania",
			"slug": "flag_albania",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇲",
			"skin_tone_support": false,
			"name": "flag Armenia",
			"slug": "flag_armenia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇴",
			"skin_tone_support": false,
			"name": "flag Angola",
			"slug": "flag_angola",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇶",
			"skin_tone_support": false,
			"name": "flag Antarctica",
			"slug": "flag_antarctica",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇷",
			"skin_tone_support": false,
			"name": "flag Argentina",
			"slug": "flag_argentina",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇸",
			"skin_tone_support": false,
			"name": "flag American Samoa",
			"slug": "flag_american_samoa",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇹",
			"skin_tone_support": false,
			"name": "flag Austria",
			"slug": "flag_austria",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇺",
			"skin_tone_support": false,
			"name": "flag Australia",
			"slug": "flag_australia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇼",
			"skin_tone_support": false,
			"name": "flag Aruba",
			"slug": "flag_aruba",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇽",
			"skin_tone_support": false,
			"name": "flag Åland Islands",
			"slug": "flag_aland_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇦🇿",
			"skin_tone_support": false,
			"name": "flag Azerbaijan",
			"slug": "flag_azerbaijan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇦",
			"skin_tone_support": false,
			"name": "flag Bosnia & Herzegovina",
			"slug": "flag_bosnia_herzegovina",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇧",
			"skin_tone_support": false,
			"name": "flag Barbados",
			"slug": "flag_barbados",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇩",
			"skin_tone_support": false,
			"name": "flag Bangladesh",
			"slug": "flag_bangladesh",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇪",
			"skin_tone_support": false,
			"name": "flag Belgium",
			"slug": "flag_belgium",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇫",
			"skin_tone_support": false,
			"name": "flag Burkina Faso",
			"slug": "flag_burkina_faso",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇬",
			"skin_tone_support": false,
			"name": "flag Bulgaria",
			"slug": "flag_bulgaria",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇭",
			"skin_tone_support": false,
			"name": "flag Bahrain",
			"slug": "flag_bahrain",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇮",
			"skin_tone_support": false,
			"name": "flag Burundi",
			"slug": "flag_burundi",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇯",
			"skin_tone_support": false,
			"name": "flag Benin",
			"slug": "flag_benin",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇱",
			"skin_tone_support": false,
			"name": "flag St. Barthélemy",
			"slug": "flag_st_barthelemy",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇲",
			"skin_tone_support": false,
			"name": "flag Bermuda",
			"slug": "flag_bermuda",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇳",
			"skin_tone_support": false,
			"name": "flag Brunei",
			"slug": "flag_brunei",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇴",
			"skin_tone_support": false,
			"name": "flag Bolivia",
			"slug": "flag_bolivia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇶",
			"skin_tone_support": false,
			"name": "flag Caribbean Netherlands",
			"slug": "flag_caribbean_netherlands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇷",
			"skin_tone_support": false,
			"name": "flag Brazil",
			"slug": "flag_brazil",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇸",
			"skin_tone_support": false,
			"name": "flag Bahamas",
			"slug": "flag_bahamas",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇹",
			"skin_tone_support": false,
			"name": "flag Bhutan",
			"slug": "flag_bhutan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇻",
			"skin_tone_support": false,
			"name": "flag Bouvet Island",
			"slug": "flag_bouvet_island",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇼",
			"skin_tone_support": false,
			"name": "flag Botswana",
			"slug": "flag_botswana",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇾",
			"skin_tone_support": false,
			"name": "flag Belarus",
			"slug": "flag_belarus",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇧🇿",
			"skin_tone_support": false,
			"name": "flag Belize",
			"slug": "flag_belize",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇦",
			"skin_tone_support": false,
			"name": "flag Canada",
			"slug": "flag_canada",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇨",
			"skin_tone_support": false,
			"name": "flag Cocos (Keeling) Islands",
			"slug": "flag_cocos_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇩",
			"skin_tone_support": false,
			"name": "flag Congo - Kinshasa",
			"slug": "flag_congo_kinshasa",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇫",
			"skin_tone_support": false,
			"name": "flag Central African Republic",
			"slug": "flag_central_african_republic",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇬",
			"skin_tone_support": false,
			"name": "flag Congo - Brazzaville",
			"slug": "flag_congo_brazzaville",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇭",
			"skin_tone_support": false,
			"name": "flag Switzerland",
			"slug": "flag_switzerland",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇮",
			"skin_tone_support": false,
			"name": "flag Côte d’Ivoire",
			"slug": "flag_cote_d_ivoire",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇰",
			"skin_tone_support": false,
			"name": "flag Cook Islands",
			"slug": "flag_cook_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇱",
			"skin_tone_support": false,
			"name": "flag Chile",
			"slug": "flag_chile",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇲",
			"skin_tone_support": false,
			"name": "flag Cameroon",
			"slug": "flag_cameroon",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇳",
			"skin_tone_support": false,
			"name": "flag China",
			"slug": "flag_china",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🇨🇴",
			"skin_tone_support": false,
			"name": "flag Colombia",
			"slug": "flag_colombia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇵",
			"skin_tone_support": false,
			"name": "flag Clipperton Island",
			"slug": "flag_clipperton_island",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇷",
			"skin_tone_support": false,
			"name": "flag Costa Rica",
			"slug": "flag_costa_rica",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇺",
			"skin_tone_support": false,
			"name": "flag Cuba",
			"slug": "flag_cuba",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇻",
			"skin_tone_support": false,
			"name": "flag Cape Verde",
			"slug": "flag_cape_verde",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇼",
			"skin_tone_support": false,
			"name": "flag Curaçao",
			"slug": "flag_curacao",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇽",
			"skin_tone_support": false,
			"name": "flag Christmas Island",
			"slug": "flag_christmas_island",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇾",
			"skin_tone_support": false,
			"name": "flag Cyprus",
			"slug": "flag_cyprus",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇨🇿",
			"skin_tone_support": false,
			"name": "flag Czechia",
			"slug": "flag_czechia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇩🇪",
			"skin_tone_support": false,
			"name": "flag Germany",
			"slug": "flag_germany",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🇩🇬",
			"skin_tone_support": false,
			"name": "flag Diego Garcia",
			"slug": "flag_diego_garcia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇩🇯",
			"skin_tone_support": false,
			"name": "flag Djibouti",
			"slug": "flag_djibouti",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇩🇰",
			"skin_tone_support": false,
			"name": "flag Denmark",
			"slug": "flag_denmark",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇩🇲",
			"skin_tone_support": false,
			"name": "flag Dominica",
			"slug": "flag_dominica",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇩🇴",
			"skin_tone_support": false,
			"name": "flag Dominican Republic",
			"slug": "flag_dominican_republic",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇩🇿",
			"skin_tone_support": false,
			"name": "flag Algeria",
			"slug": "flag_algeria",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇪🇦",
			"skin_tone_support": false,
			"name": "flag Ceuta & Melilla",
			"slug": "flag_ceuta_melilla",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇪🇨",
			"skin_tone_support": false,
			"name": "flag Ecuador",
			"slug": "flag_ecuador",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇪🇪",
			"skin_tone_support": false,
			"name": "flag Estonia",
			"slug": "flag_estonia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇪🇬",
			"skin_tone_support": false,
			"name": "flag Egypt",
			"slug": "flag_egypt",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇪🇭",
			"skin_tone_support": false,
			"name": "flag Western Sahara",
			"slug": "flag_western_sahara",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇪🇷",
			"skin_tone_support": false,
			"name": "flag Eritrea",
			"slug": "flag_eritrea",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇪🇸",
			"skin_tone_support": false,
			"name": "flag Spain",
			"slug": "flag_spain",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🇪🇹",
			"skin_tone_support": false,
			"name": "flag Ethiopia",
			"slug": "flag_ethiopia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇪🇺",
			"skin_tone_support": false,
			"name": "flag European Union",
			"slug": "flag_european_union",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇫🇮",
			"skin_tone_support": false,
			"name": "flag Finland",
			"slug": "flag_finland",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇫🇯",
			"skin_tone_support": false,
			"name": "flag Fiji",
			"slug": "flag_fiji",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇫🇰",
			"skin_tone_support": false,
			"name": "flag Falkland Islands",
			"slug": "flag_falkland_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇫🇲",
			"skin_tone_support": false,
			"name": "flag Micronesia",
			"slug": "flag_micronesia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇫🇴",
			"skin_tone_support": false,
			"name": "flag Faroe Islands",
			"slug": "flag_faroe_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇫🇷",
			"skin_tone_support": false,
			"name": "flag France",
			"slug": "flag_france",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🇬🇦",
			"skin_tone_support": false,
			"name": "flag Gabon",
			"slug": "flag_gabon",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇧",
			"skin_tone_support": false,
			"name": "flag United Kingdom",
			"slug": "flag_united_kingdom",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🇬🇩",
			"skin_tone_support": false,
			"name": "flag Grenada",
			"slug": "flag_grenada",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇪",
			"skin_tone_support": false,
			"name": "flag Georgia",
			"slug": "flag_georgia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇫",
			"skin_tone_support": false,
			"name": "flag French Guiana",
			"slug": "flag_french_guiana",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇬",
			"skin_tone_support": false,
			"name": "flag Guernsey",
			"slug": "flag_guernsey",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇭",
			"skin_tone_support": false,
			"name": "flag Ghana",
			"slug": "flag_ghana",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇮",
			"skin_tone_support": false,
			"name": "flag Gibraltar",
			"slug": "flag_gibraltar",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇱",
			"skin_tone_support": false,
			"name": "flag Greenland",
			"slug": "flag_greenland",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇲",
			"skin_tone_support": false,
			"name": "flag Gambia",
			"slug": "flag_gambia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇳",
			"skin_tone_support": false,
			"name": "flag Guinea",
			"slug": "flag_guinea",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇵",
			"skin_tone_support": false,
			"name": "flag Guadeloupe",
			"slug": "flag_guadeloupe",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇶",
			"skin_tone_support": false,
			"name": "flag Equatorial Guinea",
			"slug": "flag_equatorial_guinea",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇷",
			"skin_tone_support": false,
			"name": "flag Greece",
			"slug": "flag_greece",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇸",
			"skin_tone_support": false,
			"name": "flag South Georgia & South Sandwich Islands",
			"slug": "flag_south_georgia_south_sandwich_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇹",
			"skin_tone_support": false,
			"name": "flag Guatemala",
			"slug": "flag_guatemala",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇺",
			"skin_tone_support": false,
			"name": "flag Guam",
			"slug": "flag_guam",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇼",
			"skin_tone_support": false,
			"name": "flag Guinea-Bissau",
			"slug": "flag_guinea_bissau",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇬🇾",
			"skin_tone_support": false,
			"name": "flag Guyana",
			"slug": "flag_guyana",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇭🇰",
			"skin_tone_support": false,
			"name": "flag Hong Kong SAR China",
			"slug": "flag_hong_kong_sar_china",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇭🇲",
			"skin_tone_support": false,
			"name": "flag Heard & McDonald Islands",
			"slug": "flag_heard_mcdonald_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇭🇳",
			"skin_tone_support": false,
			"name": "flag Honduras",
			"slug": "flag_honduras",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇭🇷",
			"skin_tone_support": false,
			"name": "flag Croatia",
			"slug": "flag_croatia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇭🇹",
			"skin_tone_support": false,
			"name": "flag Haiti",
			"slug": "flag_haiti",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇭🇺",
			"skin_tone_support": false,
			"name": "flag Hungary",
			"slug": "flag_hungary",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇮🇨",
			"skin_tone_support": false,
			"name": "flag Canary Islands",
			"slug": "flag_canary_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇮🇩",
			"skin_tone_support": false,
			"name": "flag Indonesia",
			"slug": "flag_indonesia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇮🇪",
			"skin_tone_support": false,
			"name": "flag Ireland",
			"slug": "flag_ireland",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇮🇱",
			"skin_tone_support": false,
			"name": "flag Israel",
			"slug": "flag_israel",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇮🇲",
			"skin_tone_support": false,
			"name": "flag Isle of Man",
			"slug": "flag_isle_of_man",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇮🇳",
			"skin_tone_support": false,
			"name": "flag India",
			"slug": "flag_india",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇮🇴",
			"skin_tone_support": false,
			"name": "flag British Indian Ocean Territory",
			"slug": "flag_british_indian_ocean_territory",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇮🇶",
			"skin_tone_support": false,
			"name": "flag Iraq",
			"slug": "flag_iraq",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇮🇷",
			"skin_tone_support": false,
			"name": "flag Iran",
			"slug": "flag_iran",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇮🇸",
			"skin_tone_support": false,
			"name": "flag Iceland",
			"slug": "flag_iceland",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇮🇹",
			"skin_tone_support": false,
			"name": "flag Italy",
			"slug": "flag_italy",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🇯🇪",
			"skin_tone_support": false,
			"name": "flag Jersey",
			"slug": "flag_jersey",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇯🇲",
			"skin_tone_support": false,
			"name": "flag Jamaica",
			"slug": "flag_jamaica",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇯🇴",
			"skin_tone_support": false,
			"name": "flag Jordan",
			"slug": "flag_jordan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇯🇵",
			"skin_tone_support": false,
			"name": "flag Japan",
			"slug": "flag_japan",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🇰🇪",
			"skin_tone_support": false,
			"name": "flag Kenya",
			"slug": "flag_kenya",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇰🇬",
			"skin_tone_support": false,
			"name": "flag Kyrgyzstan",
			"slug": "flag_kyrgyzstan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇰🇭",
			"skin_tone_support": false,
			"name": "flag Cambodia",
			"slug": "flag_cambodia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇰🇮",
			"skin_tone_support": false,
			"name": "flag Kiribati",
			"slug": "flag_kiribati",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇰🇲",
			"skin_tone_support": false,
			"name": "flag Comoros",
			"slug": "flag_comoros",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇰🇳",
			"skin_tone_support": false,
			"name": "flag St. Kitts & Nevis",
			"slug": "flag_st_kitts_nevis",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇰🇵",
			"skin_tone_support": false,
			"name": "flag North Korea",
			"slug": "flag_north_korea",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇰🇷",
			"skin_tone_support": false,
			"name": "flag South Korea",
			"slug": "flag_south_korea",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🇰🇼",
			"skin_tone_support": false,
			"name": "flag Kuwait",
			"slug": "flag_kuwait",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇰🇾",
			"skin_tone_support": false,
			"name": "flag Cayman Islands",
			"slug": "flag_cayman_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇰🇿",
			"skin_tone_support": false,
			"name": "flag Kazakhstan",
			"slug": "flag_kazakhstan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇱🇦",
			"skin_tone_support": false,
			"name": "flag Laos",
			"slug": "flag_laos",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇱🇧",
			"skin_tone_support": false,
			"name": "flag Lebanon",
			"slug": "flag_lebanon",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇱🇨",
			"skin_tone_support": false,
			"name": "flag St. Lucia",
			"slug": "flag_st_lucia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇱🇮",
			"skin_tone_support": false,
			"name": "flag Liechtenstein",
			"slug": "flag_liechtenstein",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇱🇰",
			"skin_tone_support": false,
			"name": "flag Sri Lanka",
			"slug": "flag_sri_lanka",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇱🇷",
			"skin_tone_support": false,
			"name": "flag Liberia",
			"slug": "flag_liberia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇱🇸",
			"skin_tone_support": false,
			"name": "flag Lesotho",
			"slug": "flag_lesotho",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇱🇹",
			"skin_tone_support": false,
			"name": "flag Lithuania",
			"slug": "flag_lithuania",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇱🇺",
			"skin_tone_support": false,
			"name": "flag Luxembourg",
			"slug": "flag_luxembourg",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇱🇻",
			"skin_tone_support": false,
			"name": "flag Latvia",
			"slug": "flag_latvia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇱🇾",
			"skin_tone_support": false,
			"name": "flag Libya",
			"slug": "flag_libya",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇦",
			"skin_tone_support": false,
			"name": "flag Morocco",
			"slug": "flag_morocco",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇨",
			"skin_tone_support": false,
			"name": "flag Monaco",
			"slug": "flag_monaco",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇩",
			"skin_tone_support": false,
			"name": "flag Moldova",
			"slug": "flag_moldova",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇪",
			"skin_tone_support": false,
			"name": "flag Montenegro",
			"slug": "flag_montenegro",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇫",
			"skin_tone_support": false,
			"name": "flag St. Martin",
			"slug": "flag_st_martin",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇬",
			"skin_tone_support": false,
			"name": "flag Madagascar",
			"slug": "flag_madagascar",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇭",
			"skin_tone_support": false,
			"name": "flag Marshall Islands",
			"slug": "flag_marshall_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇰",
			"skin_tone_support": false,
			"name": "flag North Macedonia",
			"slug": "flag_north_macedonia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇱",
			"skin_tone_support": false,
			"name": "flag Mali",
			"slug": "flag_mali",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇲",
			"skin_tone_support": false,
			"name": "flag Myanmar (Burma)",
			"slug": "flag_myanmar",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇳",
			"skin_tone_support": false,
			"name": "flag Mongolia",
			"slug": "flag_mongolia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇴",
			"skin_tone_support": false,
			"name": "flag Macao SAR China",
			"slug": "flag_macao_sar_china",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇵",
			"skin_tone_support": false,
			"name": "flag Northern Mariana Islands",
			"slug": "flag_northern_mariana_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇶",
			"skin_tone_support": false,
			"name": "flag Martinique",
			"slug": "flag_martinique",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇷",
			"skin_tone_support": false,
			"name": "flag Mauritania",
			"slug": "flag_mauritania",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇸",
			"skin_tone_support": false,
			"name": "flag Montserrat",
			"slug": "flag_montserrat",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇹",
			"skin_tone_support": false,
			"name": "flag Malta",
			"slug": "flag_malta",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇺",
			"skin_tone_support": false,
			"name": "flag Mauritius",
			"slug": "flag_mauritius",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇻",
			"skin_tone_support": false,
			"name": "flag Maldives",
			"slug": "flag_maldives",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇼",
			"skin_tone_support": false,
			"name": "flag Malawi",
			"slug": "flag_malawi",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇽",
			"skin_tone_support": false,
			"name": "flag Mexico",
			"slug": "flag_mexico",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇾",
			"skin_tone_support": false,
			"name": "flag Malaysia",
			"slug": "flag_malaysia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇲🇿",
			"skin_tone_support": false,
			"name": "flag Mozambique",
			"slug": "flag_mozambique",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇦",
			"skin_tone_support": false,
			"name": "flag Namibia",
			"slug": "flag_namibia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇨",
			"skin_tone_support": false,
			"name": "flag New Caledonia",
			"slug": "flag_new_caledonia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇪",
			"skin_tone_support": false,
			"name": "flag Niger",
			"slug": "flag_niger",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇫",
			"skin_tone_support": false,
			"name": "flag Norfolk Island",
			"slug": "flag_norfolk_island",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇬",
			"skin_tone_support": false,
			"name": "flag Nigeria",
			"slug": "flag_nigeria",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇮",
			"skin_tone_support": false,
			"name": "flag Nicaragua",
			"slug": "flag_nicaragua",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇱",
			"skin_tone_support": false,
			"name": "flag Netherlands",
			"slug": "flag_netherlands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇴",
			"skin_tone_support": false,
			"name": "flag Norway",
			"slug": "flag_norway",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇵",
			"skin_tone_support": false,
			"name": "flag Nepal",
			"slug": "flag_nepal",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇷",
			"skin_tone_support": false,
			"name": "flag Nauru",
			"slug": "flag_nauru",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇺",
			"skin_tone_support": false,
			"name": "flag Niue",
			"slug": "flag_niue",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇳🇿",
			"skin_tone_support": false,
			"name": "flag New Zealand",
			"slug": "flag_new_zealand",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇴🇲",
			"skin_tone_support": false,
			"name": "flag Oman",
			"slug": "flag_oman",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇦",
			"skin_tone_support": false,
			"name": "flag Panama",
			"slug": "flag_panama",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇪",
			"skin_tone_support": false,
			"name": "flag Peru",
			"slug": "flag_peru",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇫",
			"skin_tone_support": false,
			"name": "flag French Polynesia",
			"slug": "flag_french_polynesia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇬",
			"skin_tone_support": false,
			"name": "flag Papua New Guinea",
			"slug": "flag_papua_new_guinea",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇭",
			"skin_tone_support": false,
			"name": "flag Philippines",
			"slug": "flag_philippines",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇰",
			"skin_tone_support": false,
			"name": "flag Pakistan",
			"slug": "flag_pakistan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇱",
			"skin_tone_support": false,
			"name": "flag Poland",
			"slug": "flag_poland",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇲",
			"skin_tone_support": false,
			"name": "flag St. Pierre & Miquelon",
			"slug": "flag_st_pierre_miquelon",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇳",
			"skin_tone_support": false,
			"name": "flag Pitcairn Islands",
			"slug": "flag_pitcairn_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇷",
			"skin_tone_support": false,
			"name": "flag Puerto Rico",
			"slug": "flag_puerto_rico",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇸",
			"skin_tone_support": false,
			"name": "flag Palestinian Territories",
			"slug": "flag_palestinian_territories",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇹",
			"skin_tone_support": false,
			"name": "flag Portugal",
			"slug": "flag_portugal",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇼",
			"skin_tone_support": false,
			"name": "flag Palau",
			"slug": "flag_palau",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇵🇾",
			"skin_tone_support": false,
			"name": "flag Paraguay",
			"slug": "flag_paraguay",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇶🇦",
			"skin_tone_support": false,
			"name": "flag Qatar",
			"slug": "flag_qatar",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇷🇪",
			"skin_tone_support": false,
			"name": "flag Réunion",
			"slug": "flag_reunion",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇷🇴",
			"skin_tone_support": false,
			"name": "flag Romania",
			"slug": "flag_romania",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇷🇸",
			"skin_tone_support": false,
			"name": "flag Serbia",
			"slug": "flag_serbia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇷🇺",
			"skin_tone_support": false,
			"name": "flag Russia",
			"slug": "flag_russia",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🇷🇼",
			"skin_tone_support": false,
			"name": "flag Rwanda",
			"slug": "flag_rwanda",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇦",
			"skin_tone_support": false,
			"name": "flag Saudi Arabia",
			"slug": "flag_saudi_arabia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇧",
			"skin_tone_support": false,
			"name": "flag Solomon Islands",
			"slug": "flag_solomon_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇨",
			"skin_tone_support": false,
			"name": "flag Seychelles",
			"slug": "flag_seychelles",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇩",
			"skin_tone_support": false,
			"name": "flag Sudan",
			"slug": "flag_sudan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇪",
			"skin_tone_support": false,
			"name": "flag Sweden",
			"slug": "flag_sweden",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇬",
			"skin_tone_support": false,
			"name": "flag Singapore",
			"slug": "flag_singapore",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇭",
			"skin_tone_support": false,
			"name": "flag St. Helena",
			"slug": "flag_st_helena",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇮",
			"skin_tone_support": false,
			"name": "flag Slovenia",
			"slug": "flag_slovenia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇯",
			"skin_tone_support": false,
			"name": "flag Svalbard & Jan Mayen",
			"slug": "flag_svalbard_jan_mayen",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇰",
			"skin_tone_support": false,
			"name": "flag Slovakia",
			"slug": "flag_slovakia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇱",
			"skin_tone_support": false,
			"name": "flag Sierra Leone",
			"slug": "flag_sierra_leone",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇲",
			"skin_tone_support": false,
			"name": "flag San Marino",
			"slug": "flag_san_marino",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇳",
			"skin_tone_support": false,
			"name": "flag Senegal",
			"slug": "flag_senegal",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇴",
			"skin_tone_support": false,
			"name": "flag Somalia",
			"slug": "flag_somalia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇷",
			"skin_tone_support": false,
			"name": "flag Suriname",
			"slug": "flag_suriname",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇸",
			"skin_tone_support": false,
			"name": "flag South Sudan",
			"slug": "flag_south_sudan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇹",
			"skin_tone_support": false,
			"name": "flag São Tomé & Príncipe",
			"slug": "flag_sao_tome_principe",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇻",
			"skin_tone_support": false,
			"name": "flag El Salvador",
			"slug": "flag_el_salvador",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇽",
			"skin_tone_support": false,
			"name": "flag Sint Maarten",
			"slug": "flag_sint_maarten",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇾",
			"skin_tone_support": false,
			"name": "flag Syria",
			"slug": "flag_syria",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇸🇿",
			"skin_tone_support": false,
			"name": "flag Eswatini",
			"slug": "flag_eswatini",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇦",
			"skin_tone_support": false,
			"name": "flag Tristan da Cunha",
			"slug": "flag_tristan_da_cunha",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇨",
			"skin_tone_support": false,
			"name": "flag Turks & Caicos Islands",
			"slug": "flag_turks_caicos_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇩",
			"skin_tone_support": false,
			"name": "flag Chad",
			"slug": "flag_chad",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇫",
			"skin_tone_support": false,
			"name": "flag French Southern Territories",
			"slug": "flag_french_southern_territories",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇬",
			"skin_tone_support": false,
			"name": "flag Togo",
			"slug": "flag_togo",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇭",
			"skin_tone_support": false,
			"name": "flag Thailand",
			"slug": "flag_thailand",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇯",
			"skin_tone_support": false,
			"name": "flag Tajikistan",
			"slug": "flag_tajikistan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇰",
			"skin_tone_support": false,
			"name": "flag Tokelau",
			"slug": "flag_tokelau",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇱",
			"skin_tone_support": false,
			"name": "flag Timor-Leste",
			"slug": "flag_timor_leste",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇲",
			"skin_tone_support": false,
			"name": "flag Turkmenistan",
			"slug": "flag_turkmenistan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇳",
			"skin_tone_support": false,
			"name": "flag Tunisia",
			"slug": "flag_tunisia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇴",
			"skin_tone_support": false,
			"name": "flag Tonga",
			"slug": "flag_tonga",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇷",
			"skin_tone_support": false,
			"name": "flag Turkey",
			"slug": "flag_turkey",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇹",
			"skin_tone_support": false,
			"name": "flag Trinidad & Tobago",
			"slug": "flag_trinidad_tobago",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇻",
			"skin_tone_support": false,
			"name": "flag Tuvalu",
			"slug": "flag_tuvalu",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇼",
			"skin_tone_support": false,
			"name": "flag Taiwan",
			"slug": "flag_taiwan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇹🇿",
			"skin_tone_support": false,
			"name": "flag Tanzania",
			"slug": "flag_tanzania",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇺🇦",
			"skin_tone_support": false,
			"name": "flag Ukraine",
			"slug": "flag_ukraine",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇺🇬",
			"skin_tone_support": false,
			"name": "flag Uganda",
			"slug": "flag_uganda",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇺🇲",
			"skin_tone_support": false,
			"name": "flag U.S. Outlying Islands",
			"slug": "flag_u_s_outlying_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇺🇳",
			"skin_tone_support": false,
			"name": "flag United Nations",
			"slug": "flag_united_nations",
			"unicode_version": "4.0",
			"emoji_version": "4.0"
		},
		{
			"emoji": "🇺🇸",
			"skin_tone_support": false,
			"name": "flag United States",
			"slug": "flag_united_states",
			"unicode_version": "0.6",
			"emoji_version": "0.6"
		},
		{
			"emoji": "🇺🇾",
			"skin_tone_support": false,
			"name": "flag Uruguay",
			"slug": "flag_uruguay",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇺🇿",
			"skin_tone_support": false,
			"name": "flag Uzbekistan",
			"slug": "flag_uzbekistan",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇻🇦",
			"skin_tone_support": false,
			"name": "flag Vatican City",
			"slug": "flag_vatican_city",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇻🇨",
			"skin_tone_support": false,
			"name": "flag St. Vincent & Grenadines",
			"slug": "flag_st_vincent_grenadines",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇻🇪",
			"skin_tone_support": false,
			"name": "flag Venezuela",
			"slug": "flag_venezuela",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇻🇬",
			"skin_tone_support": false,
			"name": "flag British Virgin Islands",
			"slug": "flag_british_virgin_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇻🇮",
			"skin_tone_support": false,
			"name": "flag U.S. Virgin Islands",
			"slug": "flag_u_s_virgin_islands",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇻🇳",
			"skin_tone_support": false,
			"name": "flag Vietnam",
			"slug": "flag_vietnam",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇻🇺",
			"skin_tone_support": false,
			"name": "flag Vanuatu",
			"slug": "flag_vanuatu",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇼🇫",
			"skin_tone_support": false,
			"name": "flag Wallis & Futuna",
			"slug": "flag_wallis_futuna",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇼🇸",
			"skin_tone_support": false,
			"name": "flag Samoa",
			"slug": "flag_samoa",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇽🇰",
			"skin_tone_support": false,
			"name": "flag Kosovo",
			"slug": "flag_kosovo",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇾🇪",
			"skin_tone_support": false,
			"name": "flag Yemen",
			"slug": "flag_yemen",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇾🇹",
			"skin_tone_support": false,
			"name": "flag Mayotte",
			"slug": "flag_mayotte",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇿🇦",
			"skin_tone_support": false,
			"name": "flag South Africa",
			"slug": "flag_south_africa",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇿🇲",
			"skin_tone_support": false,
			"name": "flag Zambia",
			"slug": "flag_zambia",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🇿🇼",
			"skin_tone_support": false,
			"name": "flag Zimbabwe",
			"slug": "flag_zimbabwe",
			"unicode_version": "2.0",
			"emoji_version": "2.0"
		},
		{
			"emoji": "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
			"skin_tone_support": false,
			"name": "flag England",
			"slug": "flag_england",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
			"skin_tone_support": false,
			"name": "flag Scotland",
			"slug": "flag_scotland",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		},
		{
			"emoji": "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
			"skin_tone_support": false,
			"name": "flag Wales",
			"slug": "flag_wales",
			"unicode_version": "5.0",
			"emoji_version": "5.0"
		}
	]
}