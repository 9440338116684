<template>
  <div class="header-container">
    <div :class="['header-background', { withShadow: !isUserOnHero }]" />
    <collapse-transition dimension="height">
      <div v-if="mobileMenuOpened" class="header-mobile-menu">
        <div class="header-menu-blur" />
        <transition name="fade-menu">
          <div v-show="mobileMenuOpened">
            <nav class="header-nav">
              <button
                @click="$router.currentRoute.name !== 'Home' ? $router.push({ name: 'Home' }) : ''"
                :class="[
                  'nav-link',
                  { 'nav-link--selected': $router.currentRoute.name.includes('Home') },
                ]"
              >
                {{ $t("Home.navbar.overview") }}
              </button>
              <button
                @click="
                  $router.currentRoute.name !== 'ContactUs'
                    ? $router.push({ name: 'ContactUs' })
                    : ''
                "
                :class="[
                  'nav-link',
                  { 'nav-link--selected': $router.currentRoute.name === 'ContactUs' },
                ]"
              >
                {{ $t("Home.navbar.contact-us") }}
              </button>
              <a
                class="nav-link"
                rel="noreferrer nofollow"
                target="_blank"
                href="https://utip.io/mw3y"
              >
                {{ $t("Home.navbar.donate") }}
              </a>
              <button @click="$open('/join-support')" class="nav-link">
                {{ $t("Home.navbar.support") }}
              </button>
            </nav>
            <div class="header-mobile-menu-button-container">
              <button class="large-button menu-button" @click="$open('/add-bot')">
                {{ $t("Home.addToDiscord") }}
              </button>
              <button
                ref="mobileLanguageButton"
                @click="languageDropdownOpened = !languageDropdownOpened"
                v-on-clickaway="closeLanguageDropdown"
                class=""
                :class="[
                  'large-button menu-button',
                  { 'languageSelector-clicked': languageDropdownOpened },
                ]"
              >
                <span class="buttonIcon" style="display: flex" v-html="$twemoji.parse('🌎')" />
                <span>{{ $t("Navbar.languages") }}</span>
                <LanguageDropdown
                  v-if="languageDropdownOpened"
                  :parent="$refs.mobileLanguageButton"
                  @click-outside="languageDropdownOpened = false"
                  @languageChange="(lang) => $emit('languageChange', lang)"
                />
              </button>
            </div>
          </div>
        </transition>
      </div>
    </collapse-transition>
    <div class="header">
      <div class="header-left">
        <button
          @click="$router.currentRoute.name !== 'Home' ? $router.push({ name: 'Home' }) : ''"
          class="header-logo"
        >
          <img :src="require('../resources/images/full-logo-dark-min.png')" alt="suivix-logo" />
        </button>
        <div v-if="statistics.guilds > 0" class="header-info">
          {{ $tc("Home.navbar.servers-info", statistics.guilds) }}
        </div>
        <div v-if="statistics.users > 0" class="header-info">
          {{ $tc("Home.navbar.users-info", statistics.users) }}
        </div>
      </div>
      <nav class="header-nav">
        <button
          @click="$router.currentRoute.name !== 'Home' ? $router.push({ name: 'Home' }) : ''"
          :class="[
            'nav-link',
            { 'nav-link--selected': $router.currentRoute.name.includes('Home') },
          ]"
        >
          {{ $t("Home.navbar.overview") }}
        </button>
        <button
          @click="
            $router.currentRoute.name !== 'ContactUs' ? $router.push({ name: 'ContactUs' }) : ''
          "
          :class="['nav-link', { 'nav-link--selected': $router.currentRoute.name === 'ContactUs' }]"
        >
          {{ $t("Home.navbar.contact-us") }}
        </button>
        <a class="nav-link" rel="noreferrer nofollow" target="_blank" href="https://utip.io/mw3y">
          {{ $t("Home.navbar.donate") }}
        </a>
        <button @click="$open('/join-support')" class="nav-link">
          {{ $t("Home.navbar.support") }}
        </button>
      </nav>
      <div class="header-right">
        <button
          v-if="!mobileMenuOpened"
          @click="languageDropdownOpened = !languageDropdownOpened"
          v-on-clickaway="closeLanguageDropdown"
          ref="languageButton"
          :class="[
            'header-button header-button-languageSelector',
            { 'languageSelector-clicked': languageDropdownOpened },
          ]"
        >
          <span class="buttonIcon" v-html="$twemoji.parse('🌎')" />
          <span>{{ $t("Navbar.languages") }}</span>
          <LanguageDropdown
            v-if="languageDropdownOpened"
            :parent="$refs.languageButton"
            @click-outside="languageDropdownOpened = false"
            @languageChange="(lang) => $emit('languageChange', lang)"
          />
        </button>
        <button
          :class="[
            'header-button header-button-addToDiscord',
            { 'header-button-colored': !isUserOnHero },
          ]"
          @click="$open('/add-bot')"
        >
          {{ $t("Home.addToDiscord") }}
        </button>
        <button
          @click="mobileMenuOpened = !mobileMenuOpened"
          class="header-button header-menu-button"
        >
          <font-awesome-icon
            v-if="!mobileMenuOpened"
            class="buttonIcon menuIcon"
            :icon="['fas', 'bars']"
          />
          <font-awesome-icon v-else class="buttonIcon menuIcon" :icon="['fas', 'times']" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { directive as onClickaway } from "vue-clickaway";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";
import LanguageDropdown from "./LanguagesDropdown.vue";

library.add(faBars, faTimes);

export default {
  name: "HomeNavbar",
  components: {
    LanguageDropdown,
    CollapseTransition,
  },
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    activeRoute: {
      type: String,
      required: false,
      default: "home",
    },
    heroContainer: {
      type: HTMLElement,
      required: false,
      default: undefined,
    },
    openMenu: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      mobileMenuOpened: false,
      languageDropdownOpened: false,
      isUserOnHero: true,
      statistics: {
        guilds: 0,
        users: 0,
      },
    };
  },
  methods: {
    handleScroll: function () {
      const isUserOnHero = !(window.scrollY > this.heroContainer?.clientHeight - 80 * 2 ?? false);
      // console.log(window.scrollY, this.heroContainer?.clientHeight, isUserOnHero);
      if (this.heroContainer && isUserOnHero !== this.isUserOnHero) {
        if (isUserOnHero) this.$emit("hero-enter");
        else this.$emit("hero-leave");
        this.isUserOnHero = isUserOnHero;
      }
    },
    setLanguage: function (val) {
      this.$i18n.locale = val;
      window.localStorage.setItem("siteLanguage", val);
    },
    closeLanguageDropdown: function () {
      this.languageDropdownOpened = false;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  async beforeCreate() {
    // Prevent double fetch request (one for the navbar & one for the footer)
    if (window.innerWidth > 767) {
      const res = await fetch("/api/statistics");
      const data = await res.json();
      if (data) this.statistics = data;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../resources/styles/home.scss";

.fade-menu-active,
.fade-menu-leave-active {
  transition: opacity 0.6s;
}
.fade-menu-enter, .fade-menu-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.header-container {
  color: $title;
  box-sizing: border-box;
  z-index: 100;
  width: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  user-select: none;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 800px) {
    padding-right: 10px;
    padding-left: 10px;
  }

  .menuIcon {
    margin: 0;
  }

  .nav-link {
    color: $title + CC;
    display: inline-block;
    cursor: pointer;
    position: relative;
    margin: 0 24px;
    transition: 0.2s ease-in;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.8;
    background: none;
  }

  .nav-link--selected {
    // transform: translateY(-1px) scaleX(1.1);
    font-weight: 550;
  }

  .nav-link--selected,
  .nav-link:hover {
    color: $title;
    opacity: 1;
  }

  .header-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: hsla(0, 0%, 100%, 0.8);
    backdrop-filter: blur(20px);
    transition: all 0.3s ease-in-out;

    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      // Firefox does not support backdrop-filter
      background-color: hsla(0, 0%, 100%, 0.98);
    }
  }

  .withShadow {
    box-shadow: 0px 30px 60px -10px rgba(0, 0, 0, 0.03);
  }

  .header-mobile-menu {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: clamp(510px, 62.5vh, 100vh);
    overflow: visible;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 30px 180px -10px;

    @media screen and (max-width: 700px) {
      display: block;
    }

    .header-menu-blur {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: clamp(510px, 62.5vh, 100vh);
      background-color: hsla(0, 0%, 100%, 0.6);
      backdrop-filter: blur(20px);
      transition: all 0.3s ease-in-out;

      @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        // Firefox does not support backdrop-filter
        background-color: hsla(0, 0%, 100%, 0.98);
      }
    }

    &-button-container {
      position: relative;
      display: flex;
      justify-content: space-between;
      z-index: 100;
      margin: 25px 31px;
      right: 0;
      left: 0;

      .menu-button {
        margin: 0;
        flex: 0.48;

        &:hover {
          transform: none;
        }

        &:last-of-type {
          background-color: #eef0f2;
          color: #131615;
        }
      }
    }

    .header-nav {
      position: relative;
      padding-top: 80px;
      flex-direction: column;
      display: flex;
      text-align: left;
      align-items: flex-start;

      .nav-link {
        text-align: left;
        padding: 25px 31px;
        margin: 0;
        font-size: 20px;
        line-height: 1.2;
        width: 100%;
        box-sizing: border-box;

        &--selected {
          transform: none;
        }
      }
    }
  }

  .header {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 64px;
    max-width: 1920px;

    @media screen and (max-width: 1260px) {
      margin: 0 32px;
    }

    @media screen and (max-width: 1000px) {
      margin: 0 16px;
    }

    .header-left {
      display: flex;
      position: absolute;
      margin-left: 20px;

      @media screen and (max-width: 767px) {
        margin-left: 0;
      }

      .header-info {
        display: inline-flex;
        margin-left: 16px;
        color: $title;
        align-items: center;

        // 1360px
        @media screen and (max-width: 1588px) {
          display: none;
        }

        &:before {
          width: 4px;
          height: 4px;
          margin-right: 6px;
          content: "";
          border-radius: 50%;
          background: rgba(39, 41, 55, 0.45);
        }
      }

      .header-logo {
        background: none;
        padding: 0;
        cursor: pointer;

        img {
          max-height: 25px;
        }
      }
    }

    .header-right {
      position: absolute;
      right: 0;
      margin-right: 20px;
      display: flex;
      align-items: center;
      align-content: center;
      flex-direction: row;
      flex-wrap: nowrap;

      .header-menu-button {
        display: none;

        @media screen and (max-width: 700px) {
          display: flex;
          padding: 15px 15px;
          max-width: 44px;
          max-height: 44px;
        }
      }
    }

    .header-nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
      justify-content: center;

      .nav-link {
        @media screen and (max-width: 1390px) {
          margin: 0 12px;
        }

        @media screen and (max-width: 950px) {
          margin: 0 6px;
        }

        @media screen and (max-width: 700px) {
          display: none;
          box-shadow: 0 -0.5px 0 0 rgba(0, 0, 0, 0.1);
          padding-top: 32px;
        }
      }
    }

    .header-button {
      display: inline-flex;
      padding: 12px 15px;
      border-radius: 50px;
      font-size: 16px;
      background-color: #eef0f2;
      cursor: pointer;
      font-weight: 700;
      color: $title;
      transition: 0.2s ease-in-out;
      text-align: center;

      &-colored {
        background-color: $primary;
        color: #fff;
      }

      &-addToDiscord {
        @media screen and (max-width: 1260px) {
          display: none;
        }
      }

      &-languageSelector {
        margin-right: 8px;
      }

      @media screen and (max-width: 700px) {
        display: none;
      }
    }
  }

  .languageSelector {
    background: none;
    align-items: center;

    &-clicked {
      background-color: #fff !important;
      box-shadow: 0 0px 13px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
