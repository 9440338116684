<template>
  <div>
    <Navbar @userDataLoaded="onUserDataLoaded" :openMenu="openModal"></Navbar>
    <modal ref="popup" :title="$t('Servers.popup.hello') + ' ' + user.username">
      <template #headerIcon>
        <img
          style="max-width: 108px"
          v-if="user.avatar"
          class="modal__icon"
          :src="`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}`"
        />
        <img
          style="max-width: 108px"
          v-else
          class="modal__icon"
          src="https://cdn.discordapp.com/embed/avatars/1.png"
        />
      </template>
      <template #body>
        <p class="separator">{{ $t("Servers.popup.whatWouldYouLikeToDo") }}</p>
        <div class="option" @click="() => selectAction('attendance')">
          <img class="icon" :src="require('./resources/images/icons/attendance-min.png')" />
          <h1>
            {{ $t("Attendance.title") }}
            <p style="text-align: left">
              {{ $t("Servers.popup.attendanceDescription") }}
            </p>
          </h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M 7 10 L 12 15 L 17 10"
            ></path>
          </svg>
        </div>
        <div class="option" @click="() => selectAction('poll')">
          <img class="icon borderless" :src="require('./resources/images/icons/poll-min.png')" />
          <h1>
            {{ $t("Poll.title") }}
            <p style="text-align: left">
              {{ $t("Servers.popup.pollDescription") }}
            </p>
          </h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M 7 10 L 12 15 L 17 10"
            ></path>
          </svg>
        </div>
        <div class="option" @click="$open('https://utip.io/mw3y')">
          <img class="icon borderless" :src="require('./resources/images/icons/donate-min.png')" />
          <h1>
            {{ $t("Servers.popup.supportMe.title") }}
            <p>
              {{
                $t("Servers.popup.supportMe.description", {
                  age: getYearsNumberSince2005(),
                })
              }}
            </p>
          </h1>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M 7 10 L 12 15 L 17 10"
            ></path>
          </svg>
        </div>
        <p class="separator">{{ $t("Callback.needHelp") }}</p>
        <div class="option" @click="$open('/join-support')">
          <img class="icon borderless" :src="require('./resources/images/support-icon-animated.gif')" />
          <h1>
            {{ $t("Callback.joinSupportServer.title") }}
            <p>
              {{ $t("Callback.joinSupportServer.description") }}
            </p>
          </h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M 7 10 L 12 15 L 17 10"
            ></path>
          </svg>
        </div>
      </template>
    </modal>
    <div
      class="container"
      :style="`background-image: url(${require('./resources/images/background.jpg')})`"
    >
      <div class="card">
        <div class="content">
          <img
            :src="require('./resources/images/servers/illustration-min.png')"
            alt="Page Illustration"
            class="big borderless illustration"
          />
          <h1>{{ $t("Servers.title") }}</h1>
          <p class="description">
            {{ $t("Servers.description") }}
          </p>
          <div class="guilds">
            <div v-if="guilds.length === 0">
              <div class="guild-card">
                <span>
                  {{ $t("Global.loading") + "..." }}
                  <div width="35" class="imageLoading"></div>
                </span>
              </div>
              <div class="guild-card">
                <span>
                  {{ $t("Global.loading") + "..." }}
                  <div width="35" class="imageLoading"></div>
                </span>
              </div>
              <div class="guild-card">
                <span>
                  {{ $t("Global.loading") + "..." }}
                  <div width="35" class="imageLoading"></div>
                </span>
              </div>
            </div>
            <div v-if="guilds">
              <div
                @click="$open('/join-support')"
                v-if="!guilds.find((g) => g.id === supportServerId)"
                class="guild-card"
              >
                <span>
                  {{ $t("Servers.joinSupportServer") }}
                  <img
                    class="icon server-icon"
                    :src="require('./resources/images/support-icon-animated.gif')"
                  />
                  <button title="Join Suivix © Support">
                    <font-awesome-icon class="icon" :icon="['fas', 'arrow-right']" />
                  </button>
                </span>
              </div>
              <div
                @click="createRequestOrInviteBot(id, hasBot)"
                class="guild-card"
                v-for="{ id, name, icon, hasBot } in guilds"
                :key="id"
              >
                <div class="guild-card-container">
                  <span>{{ name }}</span>
                  <div class="guild-card-icons-container">
                    <img v-if="icon" class="icon server-icon" :src="getGuildIcon(id, icon)" />
                    <span v-if="!icon" class="icon noImage">
                      {{
                        name
                          .replace(/'s /g, " ")
                          .replace(/\w+/g, (e) => e[0])
                          .replace(/\s/g, "")
                      }}
                    </span>
                    <button v-if="!hasBot" :title="'Add Suivix to ' + name">
                      <font-awesome-icon class="icon" :icon="['fas', 'plus']" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomArrow">
            <font-awesome-icon class="icon" :icon="['fas', 'chevron-down']" />
          </div>
        </div>
      </div>
    </div>
    <CookiesBanner></CookiesBanner>
  </div>
</template>

<script>
import CookiesBanner from "./components/CookiesBanner.vue";
import Navbar from "./components/Navbar.vue";
import Modal from "./components/Modal.vue";
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faChevronDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";

library.add(faPlus, faChevronDown, faArrowRight);

export default {
  name: "Servers",
  components: {
    CookiesBanner,
    Navbar,
    Modal,
  },
  metaInfo() {
    const title = this.$i18n.t("Servers.title");
    return {
      title: (chunk) => chunk.slice(0, -2) + title,
    };
  },
  data() {
    return {
      user: {
        username: "...",
        discriminator: "0001",
        avatar: null,
      },
      requestType: "attendance",
      guilds: [],
      supportServerId: process.env.VUE_APP_SUPPORT_SERVER_ID,
    };
  },
  methods: {
    // Returns the guild icon
    getGuildIcon: (id, icon) => `https://cdn.discordapp.com/icons/${id}/${icon}.png`,
    getYearsNumberSince2005: () => moment().diff(moment("2005"), "years"),
    onUserDataLoaded: function (user) {
      this.user = user;
    },
    selectAction: function (type) {
      this.requestType = type;
      this.$refs.popup.closeModal();
    },
    createRequestOrInviteBot: async function (id, hasBot) {
      // If the bot isn't on the guild, redirect to /add-bot
      if (!hasBot) return this.$open("/add-bot/" + id);
      const res = await fetch(`/api/${this.requestType}/create/${id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      // Handle server error
      if (res.status === 500) this.$router.push({ name: "ServerSelection" });
      else
        this.$router.push({ name: this.requestType === "poll" ? "CreatePoll" : "TakeAttendance" });
    },
    openModal: function () {
      this.$refs.popup.openModal();
    },
  },
  /**
   * Loads the user guild infos from the api
   */
  async beforeCreate() {
    const res = await fetch("/api/user/guilds");
    const data = await res.json();
    if (data.status === 401) this.$redirect("/login?redirectTo=/servers");
    else this.guilds = data;
  },
  async mounted() {
    this.openModal();
  },
};
</script>

<style scoped lang="scss">
@import "./resources/styles/app.scss";

.guilds {
  margin-top: 18px;
  height: 160px;
  position: relative;
  right: -5px;
  overflow: auto;

  .guild-card {
    background-color: #202225;
    padding: 8px 16px;
    border-radius: 8px;
    // height: 35px;
    margin-bottom: 3px;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    text-align: left;

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-icons-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }

    span {
      text-align: left;
      font-size: 16px;
      // line-height: 35px;
      color: #fff;
      vertical-align: middle;
      display: inline;
    }

    .server-icon {
      height: 100%;
      max-height: 35px;
      float: right;
      vertical-align: middle;
      border-radius: 100px;
      transition: 0.3s;
    }

    .noImage {
      height: 35px;
      background-color: #36393f;
      width: 35px;
      float: right;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      text-align: center;
      font-size: 13px;
      overflow: hidden;
    }

    &:hover {
      background-color: #1c1e21;
      transform: scale(0.98, 0.98);

      .icon {
        border-radius: 12px;
      }

      span {
        font-weight: 500;
      }
    }

    button {
      height: 100%;
      vertical-align: middle;
      border-radius: 100px;
      // background-color: #343a40;
      background-color: transparent;
      padding: 5px 5px;
      margin-right: 5px;
      width: 35px;
      float: right;
      transition: 0.4s;
      color: #fff;
      cursor: pointer;

      &:hover {
        background-color: #7289da;
      }
    }
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #202225;
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
}

.bottomArrow {
  font-size: 36px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
  padding: 5px;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  animation: float 3s ease-out infinite;
}
</style>
