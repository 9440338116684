<template>
  <div>
    <Navbar></Navbar>
    <NewsModal></NewsModal>
    <modal ref="popup" :title="callback.title">
      <template #headerIcon>
        <img
          v-if="!callback.error"
          class="modal__icon borderless"
          :src="require('./resources/images/icons/party.svg')"
        />
        <img v-else class="modal__icon borderless" :src="require('./resources/images/icons/warning.svg')" />
      </template>
      <template #body>
        <p class="modal__description">{{ callback.description }}</p>
        <div class="option" @click="$router.push({ name: 'ServerSelection' })">
          <img class="icon borderless" :src="require('./resources/images/icons/servers-min.png')" />
          <h1>{{ $t("Callback.selectAnotherServer") }}</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M 7 10 L 12 15 L 17 10"
            ></path>
          </svg>
        </div>
        <div
          class="option"
          v-if="callback.sheetURL !== null"
          @click="downloadFile(callback.sheetURL)"
        >
          <img class="icon borderless" :src="require('./resources/images/icons/chart-min.png')" />
          <h1>{{ $t("Attendance.callback.downloadAttendanceSheet") }}</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M 7 10 L 12 15 L 17 10"
            ></path>
          </svg>
        </div>
        <div class="option" @click="createRequest()">
          <img class="icon borderless" :src="require('./resources/images/icons/redo-min.png')" />
          <h1>{{ $t("Attendance.callback.takeAttendanceAgain") }}</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M 7 10 L 12 15 L 17 10"
            ></path>
          </svg>
        </div>
        <p class="separator">{{ $t("Callback.needHelp") }}</p>
        <div class="option" @click="$open('/join-support')">
          <img class="icon borderless" :src="require('./resources/images/support-icon-animated.gif')" />
          <h1>{{ $t("Callback.joinSupportServer.title") }}</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M 7 10 L 12 15 L 17 10"
            ></path>
          </svg>
        </div>
      </template>
    </modal>
    <div
      class="container"
      :style="`background-image: url(${require('./resources/images/background.jpg')})`"
    >
      <div :class="['card', { shake: isMissingInfos }]">
        <div class="content">
          <img
            :src="require('./resources/images/attendance/illustration-min.jpg')"
            alt="Page Illustration"
            class="illustration"
          />
          <h1>{{ $t("Attendance.title") }}</h1>
          <p class="description">{{ $t("Attendance.description") }}</p>
          <div class="vue-select infosMargin">
            <v-select
              :placeholder="$t('Attendance.whereWillTheResultBeSent')"
              autocomplete
              :options="infos.channels.textChannels"
              @input="selectDestinationChannel"
              label="name"
              :value="channels.resultDestination"
            >
              <!-- Custom style in the dropdown -->
              <template #option="{ category, name }">
                <span>{{ truncateStr(`${category ? "(" + category + ") " : ""}${name}`) }}</span>
              </template>
              <!-- Custom style when selected -->
              <template #selected-option="{ category, name }">
                <span>{{ truncateStr(`${category ? "(" + category + ") " : ""}${name}`) }}</span>
              </template>
            </v-select>
          </div>
          <p class="infos">{{ $t("Attendance.infos") }}</p>
          <div class="vue-select">
            <v-select
              :placeholder="$t('Attendance.voiceChannelsPlaceholder')"
              autocomplete
              multiple
              :options="infos.channels.voiceChannels"
              @input="setSelectedChannels"
              label="name"
              :selectable="() => channels.voiceChannels.length < 8"
            >
              <!-- Custom style in the dropdown -->
              <template #option="{ type, category, name, users }">
                <span>{{ truncateStr(`${category ? "(" + category + ") " : ""}${name}`) }}</span>
                <div class="dropdown-option-right" style="min-width: 30px">
                  <!-- TODO Text channel support -->
                  <img
                    alt="speaker"
                    :src="require(`./resources/images/attendance/${type === 'GUILD_VOICE' ? 'speaker' : 'stage_channel'}-min.png`)"
                  />
                  <span class="count">{{ users }}</span>
                </div>
              </template>
              <!-- Custom style when selected -->
              <template #selected-option="{ category, name }">
                <span>{{ truncateStr(`${category ? "(" + category + ") " : ""}${name}`) }}</span>
              </template>
            </v-select>
          </div>
          <div class="vue-select">
            <v-select
              :placeholder="$t('Attendance.rolesPlaceholder')"
              autocomplete
              multiple
              :options="infos.roles"
              @input="setSelectedRoles"
              label="name"
              :selectable="() => roles.length < 8"
            >
              <!-- Custom style in the dropdown -->
              <template #option="{ name, color, users }">
                <span
                  :style="`border-radius: 3px; padding: 2px 3px; background: #${getHexaColor(
                    color,
                    '7289da'
                  )}1A; color: #${getHexaColor(color)}`"
                >
                  {{ truncateStr(name, 30) }}
                </span>
                <div class="dropdown-option-right" :style="`min-width: 50px;`">
                  <img alt="people" :src="require('./resources/images/attendance/users-min.png')" />
                  <span class="count">{{ users }}</span>
                </div>
              </template>
              <!-- Custom style when selected -->
              <template #selected-option="{ name, color }">
                <span :style="`color: #${getHexaColor(color)}`">
                  {{ truncateStr(name, 30) }}
                </span>
              </template>
            </v-select>
          </div>
          <p class="infos">{{ $t("Attendance.timezone") }}</p>
          <div class="vue-select">
            <v-select
              :placeholder="$t('Attendance.timezonePlaceholder')"
              :value="timezone"
              :options="getTimezones()"
              @input="setTimezone"
            >
              <!-- Custom style in the dropdown -->
              <template #option="{ label }">
                <span>
                  {{ label }}
                </span>
                <div class="dropdown-option-right" style="min-width: 90px">
                  <img alt="clock" :src="require('./resources/images/attendance/clock-min.png')" />
                  <span class="count">{{ getCurrentTime(label) }}</span>
                </div>
              </template>
              <!-- Custom style when selected -->
              <template #selected-option="{ label }">
                <div style="margin-right: 5px" class="dropdown-option-right">
                  <span class="count">{{ getCurrentTime(label) }}</span>
                </div>
                <span>
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
          <div class="buttons">
            <div v-if="!isLoading">
              <button class="white">
                <font-awesome-icon class="icon" :icon="['fas', 'save']" />
              </button>
              <button @click="deleteAttendanceRequest()" class="black">
                <font-awesome-icon class="icon" :icon="['fas', 'times']" />
              </button>
              <button @click="takeAttendance()" class="blue">
                <font-awesome-icon class="icon" :icon="['fas', 'check']" />
              </button>
            </div>
            <div v-else class="circleLoader"></div>
          </div>
        </div>
      </div>
    </div>
    <CookiesBanner/>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Modal from "./components/Modal.vue";
import NewsModal from "./components/NewsModal.vue";
import CookiesBanner from "./components/CookiesBanner.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSave, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import utils from "./mixins/utils";

library.add(faSave, faTimes, faCheck);

export default {
  name: "Attendance",
  mixins: [utils],
  components: {
    Navbar,
    Modal,
    NewsModal,
    CookiesBanner
  },
  metaInfo() {
    const title = this.$i18n.t("Attendance.title");
    return {
      title: (chunk) => chunk.slice(0, -2) + title,
      meta: [
        { name: "description", content: this.$t("Home.sections.features.attendance.description") },
        { property: "og:title", content: this.$t("Attendance.title") },
        { property: "og:description", content: this.$t("Home.sections.features.attendance.description") },
      ],
    };
  },
  data() {
    return {
      isMissingInfos: false,
      infos: {
        channels: {
          voiceChannels: [],
          textChannels: []
        }
      },
      channels: {
        voiceChannels: [],
        resultDestination: undefined // the channel where the result will be sent
      },
      roles: [],
      timezone: undefined,
      callback: {},
      isLoading: false,
    };
  },
  methods: {
    selectDestinationChannel: function (textChannel) {
      this.channels.resultDestination = textChannel;
    },
    setTimezone: function (tz) {
      this.timezone = tz;
    },
    setSelectedChannels: function (voiceChannels) {
      this.channels.voiceChannels = voiceChannels;
    },
    setSelectedRoles: function (roles) {
      this.roles = roles;
    },
    downloadFile: function (fileURL) {
      this.$open(fileURL);
    },
    /**
     * Takes Attendance
     */
    takeAttendance: async function () {
      const self = this;
      if (self.channels.voiceChannels.length === 0 || self.roles.length === 0 || !self.timezone) {
        self.isMissingInfos = true;
        setTimeout(() => {
          self.isMissingInfos = false;
        }, 1000);
      } else {
        this.isLoading = true;
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            channels: this.channels.voiceChannels.map((c) => c.id),
            roles: this.roles.map((r) => r.id),
            resultDestinationChannel: this.channels.resultDestination?.id ?? "dm",
            language: this.$i18n.locale || "en",
            timezone: this.timezone,
          }),
        };
        const res = await fetch("/api/attendance/take", options);
        const data = await res.json();

        // Handle server error
        if (res.status === 403) return this.$router.push({ name: 'ServerSelection' });

        this.callback = {
          title: this.$t(
            "Attendance.callback." +
              (data.error ? `errors.${data.error}.title` : "attendanceTaken.title")
          ),
          description: this.$t(
            "Attendance.callback." +
              (data.error ? `errors.${data.error}.description` : "attendanceTaken.description")
          ),
          ...data,
        };
        this.isLoading = false;
        this.$refs.popup.openModal();
      }
    },
    /**
     * Delete an attendance request
     */
    deleteAttendanceRequest: async function () {
      const options = {
        method: "DELETE",
      };
      await fetch("/api/attendance/delete", options);
      this.$router.push({ name: 'ServerSelection' });
    },
    createRequest: async function () {
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      const res = await fetch(
        `/api/attendance/create/${this.infos.request.guildId}/${
          this.infos.request.channelId ?? ""
        }`,
        options
      );
      // Handle server error
      if (res.status === 500) this.$router.push({ name: 'ServerSelection' });
      else this.$router.go();
    },
  },
  /**
   * Loads the attendance infos from the api
   */
  async beforeCreate() {
    const res = await fetch("/api/attendance");
    const data = await res.json();
    if (data.status === 401) this.$redirect("/login?redirectTo=/attendance");
    else if (data.status === 403 || data.roles === null || data.channels === null)
      this.$router.push({ name: 'ServerSelection' });
    else {
      this.infos = data;
      // Add a private message option
      this.infos.channels.textChannels.unshift({ name: this.$t("Servers.privateMessage"), id: "dm" });
      // Select a the default channel (if there's one)
      this.channels.resultDestination = this.infos.channels.textChannels.find(c => c.id === this.infos.request.channelId);
      this.timezone = data.timezone ?? this.guessTimezone();
    }
  },
};
</script>

<style lang="scss">
@import "./resources/styles/components/vue-select/vue-select.scss";
</style>

<style scoped lang="scss">
@import "./resources/styles/app.scss";

.vue-select {
  margin-top: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-option-right {
  float: right;
  mix-blend-mode: exclusion;
  text-align: right;
  background-color: #2326298a;
  padding: 1px 6px;
  border-radius: 4px;

  .count {
    color: #888;
  }

  img {
    max-height: 18px;
    text-align: left;
    float: left;
    margin-right: 2px;
  }
}
</style>
