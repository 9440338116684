import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /website.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const locale = key.split("./")[1].split("/")[0]
    messages[locale] = locales(key)
  })
  return messages
}

export default new VueI18n({
  locale: window.localStorage.getItem('siteLanguage') || navigator.userLanguage?.split('-')[0] || navigator.language.split('-')[0],
  fallbackLocale: process.env.VUE_APP_DEFAULT_LANGUAGE,
  messages: loadLocaleMessages()
})
