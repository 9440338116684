import Vue from 'vue'
import App from './App.vue'
import initRouter from './router'
import i18n from './i18n'

import { ToggleButton } from 'vue-js-toggle-button'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueSelect from 'vue-select'
import VueTwemoji from './twemoji'

import VueMeta from 'vue-meta';
import VueGtag from "vue-gtag";

Vue.use(VueTwemoji);
Vue.use(VueMeta);

Vue.config.productionTip = false
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', VueSelect)
Vue.component('ToggleButton', ToggleButton)

Vue.prototype.$redirect = (url) => window.location.href = url;
Vue.prototype.$open = (url) => window.open(url);

const router = initRouter(i18n);
Vue.use(VueGtag, { config: { id: "UA-108500515-2" } }, router);

new Vue({
  i18n,
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
