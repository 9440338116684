<template>
  <transition name="fade">
    <div class="modal" v-if="show">
      <div class="modal__backdrop" />
      <!-- @click="closeModal()" -->
      <div class="modal__wrapper">
        <div class="modal__dialog">
          <slot name="headerIcon" />
          <div class="modal__header">
            <h1>{{ title }}</h1>
          </div>
          <div class="modal__body">
            <slot name="body" />
          </div>
          <div class="modal__footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

library.add(faTimes);

export default {
  name: "Modal",
  props: {
    title: String,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
    },
    openModal() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../resources/styles/global/variables.scss";
@import "../resources/styles/components/modal.scss";
</style>
