<template>
  <div v-if="!cookiesConsent" class="cookiesBannerContainer">
    <div class="cookiesBanner">
      <img class="cookieIcon" :src="require('../resources/images/icons/cookie.svg')" />
      <p class="cookieText">{{ $t("Cookies.description") }}</p>
      <div class="cookieButtonsWrapper">
        <button @click="$router.push({ name: 'PrivacyPolicy' })" class="cookieButton">
          {{ $t("Cookies.readMore") }}
        </button>
        <button @click="acceptCookies" class="cookieCloseButton">
          <font-awesome-icon class="cookieCloseIcon" :icon="['fas', 'times']" size="lg" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

library.add(faTimes);

export default {
  name: "CookiesBanner",
  data() {
    return {
      cookiesConsent: false,
    };
  },
  methods: {
    acceptCookies: function () {
      window.localStorage.setItem("cookiesConsent", "true");
      this.cookiesConsent = true;
    },
  },
  mounted() {
    this.cookiesConsent =
      window.localStorage.getItem("cookiesConsent") === "true" ||
      window.localStorage.getItem("cookieconsent") === "true"; // Provide support for the old "cookie consent" setting
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../resources/styles/global/variables.scss";

.cookiesBannerContainer {
  display: flex;
  justify-content: center;

  .cookiesBanner {
    display: flex;
    position: fixed;
    justify-content: space-between;
    max-width: 40%;
    bottom: 5px;
    background-color: $secondary;
    z-index: 5;
    margin: 10px 15%;
    border-radius: 12px;
    padding: 5px 10px;
    box-shadow: 0 0px 18px 0 rgba(0, 0, 0, 0.3);

    @media screen and (max-width: 1050px) {
      max-width: 85%;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 100%;
      margin: 0;
      border-radius: 8px 8px 0 0;
    }

    .cookieIcon {
      padding: 10px;
      height: 35px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .cookieText {
      color: $title;
      display: flex;
      align-self: center;
      flex: 1;
      margin-right: 15px;

      @media screen and (max-width: 767px) {
        text-align: justify;
        padding: 10px;
      }
    }

    .cookieButtonsWrapper {
      display: inline-flex;
      justify-content: center;

      @media screen and (max-width: 767px) {
        padding: 15px;
      }

      .cookieButton {
        display: flex;
        align-self: center;
        justify-content: center;
        border-radius: 8px;
        padding: 10px 20px;
        cursor: pointer;
        background-color: $background;
        color: #fff;
        transition: all 0.3s ease-in-out;
        font-size: 16px;
        font-weight: 500;

        &:hover {
          background-color: #2a2e30;
        }

        @media screen and (max-width: 767px) {
          flex: 1;
        }
      }

      .cookieCloseButton {
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: transparent;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        font-size: 16px;

        &:hover {
          transform: scale(1.1);
        }

        .cookieCloseIcon {
          color: #fff;
        }
      }

      // .iAccept {
      //   background-color: $success;
      //   color: $title;
      //   margin-right: 10px;
      //   font-weight: 600;
      //   text-decoration: none;
      // }
    }
  }
}
</style>
