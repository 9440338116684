<template>
  <div class="container">
    <HomeNavbar
      :heroContainer="$refs.heroContainer"
      @hero-enter="isUserOnHero = true"
      @hero-leave="isUserOnHero = false"
      @languageChange="onLanguageChange"
    />
    <!-- TODO https://www.npmjs.com/package/vue-scroll-reveal -->
    <div
      ref="heroContainer"
      class="hero-container"
      :style="`background-image: url(${require('./resources/images/home/background-min.jpg')})`"
    >
      <section class="hero">
        <div class="hero-left">
          <transition name="slide-fade" mode="out-in">
            <div :key="titleIndex">
              <i18n
                tag="h1"
                :path="`Home.sections.hero.titles[${titleIndex}].title`"
                :for="`Home.sections.hero.titles[${titleIndex}].titleColoredPart`"
                class="section-heading"
                :style="`font-size: ${heroTitleFontSize}px`"
              >
                <span class="title-colored">{{
                  $t(`Home.sections.hero.titles[${titleIndex}].titleColoredPart`)
                }}</span>
              </i18n>
            </div>
          </transition>
          <p class="section-text">{{ $t("Home.sections.hero.description") }}</p>
          <div class="hero-buttons">
            <button class="large-button" @click="$open('/add-bot')">
              {{ $t("Home.addToDiscord") }}
              <font-awesome-icon class="buttonIcon buttonIcon-large" :icon="['fab', 'discord']" />
            </button>
            <button
              class="large-button withoutBackground"
              @click="$router.push({ name: 'ServerSelection' })"
            >
              {{ $t("Home.useSuivix") }}
              <font-awesome-icon class="buttonIcon" :icon="['fas', 'arrow-right']" />
            </button>
          </div>
        </div>
        <div class="hero-right">
          <div class="hero-illustration-container">
            <img :src="require('./resources/images/logo-min.png')" class="suivix-logo" />
          </div>
        </div>
      </section>
      <div class="hero-arrow" v-if="!hideHeroArrow">
        <svg width="58px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            fill="rgba(0, 0, 0, .3)"
            d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
          ></path>
        </svg>
      </div>
    </div>
    <div class="sections-container">
      <section ref="feature-section" class="section-container">
        <div class="section-content">
          <h2 class="section-title">
            <span class="title-colored">{{ featuresSectionTitle[0] }}</span>
            <span>{{ featuresSectionTitle[1] }}</span>
          </h2>
          <p class="section-description">
            {{ $t("Home.sections.features.description") }}
          </p>
          <div class="feature-cards-container">
            <div class="feature-card">
              <div class="feature-card-illustration-container">
                <img
                  class="feature-card-illustration"
                  :src="require('./resources/images/home/attendance-feature-icon.svg')"
                  alt="attendance-feature-icon"
                />
              </div>
              <div class="feature-card-content">
                <h3 class="feature-card-title">
                  {{ $t("Home.sections.features.attendance.title") }}
                  <div v-html="$twemoji.parse('📋')" class="buttonIcon card-icon" />
                </h3>
                <p class="feature-card-text">
                  {{ $t("Home.sections.features.attendance.description") }}
                </p>
                <code class="feature-card-command">/attendance</code>
              </div>
            </div>
            <div class="feature-card">
              <div class="feature-card-illustration-container">
                <img
                  class="feature-card-illustration"
                  :src="require('./resources/images/home/poll-feature-icon.svg')"
                  alt="poll-feature-icon"
                />
              </div>
              <div class="feature-card-content">
                <h3 class="feature-card-title">
                  {{ $t("Home.sections.features.poll.title") }}
                  <div v-html="$twemoji.parse('📚')" class="buttonIcon card-icon" />
                </h3>
                <p class="feature-card-text">
                  {{ $t("Home.sections.features.poll.description") }}
                </p>
                <code class="feature-card-command">/poll</code>
              </div>
            </div>
            <div class="feature-card">
              <div class="feature-card-illustration-container">
                <img
                  class="feature-card-illustration"
                  :src="require('./resources/images/home/bunch-of-options.svg')"
                  alt="a-bunch-of-options-icon-1"
                />
              </div>
              <div class="feature-card-content">
                <h3 class="feature-card-title">
                  {{ $t("Home.sections.features.customizable.title") }}
                  <div v-html="$twemoji.parse('⚙️')" class="buttonIcon card-icon" />
                </h3>
                <p class="feature-card-text">
                  {{ $t("Home.sections.features.customizable.description") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section ref="attendance-section" class="section-container">
        <div class="section-content section-content-left-aligned low-margin">
          <div class="row">
            <div class="largeColumn left-aligned-column">
              <img
                class="feature-icon attendance-feature-icon mobile-icon"
                :src="require('./resources/images/home/attendance-example-min.png')"
                alt="attendance-example"
              />
              <h2 style="max-width: none" class="section-title sub-section-title">
                <span>{{ attendanceSectionTitle[0] }}</span>
                <span class="title-colored">{{ attendanceSectionTitle[1] }}</span>
              </h2>
              <p style="max-width: none" class="section-description">
                {{ $t("Home.sections.attendance.description") }}
              </p>
            </div>
            <div class="column illustration-column">
              <img
                class="feature-icon attendance-feature-icon"
                :src="require('./resources/images/home/attendance-example-min.png')"
                alt="attendance-example"
              />
            </div>
          </div>
        </div>
      </section>
      <section ref="poll-section" class="section-container">
        <div class="section-content section-content-left-aligned">
          <div class="row">
            <div class="column illustration-column">
              <img
                class="feature-icon poll-feature-icon"
                :src="require('./resources/images/home/poll-example-min.png')"
                alt="poll-example"
              />
            </div>
            <div class="largeColumn right-aligned-column">
              <img
                class="feature-icon poll-feature-icon mobile-icon"
                :src="require('./resources/images/home/poll-example-min.png')"
                alt="poll-example"
              />
              <h2 style="max-width: none" class="section-title sub-section-title">
                <span class="title-colored">{{ pollSectionTitle[0] }}</span>
                <span>{{ pollSectionTitle[1] }}</span>
              </h2>
              <p style="max-width: none" class="section-description">
                {{ $t("Home.sections.poll.description") }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section ref="thanks-section" class="section-container">
        <div class="section-content section-content-left-aligned thanks-section">
          <h2 class="section-title">
            <span>{{ thanksSectionTitle[0] }}</span>
            <span class="title-colored"
              >{{ thanksSectionTitle[1] }}
              <div v-html="$twemoji.parse('✨')" class="buttonIcon" />
            </span>
          </h2>
          <div class="thanks-container">
            <p class="thanks-name">Michaël Morival</p>
            <p class="thanks-name">espagnf</p>
            <p class="thanks-name">Benoit Pasquier</p>
            <p class="thanks-name">Alan Quéré</p>
            <p class="thanks-name">Thomas Druesne</p>
            <p class="thanks-name">Enzo Simioni</p>
            <p class="thanks-name">un_phoque</p>
            <p class="thanks-name">Lucas Brigliadori</p>
            <p class="thanks-name">Valentin Rapp</p>
            <p class="thanks-name">Victor Lamarche</p>
            <p class="thanks-name">Maxfreber</p>
            <p class="thanks-name">Achille Braune</p>
            <p class="thanks-name">BastLast</p>
            <p class="thanks-name">Lucian Filip</p>
            <p class="thanks-name">Maxime Cayssials</p>
          </div>
        </div>
      </section>
      <HomeFooter />
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

import HomeNavbar from "./components/HomeNavbar.vue";
import HomeFooter from "./components/HomeFooter.vue";

library.add(faArrowRight, faDiscord);

export default {
  name: "Home",
  components: {
    HomeNavbar,
    HomeFooter,
  },
  props: {
    language: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      titleIndex: 0,
      isUserOnHero: true,
      hideHeroArrow: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    featuresSectionTitle: function () {
      return this.getSectionTitle("Home.sections.features.title");
    },
    attendanceSectionTitle: function () {
      return this.getSectionTitle("Home.sections.attendance.title");
    },
    pollSectionTitle: function () {
      return this.getSectionTitle("Home.sections.poll.title");
    },
    thanksSectionTitle: function () {
      return this.getSectionTitle("Home.sections.thanks.title");
    },
    /**
     * Dynamic font size for the hero title
     */
    heroTitleFontSize: function () {
      const defaultSize = this.windowWidth >= 767 ? 66 : 54;
      if (defaultSize === 66) {
        const textLength = this.$t(`Home.sections.hero.titles[${this.titleIndex}].title`, [
          this.$t(`Home.sections.hero.titles[${this.titleIndex}].titleColoredPart`),
        ]).length;
        return textLength > 34 ? 66 - textLength / 4 : 66;
      }
      return defaultSize;
    },
  },
  metaInfo() {
    const title = this.$i18n.t("Home.title");
    return {
      title: (chunk) => chunk.slice(0, -2) + title,
      meta: [
        { name: "description", content: this.$t("Home.sections.hero.description") },
        {
          name: "google-site-verification",
          content: "pD8Y8KjKegiGB6zmfBozXsxDdjvcVeC1ONd7pa9qag8",
        },
        { name: "twitter:card", content: "summary_large_image" },
        { property: "og:title", content: this.$t("Home.title") },
        { property: "og:description", content: this.$t("Home.sections.hero.description") },
        { property: "og:type", content: "website" },
        { property: "og:image", content: `${process.env.VUE_APP_BASE_URL}/og-image-min.png` },
        { property: "og:image:width", content: 1720 },
        { property: "og:image:height", content: 968 },
      ],
    };
  },
  methods: {
    changeHeroText: function () {
      if (this.titleIndex === this.$t("Home.sections.hero.titles").length - 1) {
        this.titleIndex = 0;
      } else {
        this.titleIndex++;
      }
    },
    onLanguageChange: function (language) {
      this.titleIndex = 0;
      this.$router.push({ name: "Home-" + language.toUpperCase() });
    },
    getSectionTitle: function (titlePath) {
      const title = this.$t(titlePath);
      const split = title.split(" ");
      if (split.length > 1) {
        const half = Math.ceil(split.length / 2);
        const firstHalf = split.slice(0, half);
        const secondHalf = split.filter((el) => !firstHalf.includes(el));
        const result = [firstHalf.join(" "), secondHalf.join(" ")];
        return result.map((str, i) => (i !== result.length - 1 ? str + " " : str));
      } else {
        const firstPart = title.substring(0, Math.round((1 / 2) * title.length + 1));
        const lastPart = title.replace(firstPart, "");
        return [firstPart, lastPart];
      }
    },
    handleScroll: function () {
      this.hideHeroArrow = window.scrollY > this.$refs.heroContainer?.clientHeight / 11 ?? false;
    },
    handleResize: function () {
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    // Handle languages routes for home
    if (this.language) {
      this.$i18n.locale = this.language;
      window.localStorage.setItem("siteLanguage", this.language);
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    setInterval(this.changeHeroText, 10000);
  },
};
</script>

<style lang="scss" scoped>
@import "./resources/styles/home.scss";

.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: scale(0.95, 0.95);
  opacity: 0;
}

.thanks-section {
  .section-title {
    display: flex;
    white-space: pre-wrap;

    .title-colored {
      display: flex;

      .buttonIcon {
        cursor: default;
      }
    }
  }

  .thanks-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-size: 22px;
    margin-top: 8px;

    .thanks-name {
      &::after {
        content: "-";
        margin: 0 12px;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          margin: 0 8px;
        }
      }

      &:last-child {
        &::after {
          content: "";
          margin: 0;
        }
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
}
</style>
