<template>
  <!-- calculate dropdown position by using parentWidth -->
  <div ref="languageDropdown" class="dropdown" :style="'margin-left: ' + marginLeft + 'px'">
    <button :disabled="true" class="element current-language">
      <!-- style="color: #2bc566;" -->
      <div class="iconWrapper">
        <font-awesome-icon class="icon" :icon="['fas', 'check-circle']" />
      </div>
      {{ $t("Global.languageName", $i18n.locale) }}
    </button>
    <button
      v-for="lang in $i18n.availableLocales.filter((l) => l !== $i18n.locale)"
      :key="`language-${lang}`"
      @click="() => setLanguage(lang)"
      class="element"
    >
      <div class="iconWrapper">
        <!-- <font-awesome-icon class="icon" :icon="['fas', 'book']" /> -->
        <div class="icon" v-html="$twemoji.parse($t('Global.emoji', lang))" />
      </div>
      {{ $t("Global.languageName", lang) }}
    </button>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGlobeAmericas, faCheckCircle, faBook } from "@fortawesome/free-solid-svg-icons";

library.add(faGlobeAmericas, faCheckCircle, faBook);

export default {
  name: "Dropdown",
  props: {
    parent: [HTMLAnchorElement, HTMLButtonElement],
  },
  data() {
    return {
      marginLeft: 0,
    };
  },
  methods: {
    setLanguage: function (val) {
      this.$i18n.locale = val;
      window.localStorage.setItem("siteLanguage", val);
      this.$emit("languageChange", val);
    },
  },
  mounted() {
    const parentComputedStyle = getComputedStyle(this.parent);
    this.marginLeft = -(
      this.$refs.languageDropdown.offsetWidth -
      this.parent.offsetWidth +
      parseFloat(parentComputedStyle.paddingRight)
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../resources/styles/global/variables.scss";

.dropdown {
  position: absolute;
  // margin-left: -83px;
  // margin-left: -12px;
  top: $navbar-height * 2.95;
  width: 150px;
  background-color: #fff;
  overflow: auto;
  padding: 1rem;
  border-radius: 8px;
  z-index: 2;
  box-shadow: 0 0px 18px 0 rgba(0, 0, 0, 0.1);

  //TODO max height and scrollbar if more languages are added later

  .current-language {
    cursor: not-allowed;
    color: #000;
    font-weight: 600 !important;
  }

  .element {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    background: none;
    padding: 6px 12px;
    width: 100%;
    border-radius: 8px;

    &:hover {
      background-color: #eef0f2;
    }

    .iconWrapper {
      display: flex;
      margin-right: 7px;
      background-color: #d4d7da;
      border-radius: 100px;
      width: 28px;
      height: 28px;
      justify-content: center;
      align-items: center;

      .icon {
        width: 16px;
        height: 16px;
        color: #495057;
      }
    }
  }
}
</style>
