<template>
  <div>
    <header>
      <button @click="$router.push({ name: 'Home' })" class="logo">
        <div class="logo-container">
          <img
            class="logo"
            :src="require('../resources/images/full-logo-min.png')"
            alt="suivix-logo"
          />
          <p class="app-version">{{ appVersion }}</p>
        </div>
      </button>
      <nav>
        <ul class="nav__links">
          <li>
            <a
              ref="languageButton"
              :class="{ clicked: languageDropdownOpened }"
              @click="languageDropdownOpened = !languageDropdownOpened"
              v-on-clickaway="closeLanguageDropdown"
            >
              <p class="buttonIcon" v-html="$twemoji.parse('🌎')" />
              <p>{{ $t("Navbar.languages") }}</p>
              <LanguageDropdown
                :parent="$refs.languageButton"
                v-if="languageDropdownOpened"
                @click-outside="languageDropdownOpened = false"
              />
            </a>
          </li>
          <li v-if="openMenu">
            <a class="cta" @click="openMenu">
              <p class="buttonIcon" v-html="$twemoji.parse('📋')" />
              {{ $t("Navbar.reopenMenu") }}
            </a>
          </li>
        </ul>
      </nav>
      <a class="cta user" @click="$redirect('/disconnect')">
        <img
          v-if="user.avatar"
          class="avatar"
          :src="`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}`"
        />
        <img v-else class="avatar" src="https://cdn.discordapp.com/embed/avatars/1.png" />
        <p class="username">{{ user.username + "#" + user.discriminator }}</p>
        <p class="disconnect">{{ $t("Navbar.disconnect") }}</p>
      </a>
      <p @click="mobileMenuOpened = !mobileMenuOpened" class="menu cta">{{ $t("Navbar.menu") }}</p>
    </header>
    <div :class="['overlay', { 'overlay--active': mobileMenuOpened }]">
      <a @click="mobileMenuOpened = !mobileMenuOpened" class="close">&times;</a>
      <div class="overlay__content">
        <div class="mobileLanguagesContainer">
          <a
            v-for="(lang, i) in $i18n.availableLocales.filter((l) => l !== $i18n.locale)"
            :key="`language-${i}`"
            class="language"
            @click="() => setLanguage(lang)"
          >
            <p class="buttonIcon" v-html="$twemoji.parse($t('Global.emoji', lang))" />
            {{ $t("Global.languageName", lang) }}
          </a>
        </div>
        <a href="/disconnect"> {{ $t("Navbar.disconnect") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import { directive as onClickaway } from "vue-clickaway";
import LanguageDropdown from "./LanguagesDropdown.vue";

library.add(faGlobeAmericas);

export default {
  name: "Navbar",
  components: {
    LanguageDropdown,
  },
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    activeRoute: String,
    openMenu: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      mobileMenuOpened: false,
      languageDropdownOpened: false,
      appVersion: process.env.VUE_APP_VERSION,
      user: {
        username: this.$t("Global.loading"),
        discriminator: "0001",
      },
    };
  },
  methods: {
    setLanguage: function (val) {
      this.$i18n.locale = val;
      window.localStorage.setItem("siteLanguage", val);
    },
    closeLanguageDropdown: function () {
      this.languageDropdownOpened = false;
    },
  },
  /**
   * Loads the user infos from the api
   */
  async mounted() {
    const res = await fetch("/api/user");
    const data = await res.json();
    if (data.status === 401) this.$redirect("/login?redirectTo=/servers");
    else {
      this.$emit("userDataLoaded", data);
      this.user = data;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../resources/styles/global/variables.scss";

.mobileLanguagesContainer {
  max-height: 60vh;
  overflow: scroll;
  text-align: center;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(116, 120, 125, 0.7);
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

/* Mobile Nav */
header {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  padding: $navbar-height/2 10%;
  background-color: #191a1d;
}
.logo {
  margin-right: auto;
  display: flex;
  padding: 0;
  background: none;
  cursor: pointer;

  img {
    max-height: 25px;
    width: auto;
  }
}
.nav__links {
  list-style: none;
  display: flex;
  a {
    font-weight: 500;
    color: #edf0f1;
    text-decoration: none;
    font-size: 16px;
  }
  li {
    a {
      display: flex;
      height: 23px;
      align-items: center;
      padding: 9px 14px;
      border-radius: 100px;
      transition: all 0.3s ease 0s;
      cursor: pointer;

      &:hover {
        color: #23272a;
        background-color: #fff;
      }
    }
    .clicked {
      color: #23272a;
      background-color: #fff;
    }
  }
}

.user {
  .disconnect {
    display: none;
  }
  &:hover .username {
    display: none;
  }
  &:hover .disconnect {
    display: block;
  }
}

.cta {
  height: 23px;
  font-weight: 500;
  color: #edf0f1;
  text-decoration: none;
  margin-left: 16px;
  padding: 9px 14px;
  background-color: #23272a;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  font-size: 16px;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: #2a2e30;
  }

  .avatar {
    max-height: 24px;
    border-radius: 100px;
    margin-right: 6px;
  }
}

.round {
  padding: 5px 10px;
}

.overlay__content {
  a {
    font-weight: 500;
    color: #edf0f1;
    text-decoration: none;
  }

  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.menu {
  display: none;
}
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: $secondary;
  overflow-x: hidden;
  transition: all 0.5s ease 0s;

  .language {
    font-size: 24px;
    display: inline-flex;
    padding: 5px 15px;
  }

  a {
    padding: 15px;
    font-size: 36px;
    display: block;
    transition: all 0.3s ease 0s;
    &:hover {
      color: #0088a9;
    }
    &:focus {
      color: #0088a9;
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    color: #edf0f1;
    cursor: pointer;
  }
}
.overlay--active {
  width: 100%;
}
@media screen and (max-height: 450px) {
  .overlay {
    a {
      font-size: 20px;
    }
    .close {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }
  }
}
@media only screen and (max-width: 800px) {
  .nav__links {
    display: none;
  }
  .cta {
    display: none;
  }
  .menu {
    display: initial;
  }
}
</style>
