<template>
  <footer class="section-container">
    <div class="section-content footer-section-noBottomPadding">
      <div class="footer-content">
        <div class="footer-column">
          <div class="logo-container">
            <img
              class="logo"
              :src="require('../resources/images/full-logo-dark-min.png')"
              alt="suivix-logo"
            />
            <p class="app-version">{{ appVersion }}</p>
          </div>
          <p class="footer-column-text footer-statistics">
            <span v-if="statistics.guilds > 0" class="footer-statistics-data">
              {{ $tc("Home.navbar.servers-info", statistics.guilds) }}
            </span>
            <span v-if="statistics.users > 0" class="footer-statistics-data">
              {{ $tc("Home.navbar.users-info", statistics.users) }}
            </span>
          </p>
          <p class="footer-column-text">{{ $t("Home.footer.createdBy") }}</p>
          <p class="footer-column-text">
            {{ $t("Home.footer.copyright", { date: new Date().getFullYear() }) }}
          </p>
        </div>
        <div class="footer-column">
          <h3 class="footer-column-title">{{ $t("Home.footer.informations.title") }}</h3>
          <button @click="$router.push({ name: 'PrivacyPolicy' })" class="footer-column-text">
            {{ $t("Home.footer.informations.privacyPolicy") }}
            <div
              style="display: flex; width: 1em; height: 1em; margin-left: 5px"
              v-html="$twemoji.parse('🕵️')"
            />
          </button>
          <button @click="$router.push({ name: 'TermsOfUse' })" class="footer-column-text">
            {{ $t("Home.footer.informations.termsOfUse") }}
            <div
              style="display: flex; width: 1em; height: 1em; margin-left: 5px"
              v-html="$twemoji.parse('📖')"
            />
          </button>
        </div>
        <div class="footer-column">
          <h3 class="footer-column-title">{{ $t("Home.footer.sponsors.title") }}</h3>
          <img
            @click="$open('https://go31.fr')"
            class="footer-logo"
            :src="require('../resources/images/home/sponsors/logo-AmbitionJeunesses-min.png')"
            alt="ambition-jeunesses-logo"
          />
          <img
            @click="$open('http://www.lafrontonnaise.com/')"
            class="footer-logo"
            :src="require('../resources/images/home/sponsors/logo-LaFrontonaise-min.png')"
            alt="la-frontonaise-logo"
          />
        </div>
      </div>
      <i18n tag="p" path="Home.footer.credits" class="footer-bottom-text">
        <a
          class="credits-link"
          target="_blank"
          rel="noreferrer nofollow"
          href="https://www.freepik.com"
          >freepik.com</a
        >
        <a
          class="credits-link"
          target="_blank"
          rel="noreferrer nofollow"
          href="https://www.flaticon.com"
          >flaticon.com</a
        >
        <a
          class="credits-link"
          target="_blank"
          rel="noreferrer nofollow"
          href="https://twemoji.twitter.com"
          >twemoji.twitter.com</a
        >
        <a
          class="credits-link"
          target="_blank"
          rel="noreferrer nofollow"
          href="https://fontawesome.com"
          >fontawesome.com</a
        >
      </i18n>
    </div>
  </footer>
</template>

<script>
export default {
  name: "HomeFooter",
  data() {
    return {
      statistics: {
        guilds: 0,
        users: 0,
      },
      appVersion: process.env.VUE_APP_VERSION,
    };
  },
  async beforeCreate() {
    // Prevent double fetch request (one for the navbar & one for the footer)
    if (window.innerWidth <= 767) {
      const res = await fetch("/api/statistics");
      const data = await res.json();
      if (data) this.statistics = data;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../resources/styles/home.scss";

.footer-section-noBottomPadding {
  padding-bottom: 0 !important;

  .footer-bottom-text {
    display: inline-flex;
    flex-wrap: wrap;
    align-self: flex-start;
    text-align: left;
    margin-bottom: 65px;
    padding: 0 25px;
    color: $subtitle;
    max-width: 100%;

    .credits-link {
      margin: 0 0 0 5px;
      text-decoration: underline;
      color: $primary;

      &:nth-of-type(3) {
        margin: 0 5px 0;
      }

      &:last-of-type() {
        margin: 0 5px 0 5px;
      }
    }

    @media screen and (max-width: 691px) {
      text-align: center;
      padding: 0;
      justify-content: center;
    }
  }

  .footer-content {
    display: flex;
    flex-direction: row;
    border-top: 0.5px solid rgba(0, 0, 0, 0.2);
    padding: 95px 25px;
    padding-bottom: 70px;
    width: 100%;

    @media screen and (max-width: 691px) {
      display: block;
      padding: 95px 0;
    }

    .footer-column {
      display: flex;
      flex: 3.5;
      flex-direction: column;
      padding: 0 25px;

      &:last-child {
        flex: 1;

        .footer-column-title {
          padding-bottom: 20px;
        }
      }

      @media screen and (max-width: 691px) {
        align-items: center;
        padding: 0;
      }

      .logo-container {
        align-items: inherit;
        margin-bottom: 38px;

        .logo {
          max-width: 100%;
          width: auto;
          justify-self: flex-start;
        }

        .app-version {
          display: flex;
          color: $subtitle;
          font-weight: 600;
        }
      }

      .footer-logo {
        height: 42px;
        width: fit-content;
        cursor: pointer;
        max-width: 100%;
        justify-self: flex-start;

        @-moz-document url-prefix() {
          width: auto !important;
        }

        @media screen and (max-width: 691px) {
          height: auto;
          width: 45%;
        }
      }

      button,
      a {
        background: none;
        text-decoration: underline;
        padding: 0;
        cursor: pointer;
      }

      &-title {
        text-align: start;
        font-size: 24px;
        padding-bottom: 37.02px;
        font-weight: 600;
        color: $title;

        @media screen and (max-width: 691px) {
          margin-top: 25px;
          padding-bottom: 15px;
        }
      }

      &-text {
        display: flex;
        text-align: start;
        margin-bottom: 18px;
        font-size: 18px;
        color: $subtitle;
      }
    }
    .footer-statistics {
      @media screen and (min-width: 767px) {
        display: none;
      }

      .footer-statistics-data {
        &:first-of-type {
          margin-right: 10px;
        }

        &:before {
          content: "•";
        }
      }
    }
  }
}
</style>
