<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "App",
      titleTemplate: "Suivix - %s",
      meta: [
        // { charset: "utf-8" },
        { property: "og:site_name", content: process.env.VUE_APP_BASE_URL.split("//")[1] },
        {
          name: "keywords",
          content:
            "take attendance, attendance, create a poll, polls, Discord, Discord bot, Suivix, education",
        },
        { name: "theme-color", content: "#fcba03" },
      ],
      htmlAttrs: {
        lang: this.$i18n.locale ?? "en",
        amp: true,
      },
    };
  },
  mounted() {
    window.console.log(
      "%c\n" +
        ".d888888b               d8b              d8b\n" +
        "d88P  '88b              Y8P              Y8P\n" +
        "Y88b.\n" +
        " Y888b.      888  888   888   888  888   888   888  888\n" +
        "    'Y88b.   888  888   888   888  888   888    Y8bd8P\n" +
        "      '888   888  888   888   Y88  88P   888     X88K\n" +
        "Y88b  d88P   88b  d88   888    Y8bd8P    888    d8bd8b\n" +
        " 'Y8888P'     Y8888P    888     Y88P     888   888  888\n" +
        "\n" +
        "%c/!\\ WARNING: If you don't know what you're doing, close this window.\n",
      "color: #00BFFF;",
      "font-size: 14px; font-weight: bold; color: #FFD700;"
    );
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Whitney";
  src: url("./views/resources/fonts/Whitney-300.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Whitney";
  src: url("./views/resources/fonts/Whitney-400.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Whitney";
  src: url("./views/resources/fonts/Whitney-500.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Whitney";
  src: url("./views/resources/fonts/Whitney-600.woff") format("woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Whitney";
  src: url("./views/resources/fonts/Whitney-700.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

@import "./views/resources/styles/global/global.scss";
</style>
