<template>
  <div>
    <slot
      name="emoji-invoker"
      :events="{
        click: (e, isEmojiPickerOpened) => {
          if (!isEmojiPickerOpened) toggle(e);
        },
      }"
    ></slot>
    <div v-if="display.visible" class="emoji-picker-container">
      <div v-on-clickaway="hide">
        <div
          class="emoji-picker emoji-picker-mobile"
          :style="{ top: display.y + 'px', left: display.x + 'px' }"
        >
          <div class="emoji-picker__search">
            <input
              :placeholder="$t('Poll.optionsModal.emojiPicker.searchPlaceholder')"
              type="text"
              autocomplete="off"
              v-model="searchContent"
            />
          </div>
          <div class="emojis-container">
            <div v-for="(emojiGroup, category) in emojis" :key="category">
              <h5 v-if="Object.keys(emojiGroup).length > 0">{{ category }}</h5>
              <div class="emojis">
                <div v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" :title="emojiName">
                  <div
                    @click="
                      () => {
                        selectEmoji(emoji);
                        hide();
                      }
                    "
                    v-html="$twemoji.parse(emoji)"
                    v-if="!emojisToHide.includes(emoji)"
                    class="emoji-wrapper"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";
import emojiTable from "./emojis";
// Credits to https://github.com/DCzajkowski/vue-emoji-picker/
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
const escapeRegExp = (s) => s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
export default {
  name: "EmojiPicker",
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    emojisToHide: {
      type: Array,
      required: false,
      default: [],
    },
    pickerId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      display: {
        x: 0,
        y: 0,
        visible: false,
      },
      searchContent: "",
      selectedEmojis: [],
    };
  },
  computed: {
    emojis() {
      const obj = {};
      for (const category in emojiTable) {
        obj[category] = {};
        for (const key in emojiTable[category]) {
          if (
            new RegExp(`.*${escapeRegExp(this.searchContent)}.*`).test(
              emojiTable[category][key].name
            )
          ) {
            obj[category][emojiTable[category][key].name] = emojiTable[category][key].emoji;
          }
        }
      }
      return Object.freeze(obj);
    },
  },
  methods: {
    selectEmoji(emoji) {
      this.searchContent = "";
      this.selectedEmojis.push(emoji);
      this.$emit("emojiSelect", { emoji, pickerId: this.pickerId });
    },
    toggle(e) {
      this.display.visible = !this.display.visible;
      this.$emit("open", this.display.visible);
      this.display.x = e.clientX;
      this.display.y = e.clientY;
    },
    hide() {
      this.display.visible = false;
      this.$emit("hide", this.display.visible);
    },
    escape(e) {
      if (this.display.visible === true && e.keyCode === 27) {
        this.$emit("hide", this.display.visible);
        this.display.visible = false;
      }
    },
  },
  mounted() {
    document.addEventListener("keyup", this.escape);
  },
  destroyed() {
    document.removeEventListener("keyup", this.escape);
  },
};
</script>

<style lang="scss" scoped>
.emoji-picker__search {
  display: flex;
  background: #202225;
  padding: 0.25rem 0.3rem;
  border-radius: 4px;

  input {
    flex: 1;
    padding: 0.1rem 0.5rem;
    outline: none;
    font-size: 18px;
    color: #fff;
    background: none;
  }
}

.emoji-picker-container {
  display: flex;
  position: fixed;
  z-index: 50;

  @media screen and (max-width: 992px) {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.emoji-picker {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 20rem; // Default was 15
  height: 20rem;
  overflow: hidden;
  padding: 12px 8px;
  box-sizing: border-box;
  border-radius: 6px;
  background: #2f3136;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.4);

  @media screen and (max-width: 992px) {
    width: 90%;
    height: 60%;
  }

  &-mobile {
    @media screen and (max-width: 992px) {
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%);
    }
  }

  .emojis-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 8px;
    overflow: auto;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.3);
      border: 5px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    h5 {
      margin-top: 6px;
      margin-bottom: 2px;
      color: #b1b1b1;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: default;
      font-weight: 600;
    }

    .emojis {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &:after {
        content: "";
        flex: auto;
      }

      .emoji-wrapper {
        width: 1.6em;
        height: 1.6em;
        padding: 0.2rem;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background: #4f545c;
          cursor: pointer;
        }

        @media screen and (max-width: 992px) {
          width: 1.8em;
          height: 1.8em;
        }
      }
    }
  }
}
</style>
